import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import fetchTeammates from '../../../../shared/networking/teammates/fetchTeammates';
import User from '../../../../shared/types/auth/user';

export const loadTeammates = createAsyncThunk(
  'teammates/LOAD_TEAMMATES',
  async (): Promise<User[]> => {
    const result = await fetchTeammates();

    return result.data?.users || [];
  },
);

interface State {
  teammates: User[];
  teammatesLoading: boolean;
  teammatesLoaded: boolean;
}

export const initialState: State = {
  teammates: [],
  teammatesLoading: false,
  teammatesLoaded: false,
};

export default createReducer<State>(initialState, (builder) => {
  builder
    .addCase(loadTeammates.pending, (state) => {
      state.teammatesLoaded = false;
      state.teammatesLoading = true;
    })
    .addCase(loadTeammates.fulfilled, (state, action) => {
      state.teammates = action.payload;
      state.teammatesLoading = false;
      state.teammatesLoaded = true;
    });
});
