/* eslint-disable motivato/all-components-should-have-storybook-stories */
import { liveSurveySpinnerContainer } from '@packages/test-ids/liveSurvey';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import styles from './styles.module.scss';

import Spinner from '../../shared/components/core-ui/Spinner';
import { useAppDispatch } from '../../shared/hooks/redux/useAppDispatch';
import { useAppSelector } from '../../shared/hooks/redux/useAppSelector';
import LiveSurveyController from './components/LiveSurveyController';
import { loadLiveSurvey } from './redux/liveSurvey';

export default function LiveSurvey(): React.ReactElement {
  const dispatch = useAppDispatch();
  const { surveyId } = useParams();
  const activeLiveSurvey = useAppSelector(
    ({ liveSurvey }) => liveSurvey.activeLiveSurvey,
  );

  useEffect(() => {
    if (!activeLiveSurvey && surveyId) {
      dispatch(loadLiveSurvey({ id: surveyId }));
    }
  }, []);

  if (!activeLiveSurvey) {
    return (
      <div
        className={styles.spinnerContainer}
        data-test-id={liveSurveySpinnerContainer}
      >
        <Spinner />
      </div>
    );
  }

  return <LiveSurveyController survey={activeLiveSurvey} />;
}
