import styles from './styles.module.scss';

import Spacing from '../core-ui/Spacing';
import Label from '../core-ui/text/Label';
import P from '../core-ui/text/P';
import Title from '../core-ui/text/Title';

export enum SlackButtonSizeVariant {
  small = 'small',
  default = 'default',
  large = 'large',
}

interface Props {
  title: string;
  outOf?: number;
  restOfText: string;
  highlightedText: string | number;
}

export default function QuickStat({
  outOf,
  title,
  restOfText,
  highlightedText,
}: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      <Label>{title}</Label>

      <div className={styles.statContainer}>
        <Title noMargin>{highlightedText}</Title>
        <Spacing horizontal={1} />

        <P>
          {outOf ? `/${outOf} ` : ''} {restOfText}
        </P>
      </div>
    </div>
  );
}
