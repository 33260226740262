import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
// TODO: Create a custom theme provider as part of core-ui so we don't need to
//       disable this linter rule.
// eslint-disable-next-line motivato/mui-should-not-be-imported-outside-of-core-ui
import { ThemeProvider } from '@mui/material';
import { Amplify } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import './index.scss';

import reportWebVitals from './reportWebVitals';
import Router from './router';
import generateTheme from './shared/helpers/mui/theme';
import amplifyConfig from './shared/networking/amplifyConfig';
import AuthProvider from './shared/providers/AuthProvider';
import { store } from './shared/redux/setup/store';

Amplify.configure(amplifyConfig);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={generateTheme()}>
          <AuthProvider>
            <Router />
          </AuthProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
