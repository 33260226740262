import classnames from 'classnames';

import styles from './styles.module.scss';

import Logo, { LogoSize } from '../../../shared/components/Logo';
import useResponsive from '../../hooks/useResponsive';
import MainButton, { MainButtonVariant } from '../core-ui/MainButton';
import Spacing from '../core-ui/Spacing';
import Tooltip from '../core-ui/Tooltip';
import Wave from './components/Wave';

interface Props {
  addLogo?: boolean;
  children: React.ReactElement;
  animation: React.ReactElement | null;
  makeLogoSmaller?: boolean;
  continueButtonText?: string;
  onContinueButtonClick?: () => void;
  continueButtonDisabled?: boolean;
  continueButtonHelperText?: string | null;
  animationContainerClassName?: string;
}

export default function ContainerWithAnimation({
  addLogo = true,
  children,
  animation,
  makeLogoSmaller,
  continueButtonText,
  onContinueButtonClick,
  continueButtonDisabled,
  continueButtonHelperText,
  animationContainerClassName,
}: Props): React.ReactElement {
  const { isMobile, isTablet, isLaptop } = useResponsive();

  let logoSize: LogoSize;

  if (isMobile) {
    logoSize = LogoSize.small;
  } else if (isTablet || isLaptop) {
    logoSize = LogoSize.medium;
  } else {
    logoSize = LogoSize.large;
  }

  if (makeLogoSmaller) {
    logoSize = Math.max(logoSize - 1, LogoSize.small);
  }

  return (
    <div className={styles.container}>
      <main className={styles.mainContainer}>
        {addLogo && (
          <div className={styles.logoContainer}>
            <Logo size={logoSize} hideName />
          </div>
        )}

        {children}

        {!!continueButtonText && onContinueButtonClick && (
          <>
            <Spacing flex vertical={1} />

            <Tooltip title={continueButtonHelperText}>
              {/* Disabled buttons do not fire events, so we wrap it in a span */}
              <span>
                <MainButton
                  variant={MainButtonVariant.primary}
                  onClick={onContinueButtonClick}
                  disabled={continueButtonDisabled}
                >
                  {continueButtonText}
                </MainButton>
              </span>
            </Tooltip>
          </>
        )}
      </main>

      <section
        className={classnames(
          styles.animationSection,
          animationContainerClassName,
        )}
      >
        <Wave className={styles.wave} color="#08070C" decideBasedOnSize />

        {animation}
      </section>
    </div>
  );
}
