import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileTimePicker from '@mui/lab/MobileTimePicker';
import moment from 'moment';
import React from 'react';
import { useState } from 'react';

import { IconSize } from '../../../enums/icons';
import TextField, { TextFieldSize } from '../TextField';
import TimeIcon from '../icons/TimeIcon';

type TextFieldProps = Parameters<typeof TextField>[0];

interface Props {
  size?: TextFieldSize;
  label: string;
  value: moment.Moment;
  onChange: (newValue: moment.Moment | null) => void;
  inputProps?: Partial<TextFieldProps>;
}

const ICON_SIZE: { [key in TextFieldSize]: IconSize } = {
  [TextFieldSize.small]: IconSize.small,
  [TextFieldSize.medium]: IconSize.medium,
};

function TimePickerTextField(
  { size = TextFieldSize.medium, label, value, onChange, ...otherProps }: Props,
  ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
  const [open, setOpen] = useState(false);

  const openDialogHandler = () => {
    setOpen(true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileTimePicker
        {...otherProps}
        ref={ref}
        label={label}
        open={open}
        value={value}
        onClose={() => setOpen(false)}
        onChange={(newValue: moment.Moment | null) => {
          onChange(moment(newValue));
        }}
        renderInput={(params: unknown) => (
          <TextField
            {...({
              ...((params as TextFieldProps) || {}),
              rightButtonIcon: <TimeIcon size={ICON_SIZE[size]} />,
              onRightButtonIconClick: openDialogHandler,
            } as TextFieldProps)}
            size={size}
            onClick={openDialogHandler}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default React.forwardRef(TimePickerTextField);
