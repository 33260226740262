import styles from './styles.module.scss';

import StatSummaryCard from '../../../shared/components/StatSummaryCard';
import VerticalQuickStat from '../../../shared/components/VerticalQuickStat';
import MainButton, {
  MainButtonVariant,
} from '../../../shared/components/core-ui/MainButton';
import Separator from '../../../shared/components/core-ui/Separator';
import Spacing from '../../../shared/components/core-ui/Spacing';
import LineChart from '../../../shared/components/core-ui/charts/LineChart';
import Title from '../../../shared/components/core-ui/text/Title';

export default function CompanyRoadmapVisibility(): React.ReactElement {
  return (
    <div>
      <Title>1. Summary</Title>
      <Spacing total={1} />

      <StatSummaryCard
        className={styles.card}
        mainNumber={90}
        description={`You've hit a sweet spot! Both short-term and
          long-term visibility are high. Motivation, code quality and
          timelines are likely not at risk from roadmap visibility.`}
      >
        <LineChart
          lines={[
            [
              { y: 1, z1: 3, name: 'Fugiat' },
              { y: 5, z1: 2, name: 'Nostrud' },
              { y: 3, z1: 3, name: 'Deserunt' },
            ],
          ]}
        />
      </StatSummaryCard>

      <Separator spacing={5} />

      <Title>2. The Breakdown</Title>
      <Spacing total={1} />

      <div className={styles.verticalStatsContainer}>
        <VerticalQuickStat
          text="High"
          title="Average visibility"
          number={90}
          difference={32}
          headerClassName={styles.verticalStatHeader}
        />

        <VerticalQuickStat
          text="High"
          title="Short-term visibility"
          number={93}
          difference={12}
          headerClassName={styles.verticalStatHeader}
        />

        <VerticalQuickStat
          text="High"
          title="Long-term visibility"
          number={85}
          difference={5}
          headerClassName={styles.verticalStatHeader}
        />
      </div>

      <Spacing total={4} />

      <StatSummaryCard
        title="Connect kudos to roadmap plans"
        kicker="Motivato recommends"
        className={styles.card}
        description={`To maintain this, send a team-wide thank
          you about the last time they hit a milestone. Talk about
          where in the roadmap they are and how much further they
          progressed in the long-term plan. This will boost morale
          and remind everyone what needs to be done and why.`}
      >
        <div className={styles.buttonContainer}>
          <MainButton variant={MainButtonVariant.secondary} onClick={() => {}}>
            Leading with gratitude
          </MainButton>
        </div>
      </StatSummaryCard>
    </div>
  );
}
