import { VictoryThemeDefinition } from 'victory';

export interface ChartColorsConfig {
  dot: string;
  line: string;
  background: string;
}

export const DEFAULT_BAR_CHART_COLORS: ChartColorsConfig[] = [
  {
    dot: '#99EBE3',
    line: '#00C0AA',
    background: 'rgba(0, 192, 170, .1)',
  },
  {
    dot: '#EB6324',
    line: '#FCA326',
    background: 'rgba(252, 163, 38, .1)',
  },
];

export const DEFAULT_PIE_CHART_COLORS = ['#4888FA', '#054FEE', '#0016BA'];

const LABEL_COLOR = 'rgba(255, 255, 255, .4)';
const LABEL_FONT_SIZE = 12;

const chartTheme: VictoryThemeDefinition = {
  axis: {
    style: {
      axis: {
        stroke: 'none',
      },
      grid: {
        stroke: 'none',
      },
      tickLabels: {
        fill: LABEL_COLOR,
        padding: 10,
        fontSize: LABEL_FONT_SIZE,
      },
    },
  },
  pie: {
    style: {
      labels: {
        fill: LABEL_COLOR,
        padding: 15,
        fontSize: LABEL_FONT_SIZE,
      },
    },
  },
  bar: {
    style: {
      data: {
        fill: '#4888FA',
      },
      labels: {
        fill: LABEL_COLOR,
        fontSize: LABEL_FONT_SIZE,
      },
    },
  },
};

export default chartTheme;
