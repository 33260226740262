import merge from 'lodash/merge';
import React from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { VictoryPie, VictoryThemeDefinition } from 'victory';

import styles from './styles.module.scss';

import chartTheme, {
  DEFAULT_PIE_CHART_COLORS,
} from '../../../../constants/chartTheme/theme';
import useResizeObserver from '../../../../hooks/useResizeObserver';

interface PieSlice {
  value: number;
  label: string;
  color?: string;
}

interface Props {
  theme?: VictoryThemeDefinition;
  slices: PieSlice[];
}

function PieChart(
  { theme, slices: unresolvedSlices }: Props,
  ref: React.ForwardedRef<VictoryPie>,
): React.ReactElement {
  const { ref: contentRef, size } = useResizeObserver();
  const [endAngle, setEndAngle] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setEndAngle(360);
    }, 100);
  }, []);

  const { colorScale, resolvedSlices } = useMemo(() => {
    const colors: string[] = [];

    return {
      colorScale: colors,
      resolvedSlices: unresolvedSlices?.map((slice, sliceIndex) => {
        colors.push(
          DEFAULT_PIE_CHART_COLORS[
            sliceIndex % DEFAULT_PIE_CHART_COLORS.length
          ],
        );

        return {
          y: slice.value,
          label: slice.label,
        };
      }),
    };
  }, [unresolvedSlices]);

  return (
    <div ref={contentRef} className={styles.container}>
      <div className={styles.content}>
        <VictoryPie
          ref={ref}
          data={resolvedSlices}
          theme={theme ? merge(chartTheme, theme) : chartTheme}
          width={size?.width}
          height={size?.height}
          endAngle={endAngle}
          colorScale={colorScale}
          animate={{
            duration: 2000,
          }}
        />
      </div>
    </div>
  );
}

export default React.forwardRef(PieChart);
