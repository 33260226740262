/* eslint-disable motivato/all-components-should-have-storybook-stories */
import { Key, useState } from 'react';

import styles from './styles.module.scss';

import Spacing from '../../../../shared/components/core-ui/Spacing';
import P from '../../../../shared/components/core-ui/text/P';
import Title from '../../../../shared/components/core-ui/text/Title';
import LiveSurvey from '../../../../shared/types/surveys/liveSurvey';
import Survey from '../../../../shared/types/surveys/survey';
import LiveSurveyScreen from '../LiveSurveyScreen';

interface Props {
  survey: LiveSurvey | Survey;
}

export default function LiveSurveyController({
  survey,
}: Props): React.ReactElement {
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>(0);

  const handleContinue = () => {
    setSelectedQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const selectedQuestion = survey.questions?.[selectedQuestionIndex];

  if (!selectedQuestion) {
    return (
      <div className={styles.doneContainer}>
        <Title>You're awesome 🚀</Title>
        <Spacing total={1} />
        <P>That's it for now!</P>
      </div>
    );
  }

  if (!selectedQuestion.animation?.lottie) {
    return <></>;
  }

  return (
    <LiveSurveyScreen
      key={selectedQuestion.animation?.lottie as Key}
      question={selectedQuestion}
      onContinue={handleContinue}
      animationJSON={selectedQuestion.animation?.lottie}
    />
  );
}
