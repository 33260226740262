import { FetchResult, gql } from '@apollo/client';
import moment from 'moment';

import ID from '../../types/id';
import PossibleAudience from '../../types/surveys/schedulingEvents/possibleAudience';
import Survey from '../../types/surveys/survey';
import { GenericResponseType, apolloClient } from '../apolloClient';
import createUsersFromSlack from '../users/createUsers';
import transformAudienceToSchedulingEventType from './dataTransformers/audience';

interface ResponseType extends GenericResponseType {
  survey: Survey;
}

const mutationName = 'updatedSchedulingEvent';
type Response = Record<typeof mutationName, ResponseType>;

export default async function updateSchedulingEvent({
  id,
  timeZone,
  audience,
  cronString,
  startingDate,
}: {
  id: ID;
  timeZone: string;
  audience: PossibleAudience;
  cronString: string | null;
  startingDate: moment.Moment;
}): Promise<FetchResult<Response>> {
  if (audience.slackUsers?.length) {
    // If we have audience.slackUsers, that means that those users do not exist as
    // Motivato users yet.
    const newUsers = await createUsersFromSlack(audience.slackUsers);

    if (newUsers.data?.userCreateInBulk?.length) {
      audience.users.push(...newUsers.data.userCreateInBulk);
    } else {
      // TODO: Log error.
    }
  }

  return apolloClient.mutate<Response>({
    mutation: gql`
      mutation SurveySchedulingEventUpdate(
        $id: String!
        $input: UpdateSurveySchedulingEventInput!
      ) {
        surveySchedulingEventUpdate(id: $id, input: $input) {
          id
        }
      }
    `,
    variables: {
      id,
      input: {
        frequency: cronString,
        timeZone,
        startAt: startingDate.toISOString(),
        targetAudience: transformAudienceToSchedulingEventType(audience),
      },
    },
  });
}
