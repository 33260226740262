export default function getFromLocalStorage<T>(key: string): T | undefined {
  try {
    const data = localStorage.getItem(key);

    if (data) {
      return JSON.parse(data);
    }
  } catch (error) {
    // TODO: Log error.
  }
}
