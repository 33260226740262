import without from 'lodash/without';

import ID from '../../../../types/id';

type ReturnType = {
  created: ID[];
  updated: ID[];
  deleted: ID[];
};

export default function normalizeQuestionLog(changeLog: {
  created: Record<ID, boolean>;
  updated: Record<ID, boolean>;
  deleted: Record<ID, boolean>;
}): ReturnType {
  const getLogIds = (ids: Record<ID, boolean>): ID[] => {
    return Object.entries(ids)
      .filter(([, shouldPerformAction]) => shouldPerformAction)
      .map(([id]) => id);
  };

  const deletedLogIds = getLogIds(changeLog.deleted);

  // Questions that were created and then deleted, should not be created.
  const createdLogIds = without(getLogIds(changeLog.created), ...deletedLogIds);

  // 1. Questions that were created and then updated, should only be updated.
  // 2. Questions that were updated and then deleted, should only be deleted.
  const updatedLogIds = without(
    getLogIds(changeLog.updated),
    ...createdLogIds,
    ...deletedLogIds,
  );

  return {
    created: createdLogIds,
    updated: updatedLogIds,
    deleted: deletedLogIds,
  };
}
