import styles from './styles.module.scss';

import FixAndCelebrateCard, {
  FixOrCelebrateType,
} from '../../../../shared/components/FixAndCelebrateCard';

export default function TeammateSummary(): React.ReactElement {
  return (
    <div className={styles.container}>
      <FixAndCelebrateCard
        type={FixOrCelebrateType.celebrate}
        sections={[{ title: 'test', items: [{ name: 'test' }] }]}
      />

      <FixAndCelebrateCard
        type={FixOrCelebrateType.fix}
        sections={[{ title: 'test', items: [{ name: 'test' }] }]}
      />
    </div>
  );
}
