import { gql, FetchResult } from '@apollo/client';

import Survey from '../../types/surveys/survey';
import { apolloClient } from '../apolloClient';

type ResponseType = Survey[];

const queryName = 'surveys';
type Response = Record<typeof queryName, ResponseType | null>;

export default function fetchSurveys(): Promise<FetchResult<Response>> {
  return apolloClient.query<Response>({
    query: gql`
      query surveys {
        surveys {
          id
          name
          isValid
          isEnabled
          description
          questions {
            id
            name
            question
            sortOrder
            followUps {
              id
              name
              question
              sortOrder
              followUps {
                id
                name
                question
                sortOrder
              }
            }
            animation {
              id
              url
            }
          }
          schedulingEvents {
            id
          }
        }
      }
    `,
  });
}
