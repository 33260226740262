import classnames from 'classnames';
import isNumber from 'lodash/isNumber';

import styles from './styles.module.scss';

import Label from '../core-ui/text/Label';
import P from '../core-ui/text/P';
import Title from '../core-ui/text/Title';
import DifferenceTag from '../reporting/DifferenceTag';

interface Props {
  text: string;
  title: string;
  number: number;
  difference?: number;
  headerClassName?: string;
}

export default function VerticalQuickStat({
  text,
  title,
  number,
  difference,
  headerClassName,
}: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      <header className={classnames(styles.header, headerClassName)}>
        <P center uppercase>
          {title}
        </P>
      </header>

      <div className={styles.content}>
        <Title center noMargin>
          {number}%
        </Title>

        <Label center>{text}</Label>

        {isNumber(difference) && <DifferenceTag difference={difference} />}
      </div>
    </div>
  );
}
