import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import * as moment from 'moment-timezone';

import fetchAudienceTargets from '../../../../shared/networking/audience/fetchAudienceTargets';
import createSchedulingEvent from '../../../../shared/networking/surveySchedulingEvents/createSchedulingEvent';
import fetchSchedulingEventsForSurvey from '../../../../shared/networking/surveySchedulingEvents/fetchSchedulingEventsForSurvey';
import updateSchedulingEvent from '../../../../shared/networking/surveySchedulingEvents/updateSchedulingEvent';
import ID from '../../../../shared/types/id';
import PossibleAudience from '../../../../shared/types/surveys/schedulingEvents/possibleAudience';
import SchedulingEvent from '../../../../shared/types/surveys/schedulingEvents/schedulingEvent';

export const loadSchedulingEventForSurvey = createAsyncThunk(
  'schedulingEvents/LOAD_SCHEDULING_EVENT_FOR_SURVEY',
  async ({
    surveyId,
    schedulingEventId,
  }: {
    surveyId: ID;
    schedulingEventId: ID;
  }): Promise<SchedulingEvent | null> => {
    const result = await fetchSchedulingEventsForSurvey({ surveyId });

    return (
      result.data?.survey?.schedulingEvents?.find(
        ({ id }) => id === schedulingEventId,
      ) || null
    );
  },
);

export const loadPossibleAudience = createAsyncThunk(
  'schedulingEvents/LOAD_POSSIBLE_AUDIENCE',
  async (): Promise<PossibleAudience> => {
    const result = await fetchAudienceTargets();

    return (
      result.data || {
        users: [],
        slackUsers: [],
        slackChannels: [],
      }
    );
  },
);

export const saveSchedulingEvent = createAsyncThunk(
  'schedulingEvents/SAVE_SCHEDULING_EVENT',
  async ({
    id,
    surveyId,
    cronString,
    startingDate,
    selectedAudience,
  }: {
    id?: ID;
    surveyId: ID;
    cronString: string | null;
    startingDate: moment.Moment;
    selectedAudience: PossibleAudience;
  }) => {
    if (id) {
      updateSchedulingEvent({
        id,
        timeZone: moment.tz.guess(),
        cronString,
        startingDate,
        audience: selectedAudience,
      });
    } else {
      createSchedulingEvent({
        surveyId,
        timeZone: moment.tz.guess(),
        cronString,
        startingDate,
        audience: selectedAudience,
      });
    }
  },
);

interface State {
  schedulingEventToEdit: SchedulingEvent | null;
  schedulingEventLoaded: boolean;
  possibleAudience: PossibleAudience;
  possibleAudienceLoaded: boolean;
}

export const initialState: State = {
  schedulingEventToEdit: null,
  schedulingEventLoaded: false,
  possibleAudienceLoaded: false,
  possibleAudience: {
    users: [],
    slackUsers: [],
    slackChannels: [],
  },
};

export default createReducer<State>(initialState, (builder) => {
  builder.addCase(loadSchedulingEventForSurvey.fulfilled, (state, action) => {
    state.schedulingEventToEdit = action.payload;
    state.schedulingEventLoaded = true;
  });

  builder.addCase(loadPossibleAudience.fulfilled, (state, action) => {
    state.possibleAudience = action.payload;
    state.possibleAudienceLoaded = true;
  });
});
