/* eslint-disable motivato/all-components-should-have-storybook-stories */
import PossibleAudienceType from '../../../../../../shared/enums/schedulingEvents/possibleAudienceType';
import PossibleAudience from '../../../../../../shared/types/surveys/schedulingEvents/possibleAudience';
import AudienceOptionItem from '../../types/audienceOptionItemAutocomplete';

export default function getAudienceFromAutocompleteItems(
  autocompleteItems: AudienceOptionItem[],
): PossibleAudience {
  const newAudience: PossibleAudience = {
    users: [],
    slackUsers: [],
    slackChannels: [],
  };

  autocompleteItems.forEach((option) => {
    if (option.type === PossibleAudienceType.User) {
      newAudience.users.push(option.object);
    }

    if (option.type === PossibleAudienceType.SlackUser) {
      newAudience.slackUsers.push(option.object);
    }

    if (option.type === PossibleAudienceType.SlackChannel) {
      newAudience.slackChannels.push(option.object);
    }
  });

  return newAudience;
}
