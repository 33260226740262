import classnames from 'classnames';

import styles from './styles.module.scss';

import { getDeviceEmulatorScale } from '../../helpers/deviceEmulator/dimensions';
import useResizeObserver from '../../hooks/useResizeObserver';
import IFrame from '../IFrame';

export enum EmulatorDevice {
  iPhone = 'iPhone',
  Desktop = 'Desktop',
}

const DEVICE_SIZES: {
  [key in EmulatorDevice]: { width: number; height: number };
} = {
  [EmulatorDevice.iPhone]: {
    width: 390,
    height: 844,
  },
  [EmulatorDevice.Desktop]: {
    width: 1080,
    height: 720,
  },
};

interface Props {
  device?: EmulatorDevice;
  children: React.ReactElement;
  className?: string;
}

export default function Emulator({
  device = EmulatorDevice.iPhone,
  children,
  className,
}: Props): React.ReactElement {
  const deviceConfig = DEVICE_SIZES[device];
  const { ref: contentRef, size } = useResizeObserver();

  const isReadyToRender = !!(size.width && size.height);
  const aspectRatio = `${deviceConfig.width} / ${deviceConfig.height}`;

  return (
    <div
      ref={contentRef}
      style={{ aspectRatio }}
      className={classnames(styles.container, className)}
    >
      {isReadyToRender && (
        <IFrame
          className={styles.iframe}
          style={{
            width: deviceConfig.width,
            height: deviceConfig.height,
            aspectRatio,
            transform: `scale(${getDeviceEmulatorScale({
              containerWidth: size.width as number,
              containerHeight: size.height as number,
              deviceWidth: deviceConfig.width,
              deviceHeight: deviceConfig.height,
            })})`,
          }}
        >
          <div className={classnames('smoothEnter', styles.content)}>
            {children || null}
          </div>
        </IFrame>
      )}
    </div>
  );
}
