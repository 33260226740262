/* eslint-disable max-lines */
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import CompanyBurnout from './modules/CompanyInsights/CompanyBurnout';
import CompanyInsights from './modules/CompanyInsights/CompanyInsights';
import CompanyRoadmapVisibility from './modules/CompanyInsights/CompanyRoadmapVisibility';
import CompanyTrustAndBelonging from './modules/CompanyInsights/CompanyTrustAndBelonging';
import CompanyWorkflowSatisfaction from './modules/CompanyInsights/CompanyWorkflowSatisfaction';
import Home from './modules/Home';
import LiveSurvey from './modules/LiveSurvey';
import RoundOfResponses from './modules/RoundOfResponses/components/RoundOfResponses';
import SurveyBuilderEditor from './modules/SurveyBuilder/components/SurveyBuilderEditor';
import SurveyBuilderLayout from './modules/SurveyBuilder/components/SurveyBuilderLayout';
import SurveyBuilderPreview from './modules/SurveyBuilder/components/SurveyBuilderPreview';
import SurveySchedulingEvent from './modules/SurveySchedulingEvent/components/SurveySchedulingEvent';
import TeammateProfile from './modules/TeammateProfile/components/TeammateProfile';
import Teammates from './modules/Teammates';
import Templates from './modules/Templates';
import AppLayout from './shared/components/AppLayout';
import P from './shared/components/core-ui/text/P';
import ProtectedRoute from './shared/components/router/ProtectedRoute';
import useAuth from './shared/hooks/useAuth';
import ID from './shared/types/id';

const Onboarding = React.lazy(() => import('./modules/Onboarding/Onboarding'));
const Login = React.lazy(() => import('./modules/Auth/Login'));

export const ROUTES = {
  home: {
    route: '/',
  },
  login: {
    route: '/login',
  },
  templates: {
    route: '/templates',
  },
  surveys: {
    getRoute: (id: string | number): string => `/surveys/${id}`,
  },
  teammateProfile: {
    getRoute: (id: string | number): string => `/teammates/${id}`,
  },
  surveyBuilder: {
    getRoute: (id: string | number): string => `/surveys/${id}/builder`,
    routes: {
      withQuestion: {
        getRoute: (surveyId: ID, questionId: ID): string =>
          `/surveys/${surveyId}/builder/questions/${questionId}`,
      },
      fromTemplate: {
        getRoute: (templateId: ID): string =>
          `/surveys/builder?templateId=${templateId}`,
      },
    },
  },
  schedulingEvents: {
    new: {
      getRoute: (surveyId: ID): string =>
        `/surveys/${surveyId}/scheduling-events/new`,
    },
    edit: {
      getRoute: (surveyId: ID, schedulingEventId: ID): string =>
        `/surveys/${surveyId}/scheduling-events/${schedulingEventId}`,
    },
  },
  roundOfResponses: {
    getRoute: (dispatchLogId: ID): string =>
      `/round-of-responses/${dispatchLogId}`,
  },
  companyInsights: {
    route: '/company-insights',
    routes: {
      burnoutLikelihood: {
        route: '/company-insights/burnout-likelihood',
      },
      workflowSatisfaction: {
        route: '/company-insights/workflow-satisfaction',
      },
      roadmapVisibility: {
        route: '/company-insights/roadmap-visibility',
      },
      trustAndBelonging: {
        route: '/company-insights/trust-and-belonging',
      },
    },
  },
  onboarding: {
    route: '/onboarding',
    routes: {
      connectToSlackStep: {
        route: '/onboarding/steps/1',
      },
      createTeamsStep: {
        route: '/onboarding/steps/2',
        routes: {
          new: {
            route: '/onboarding/steps/2/teams/new',
          },
          edit: {
            getRoute: (id: string | number): string =>
              `/onboarding/steps/2/teams/${id}`,
          },
        },
      },
    },
  },
};

export default function Router(): React.ReactElement {
  const { isLoggedIn } = useAuth();

  const protect = (element: JSX.Element) => (
    <React.Suspense fallback={<>...</>}>
      <ProtectedRoute
        allow={isLoggedIn}
        redirectsTo={ROUTES.login.route}
        element={element}
      />
    </React.Suspense>
  );

  return (
    <Routes>
      <Route path="/">
        <Route
          path="onboarding/*"
          element={
            <React.Suspense fallback={<>...</>}>
              <Onboarding />
            </React.Suspense>
          }
        />

        <Route
          path="login/"
          element={
            <React.Suspense fallback={<>...</>}>
              <Login />
            </React.Suspense>
          }
        />

        <Route path="surveys">
          <Route path="builder" element={protect(<SurveyBuilderLayout />)}>
            <Route index element={<SurveyBuilderEditor />} />
          </Route>

          <Route path=":surveyId">
            <Route
              index
              element={
                <React.Suspense fallback={<>...</>}>
                  <LiveSurvey />
                </React.Suspense>
              }
            />

            <Route path="scheduling-events">
              <Route path="new" element={protect(<SurveySchedulingEvent />)} />

              <Route
                path=":schedulingEventId"
                element={protect(<SurveySchedulingEvent />)}
              />
            </Route>

            <Route path="builder" element={protect(<SurveyBuilderLayout />)}>
              <Route index element={<SurveyBuilderEditor />} />
              <Route path="preview" element={<SurveyBuilderPreview />} />

              <Route
                path="questions/:questionId"
                element={<SurveyBuilderEditor />}
              />
            </Route>
          </Route>
        </Route>

        <Route element={<AppLayout />}>
          <Route path="/" element={protect(<Home />)} />
          <Route path="/templates" element={protect(<Templates />)} />

          <Route
            path="round-of-responses/:surveyId"
            element={protect(<RoundOfResponses />)}
          />

          <Route path="teammates">
            <Route path=":teammateId" element={protect(<TeammateProfile />)} />
            <Route index element={protect(<Teammates />)} />
          </Route>

          <Route path="company-insights" element={protect(<CompanyInsights />)}>
            <Route path="burnout-likelihood" element={<CompanyBurnout />} />

            <Route
              path="workflow-satisfaction"
              element={<CompanyWorkflowSatisfaction />}
            />

            <Route
              path="roadmap-visibility"
              element={<CompanyRoadmapVisibility />}
            />

            <Route
              path="trust-and-belonging"
              element={<CompanyTrustAndBelonging />}
            />

            <Route
              index
              element={
                <Navigate
                  to={ROUTES.companyInsights.routes.burnoutLikelihood.route}
                />
              }
            />
          </Route>

          <Route path="*" element={<P>404</P>} />
        </Route>
      </Route>
    </Routes>
  );
}
