/* eslint-disable motivato/all-components-should-have-storybook-stories */
import { FixOrCelebrateType } from '../..';

interface ReturnType {
  title: string;
  primaryHeaderColor: string;
  secondaryHeaderColor: string;
}

export default function getPropsForFixOrCelebrateType(
  type: FixOrCelebrateType,
): ReturnType {
  if (type === FixOrCelebrateType.celebrate) {
    return {
      title: 'To celebrate 🎉',
      primaryHeaderColor: '#007568',
      secondaryHeaderColor: '#00C0AA',
    };
  }

  return {
    title: 'To fix 🚨',
    primaryHeaderColor: '#A35270',
    secondaryHeaderColor: '#FF80AF',
  };
}
