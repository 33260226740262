import styles from './styles.module.scss';

import StatSummaryCard from '../../../shared/components/StatSummaryCard';
import VerticalStatSummary from '../../../shared/components/VerticalStatSummary';
import MainButton, {
  MainButtonVariant,
} from '../../../shared/components/core-ui/MainButton';
import Separator from '../../../shared/components/core-ui/Separator';
import Spacing from '../../../shared/components/core-ui/Spacing';
import LineChart from '../../../shared/components/core-ui/charts/LineChart';
import Label from '../../../shared/components/core-ui/text/Label';
import P from '../../../shared/components/core-ui/text/P';
import Title from '../../../shared/components/core-ui/text/Title';

export default function CompanyWorkflowSatisfaction(): React.ReactElement {
  return (
    <div>
      <Title>1. Summary</Title>
      <Spacing total={1} />

      <StatSummaryCard
        className={styles.card}
        mainNumber={62}
        description={
          <>
            Code Review Satisfaction = 40%
            <br />
            <br />
            Tech Debt Satisfaction = 83%
          </>
        }
      >
        <LineChart
          lines={[
            [
              { y: 1, z1: 3, name: 'Fugiat' },
              { y: 5, z1: 2, name: 'Nostrud' },
              { y: 3, z1: 3, name: 'Deserunt' },
            ],
          ]}
        />
      </StatSummaryCard>

      <Spacing vertical={5} />

      <Label>2. The breakdown</Label>
      <Separator spacing={1} />
      <Spacing total={1} />

      <Title>2.1 Code Review Satisfaction</Title>
      <Spacing total={1} />
      <P>
        Your team is unhappy with the thoroughness, constructiveness and speed
        of code reviews. This means morale, code quality and timelines could be
        at risk!
      </P>

      <Spacing total={3} />

      <div className={styles.row}>
        <VerticalStatSummary
          text="Low"
          percentage={40}
          title="Score"
          className={styles.verticalStatSummary}
        />

        <Spacing horizontal={2} />

        <StatSummaryCard
          title="Make this survey regular"
          kicker="Motivato recommends"
          className={styles.card}
          description={`To tackle all 3 factors, use a code review checklist, or revise
          the one you have. This will help maintain a standard for quality checks.
          To keep monitoring this metric, schedule a recurring code review survey!`}
        >
          <div className={styles.buttonContainer}>
            <MainButton
              variant={MainButtonVariant.secondary}
              onClick={() => {}}
            >
              Quick read: Recommended CR Checklist
            </MainButton>
          </div>
        </StatSummaryCard>
      </div>

      <Spacing total={3} />
      <Separator spacing={1} />
      <Spacing total={1} />

      <Title>2.2 Tech Debt Satisfaction</Title>
      <Spacing total={1} />
      <P>
        You've hit a sweet spot! Your team is 80% confident working on the
        codebase and and 85% satisfied with the amount of time for tackling tech
        debt. This means the likelihood of bugs is low. Morale and code quality
        are likely not at risk from tech debt.
      </P>

      <Spacing total={3} />

      <div className={styles.row}>
        <VerticalStatSummary
          text="High"
          percentage={83}
          title="Score"
          className={styles.verticalStatSummary}
        />

        <Spacing horizontal={2} />

        <StatSummaryCard
          title="Organize & categorize new tech debt"
          kicker="Motivato recommends"
          className={styles.card}
          description={`Want to maintain this? Keep track of new tech debt that’s being
          introduced, so you have a better idea of code performance issues that may
          come up in the future! Check out the updated list from your team!`}
        >
          <div className={styles.buttonContainer}>
            <MainButton
              variant={MainButtonVariant.secondary}
              onClick={() => {}}
            >
              Check Team Responses
            </MainButton>
          </div>
        </StatSummaryCard>
      </div>
    </div>
  );
}
