import React from 'react';

import styles from './styles.module.scss';

import QuickStat from '../../../../shared/components/QuickStat';
import Spacing from '../../../../shared/components/core-ui/Spacing';
import Label from '../../../../shared/components/core-ui/text/Label';
import Title from '../../../../shared/components/core-ui/text/Title';
import QuestionWithResponse from '../../../../shared/types/surveys/questionResponse';

interface Props {
  question: QuestionWithResponse;
  questionIndex: number;
  averageResult?: number | string;
  audienceReached: {
    amountWhoResponded: number;
    amountWhoReceivedIt: number;
  };
}

export default function RoundOfResponsesCardSummary({
  question,
  averageResult,
  questionIndex,
  audienceReached,
}: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      <Label uppercase>Question #{questionIndex + 1}</Label>

      <Title>{question.name}</Title>

      <QuickStat
        title="Responses"
        outOf={audienceReached.amountWhoReceivedIt}
        highlightedText={audienceReached.amountWhoResponded}
        restOfText="people"
      />

      {(averageResult || averageResult === 0) && (
        <React.Fragment>
          <Spacing vertical={2} />
          <QuickStat
            title="Result"
            highlightedText={averageResult}
            restOfText="in average"
          />
        </React.Fragment>
      )}
    </div>
  );
}
