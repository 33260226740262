import ID from '../../../../types/id';
import SurveyQuestion from '../../../../types/surveys/question';
import Survey from '../../../../types/surveys/survey';

export default function getQuestionFromSurvey(
  survey: Survey,
  questionId: ID,
): SurveyQuestion | null {
  let foundQuestion: SurveyQuestion | null = null;

  const lookInFollowUps = (
    followUps?: SurveyQuestion[],
  ): SurveyQuestion | null => {
    if (followUps) {
      for (const question of followUps) {
        if (question.id === questionId) {
          foundQuestion = question;
        } else if (question.followUps?.length) {
          foundQuestion = lookInFollowUps(question.followUps);
        }

        if (foundQuestion) {
          break;
        }
      }
    }

    return foundQuestion;
  };

  // The very first level of questions can be found in `Survey.questions',
  // but from that point onwards, questions are located in 'SurveyQuestion.followUps'.
  return lookInFollowUps(survey.questions);
}
