import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import deleteTeam from '../../../../../shared/networking/teams/deleteTeam';
import fetchTeams from '../../../../../shared/networking/teams/fetchTeams';
import { Team } from '../../../../../shared/types/teams';
import { changeTeam } from '../editTeam';

export const loadTeams = createAsyncThunk(
  'teamsList/LOAD_TEAMS',
  async (): Promise<Team[]> => {
    const teams = await fetchTeams();

    return teams.data?.Groups?.groups || [];
  },
);

export const removeTeam = createAsyncThunk(
  'teamsList/REMOVE_TEAM',
  async (
    teamId: string | number,
  ): Promise<{ teamIdToDelete: string | number }> => {
    await deleteTeam(teamId);

    return { teamIdToDelete: teamId };
  },
);

interface State {
  teams: Team[];
  teamsLoaded: boolean;
}

export const initialState: State = { teams: [], teamsLoaded: false };

export default createReducer<State>(initialState, (builder) => {
  builder.addCase(loadTeams.fulfilled, (state, action) => {
    state.teams = action.payload;
    state.teamsLoaded = true;
  });

  builder.addCase(removeTeam.fulfilled, (state, action) => {
    state.teams = state.teams.filter(
      ({ id }) => id.toString() !== action.payload.teamIdToDelete.toString(),
    );
  });

  // When editTeam actually edits or adds a new team, we update the list:
  builder.addCase(changeTeam.fulfilled, (state, action) => {
    if (action.payload.isEdit) {
      state.teams = state.teams.map((team) => {
        if (team.id.toString() === action.payload.team.id.toString()) {
          return action.payload.team;
        }

        return team;
      });
    } else {
      state.teams.push(action.payload.team);
    }
  });
});
