import moment from 'moment';
import { useRef } from 'react';
import { useEffect } from 'react';

interface Props {
  datetime: moment.Moment;
  onDatetimeUpdate: (datetime: moment.Moment) => void;
}

export default function useMinDatetime({
  datetime,
  onDatetimeUpdate,
}: Props): void {
  const datetimeRef = useRef(datetime);
  datetimeRef.current = datetime;

  useEffect(() => {
    const updateTime = () => {
      const minDatetime = moment();

      if (datetimeRef.current.isBefore(minDatetime)) {
        onDatetimeUpdate(minDatetime);
      }
    };

    updateTime();
    const updateTimeInterval = setInterval(updateTime, 1000);

    return () => {
      clearInterval(updateTimeInterval);
    };
  }, []);
}
