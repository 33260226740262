import { SurveyQuestionType } from '../../enums/surveys/questionType';
import generateMockId from '../../helpers/mocks/generateMockId';
import getISONow from '../../helpers/mocks/getISONow';
import { ShallowModify } from '../../helpers/typescript/shallowModify';
import SurveyQuestion from '../../types/surveys/question';
import { SurveyBooleanResponse } from '../../types/surveys/questions/booleanResponse';
import { SurveyListResponse } from '../../types/surveys/questions/listResponse';
import { SurveySliderResponse } from '../../types/surveys/questions/sliderResponse';
import { SurveyTextResponse } from '../../types/surveys/questions/textResponse';
import SurveyResponseType from '../../types/surveys/responseTypeConfig';

export const questionConfigsForType: { [key: string]: SurveyResponseType } = {
  [SurveyQuestionType.Boolean]: {
    type: SurveyQuestionType.Boolean,
    config: {
      yes: {
        text: 'I agree',
      },
    },
  },
  [SurveyQuestionType.List]: {
    type: SurveyQuestionType.List,
    config: {
      placeholder: 'What do you think?',
    },
  },
  [SurveyQuestionType.Slider]: {
    type: SurveyQuestionType.Slider,
    config: {
      segments: [
        { name: 'Really bad' },
        { name: 'Bad' },
        { name: 'Okay' },
        { name: 'Good' },
        { name: 'Really good' },
      ],
    },
  },
  [SurveyQuestionType.Text]: {
    type: SurveyQuestionType.Text,
    config: {
      maxAmountOfWords: 50,
    },
  },
};

export const generateMockQuestion = (
  sectionName: string,
  type: SurveyQuestionType,
): SurveyQuestion => {
  return {
    id: generateMockId(),
    name: `Question ${type}`,
    parentId: generateMockId(),
    sectionName,
    globalQuestionId: generateMockId(),
    customerId: generateMockId(),
    surveyId: generateMockId(),
    isEnabled: true,
    sortOrder: 0,
    defaultAnimationId: generateMockId(),
    selectedAnimationId: generateMockId(),
    isAnonymous: false,
    lastModifiedBy: getISONow(),
    lastModifiedAt: getISONow(),
    question: questionConfigsForType[type] || {
      type,
      config: {},
    },
  };
};

const sectionNameA = 'Section name A';
const sectionNameB = 'Section name B';

const surveyQuestionsMock: SurveyQuestion[] = [
  generateMockQuestion(sectionNameA, SurveyQuestionType.Boolean),
  {
    ...generateMockQuestion(sectionNameA, SurveyQuestionType.Slider),
    followUps: [
      generateMockQuestion(sectionNameA, SurveyQuestionType.Text),
      generateMockQuestion(sectionNameA, SurveyQuestionType.Boolean),
      {
        ...generateMockQuestion(sectionNameA, SurveyQuestionType.List),
        followUps: [
          generateMockQuestion(sectionNameA, SurveyQuestionType.List),
          generateMockQuestion(sectionNameA, SurveyQuestionType.Text),
          generateMockQuestion(sectionNameA, SurveyQuestionType.Text),
        ],
      },
    ],
  },
  generateMockQuestion(sectionNameB, SurveyQuestionType.Text),
];

export default surveyQuestionsMock;

export const booleanSurveyQuestion: ShallowModify<
  SurveyQuestion,
  { question: SurveyBooleanResponse }
> = {
  ...generateMockQuestion(sectionNameA, SurveyQuestionType.Boolean),
  question: questionConfigsForType[
    SurveyQuestionType.Boolean
  ] as SurveyBooleanResponse,
};

export const textSurveyQuestion: ShallowModify<
  SurveyQuestion,
  { question: SurveyTextResponse }
> = {
  ...generateMockQuestion(sectionNameA, SurveyQuestionType.Text),
  question: questionConfigsForType[
    SurveyQuestionType.Text
  ] as SurveyTextResponse,
};

export const sliderSurveyQuestion: ShallowModify<
  SurveyQuestion,
  { question: SurveySliderResponse }
> = {
  ...generateMockQuestion(sectionNameA, SurveyQuestionType.Slider),
  question: questionConfigsForType[
    SurveyQuestionType.Slider
  ] as SurveySliderResponse,
};

export const listSurveyQuestion: ShallowModify<
  SurveyQuestion,
  { question: SurveyListResponse }
> = {
  ...generateMockQuestion(sectionNameA, SurveyQuestionType.List),
  question: questionConfigsForType[
    SurveyQuestionType.List
  ] as SurveyListResponse,
};
