/* eslint-disable motivato/all-components-should-have-storybook-stories */
import React, { useEffect } from 'react';
import { useState } from 'react';

import styles from './styles.module.scss';

import TemplateDialog from '../../shared/components/TemplateDialog';
import Spinner from '../../shared/components/core-ui/Spinner';
import Title from '../../shared/components/core-ui/text/Title';
import { useAppDispatch } from '../../shared/hooks/redux/useAppDispatch';
import { useAppSelector } from '../../shared/hooks/redux/useAppSelector';
import SurveyTemplate from '../../shared/types/surveys/template';
import TemplatesLayout from './components/TemplatesLayout';
import { loadTemplates } from './redux/templates';

export default function Templates(): React.ReactElement {
  const dispatch = useAppDispatch();
  const [selectedTemplate, setSelectedTemplate] =
    useState<SurveyTemplate | null>(null);

  const { templateList, templatesLoaded } = useAppSelector(({ templates }) => ({
    templateList: templates.templates,
    templatesLoaded: templates.templatesLoaded,
  }));

  useEffect(() => {
    dispatch(loadTemplates());
  }, []);

  if (!templatesLoaded) {
    return (
      <div className={styles.spinnerContainer}>
        <Spinner />
      </div>
    );
  }

  if (!templateList.length) {
    return <Title>Error loading templates</Title>;
  }

  return (
    <div>
      <TemplatesLayout
        templates={templateList}
        onTemplateClick={setSelectedTemplate}
      />

      {selectedTemplate && (
        <TemplateDialog
          onClose={() => setSelectedTemplate(null)}
          template={selectedTemplate}
        />
      )}
    </div>
  );
}
