/* eslint-disable max-lines */
import {
  createAction,
  createAsyncThunk,
  createReducer,
  PayloadAction,
} from '@reduxjs/toolkit';

import { NEW_SURVEY_FROM_SCRATCH } from '../../../../shared/constants/surveys/newSurveyFromScratch';
import resolveAnimationsForSurvey from '../../../../shared/helpers/surveys/animations/resolveAnimationsForSurvey';
import createSurveyFromTemplate from '../../../../shared/helpers/surveys/createSurveyFromTemplate';
import getQuestionFromSurvey from '../../../../shared/helpers/surveys/questions/getQuestionFromSurvey';
import replaceQuestionInSurvey from '../../../../shared/helpers/surveys/questions/replaceQuestionInSurvey';
import fetchSurveyTemplate from '../../../../shared/networking/surveyTemplates/fetchSurveyTemplate';
import createSurvey from '../../../../shared/networking/surveys/createSurvey';
import fetchSurvey from '../../../../shared/networking/surveys/fetchSurvey';
import updateSurvey from '../../../../shared/networking/surveys/updateSurvey';
import { RootState } from '../../../../shared/redux/setup/rootReducer';
import ID from '../../../../shared/types/id';
import SurveyQuestion from '../../../../shared/types/surveys/question';
import Survey from '../../../../shared/types/surveys/survey';
import SurveyTemplate from '../../../../shared/types/surveys/template';

interface SharedLoadSurveyPayload {
  survey: Survey | null;
  question?: SurveyQuestion | null;
}

/**
 * Loads the bare bones of a Survey to be edited. This is useful when the user is
 * starting a Survey fully from scratch.
 */
export const loadBaseSurveyToCreate = createAction(
  'surveyBuilder/LOAD_BASE_SURVEY_TO_CREATE',
  (): { payload: SharedLoadSurveyPayload } => {
    return {
      payload: {
        survey: NEW_SURVEY_FROM_SCRATCH,
        question: NEW_SURVEY_FROM_SCRATCH.questions?.[0],
      },
    };
  },
);

const getSurveyAndPreselectedQuestion = (
  survey: Survey | null,
  preselectedQuestionId?: ID | null,
): SharedLoadSurveyPayload => {
  let question: SurveyQuestion | null = null;

  if (preselectedQuestionId && survey) {
    question = getQuestionFromSurvey(survey, preselectedQuestionId);
  } else if (!preselectedQuestionId && survey?.questions?.length) {
    question = survey.questions[0];
  }

  return {
    survey,
    question,
  };
};

const loadSurveyToEditData = createAction<SharedLoadSurveyPayload>(
  'surveyBuilder/LOAD_SURVEY_TO_EDIT_DATA',
);

const changeQuestions = createAction<SurveyQuestion[]>(
  'surveyBuilder/CHANGE_QUESTIONS',
);

export const loadSurveyToEdit = createAsyncThunk(
  'surveyBuilder/LOAD_SURVEY_TO_EDIT',
  async (
    {
      id,
      preselectedQuestionId,
    }: {
      id: ID;
      preselectedQuestionId?: ID;
    },
    { dispatch },
  ) => {
    const result = await fetchSurvey({ id });
    const survey = result.data?.survey || null;

    const surveyAndPreselectedQuestion = getSurveyAndPreselectedQuestion(
      survey,
      preselectedQuestionId,
    );

    dispatch(loadSurveyToEditData(surveyAndPreselectedQuestion));

    if (survey) {
      resolveAnimationsForSurvey(survey, (changedQuestion) => {
        dispatch(changeQuestions(changedQuestion));
      });
    }
  },
);

const loadSurveyFromTemplateData = createAction<SharedLoadSurveyPayload>(
  'surveyBuilder/LOAD_SURVEY_FROM_TEMPLATE_DATA',
);

export const loadSurveyFromTemplate = createAsyncThunk(
  'surveyBuilder/LOAD_SURVEY_FROM_TEMPLATE',
  async (
    {
      templateId,
    }: {
      templateId: ID;
    },
    { dispatch },
  ) => {
    const templateResponse = await fetchSurveyTemplate({ id: templateId });
    const template = templateResponse.data?.template as SurveyTemplate;

    if (!template) {
      // TODO: Log error.
    }

    const survey = createSurveyFromTemplate(template);

    const surveyAndPreselectedQuestion =
      getSurveyAndPreselectedQuestion(survey);

    dispatch(loadSurveyFromTemplateData(surveyAndPreselectedQuestion));

    if (survey) {
      resolveAnimationsForSurvey(survey, (changedQuestion) => {
        dispatch(changeQuestions(changedQuestion));
      });
    }
  },
);

export const saveSurveyToEdit = createAsyncThunk(
  'surveyBuilder/SAVE_SURVEY_TO_EDIT',
  async (__, { getState }): Promise<Partial<Survey> | void> => {
    const state = getState() as RootState;
    const survey = state.surveyBuilder.surveyToEdit;

    if (survey) {
      if (survey.id) {
        updateSurvey({
          survey: survey as Survey & { id: string },
          questionsChangeLog: state.surveyBuilder.questionsChangeLogForUpdate,
        });
      } else {
        const response = await createSurvey({ survey });
        const id = response.data?.surveyCreate.id;

        if (id) {
          return { id };
        } else {
          // TODO: Log error
        }
      }
    } else {
      // TODO: Log error
    }
  },
);

export const changeSelectedQuestion = createAction<SurveyQuestion>(
  'surveyBuilder/CHANGE_SELECTED_QUESTION',
);

export const addQuestionToSurvey = createAction<SurveyQuestion>(
  'surveyBuilder/ADD_QUESTION_TO_SURVEY',
);

export const removeQuestionFromSurvey = createAction<ID>(
  'surveyBuilder/REMOVE_QUESTION_FROM_SURVEY',
);

export const cleanSurveyBuilder = createAction(
  'surveyBuilder/CLEAN_SURVEY_BUILDER',
);

interface State {
  surveyToEdit: Survey | null;
  selectedQuestion: SurveyQuestion | null;
  questionsChangeLogForUpdate: {
    deleted: Record<ID, boolean>;
    created: Record<ID, boolean>;
    updated: Record<ID, boolean>;
  };
}

export const initialState: State = {
  surveyToEdit: null,
  selectedQuestion: null,
  // We need to keep track of changes to the survey in order to update it (see API docs)
  questionsChangeLogForUpdate: {
    deleted: {},
    created: {},
    updated: {},
  },
};

export default createReducer<State>(initialState, (builder) => {
  const loadSurvey = (
    state: State,
    action: PayloadAction<SharedLoadSurveyPayload>,
  ) => {
    state.surveyToEdit = action.payload.survey;

    if (action.payload.question) {
      state.selectedQuestion = action.payload.question;
    }
  };

  builder.addCase(loadBaseSurveyToCreate, loadSurvey);
  builder.addCase(loadSurveyToEditData, loadSurvey);
  builder.addCase(loadSurveyFromTemplateData, loadSurvey);

  builder.addCase(saveSurveyToEdit.fulfilled, (state, action) => {
    if (state.surveyToEdit && action.payload) {
      state.surveyToEdit = { ...state.surveyToEdit, ...action.payload };
    }
  });

  builder.addCase(changeQuestions, (state, action) => {
    if (state.surveyToEdit) {
      state.surveyToEdit.questions = state.surveyToEdit?.questions?.map(
        (question) => {
          const questionToReplace = action.payload.find(
            (q) => q.id === question.id,
          );

          if (questionToReplace?.id === state.selectedQuestion?.id) {
            state.selectedQuestion = questionToReplace || null;
          }

          return questionToReplace || question;
        },
      );
    }
  });

  builder.addCase(changeSelectedQuestion, (state, action) => {
    if (state.surveyToEdit) {
      // We apply the changes made to the question in the real question within the survey
      state.surveyToEdit = replaceQuestionInSurvey(
        state.surveyToEdit,
        action.payload,
      );
    }

    state.questionsChangeLogForUpdate.updated[action.payload.id] = true;
    state.selectedQuestion = action.payload;
  });

  builder.addCase(addQuestionToSurvey, (state, action) => {
    if (state.surveyToEdit?.questions) {
      state.questionsChangeLogForUpdate.created[action.payload.id] = true;
      state.surveyToEdit.questions.push(action.payload);
      state.selectedQuestion = action.payload;
    } else {
      // TODO: Log error.
    }
  });

  builder.addCase(removeQuestionFromSurvey, (state, action) => {
    if (state.surveyToEdit?.questions) {
      state.questionsChangeLogForUpdate.deleted[action.payload] = true;
      state.surveyToEdit.questions = state.surveyToEdit.questions.filter(
        (question) => question.id !== action.payload,
      );

      if (state.selectedQuestion?.id === action.payload) {
        state.selectedQuestion = state.surveyToEdit.questions[0];
      }
    } else {
      // TODO: Log error.
    }
  });

  builder.addCase(cleanSurveyBuilder, () => {
    return initialState;
  });
});
