import classnames from 'classnames';

import styles from './styles.module.scss';

import Spacing from '../../../../../../../shared/components/core-ui/Spacing';
import P from '../../../../../../../shared/components/core-ui/text/P';
import LiveSurveyResponseProps from '../../../../../../../shared/types/surveys/liveSurveys/liveSurveys';
import { SurveyBooleanResponse } from '../../../../../../../shared/types/surveys/questions/booleanResponse';

interface Props extends LiveSurveyResponseProps {
  questionConfig: SurveyBooleanResponse;
}

export default function LiveSurveyBooleanAnswer({
  value: isEnabled,
  onChange,
  renderFooter,
  questionConfig,
}: Props): React.ReactElement {
  const handleChange = (isNowEnabled: boolean) => {
    if (isNowEnabled !== isEnabled) {
      onChange(isNowEnabled);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div
          className={classnames(styles.switch, { [styles.enabled]: isEnabled })}
        />

        <div
          onClick={() => handleChange(false)}
          className={classnames(styles.optionContainer, {
            [styles.selected]: isEnabled,
          })}
        >
          <P>{questionConfig.config.no?.text || 'No'}</P>
        </div>

        <Spacing total={1} />

        <div
          onClick={() => handleChange(true)}
          className={classnames(styles.optionContainer, {
            [styles.selected]: !isEnabled,
          })}
        >
          <P>{questionConfig.config.yes?.text || 'Yes'}</P>
        </div>
      </div>

      {renderFooter({ buttonDisabled: true })}
    </>
  );
}
