export default function generateBasicHtml({
  headInnerHtml,
}: {
  headInnerHtml: string;
}): string {
  return `
    <!DOCTYPE html>
    <html>
      <head>
        ${headInnerHtml}
      </head>
      <body style="margin: 0;">
        <div></div>
      </body>
    </html>
  `;
}
