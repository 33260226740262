/* eslint-disable motivato/all-components-should-have-storybook-stories */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable motivato/default-export-name-and-path-should-match */
import React from 'react';

import styles from './styles.module.scss';

import Button from '../core-ui/Button';
import Title from '../core-ui/text/Title';

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component {
  state: State = { hasError: false };

  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(/* error: unknown, errorInfo: ErrorInfo */): void {
    // TODO: Log error.
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <div className={styles.container}>
          <Title>Something went wrong.</Title>

          <Button onClick={() => this.setState({ hasError: false })}>
            Try again
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}
