/* eslint-disable motivato/all-components-should-have-storybook-stories */
import MuiMenuItem from '@mui/material/MenuItem';
import React from 'react';

interface Props<MenuItemType> {
  key?: string | number;
  option: MenuItemType;
  onOptionSelected: (selectedOption: MenuItemType) => void;
}

function MenuItem<MenuItemType = string>(
  { key, option, onOptionSelected }: Props<MenuItemType>,
  ref: React.ForwardedRef<HTMLLIElement>,
): React.ReactElement {
  return (
    <MuiMenuItem ref={ref} key={key} onClick={() => onOptionSelected(option)}>
      {option}
    </MuiMenuItem>
  );
}

export default React.forwardRef(MenuItem);
