import classnames from 'classnames';

import styles from './styles.module.scss';

export enum WaveVariant {
  verticalAndComplex = 'verticalAndComplex',
  horizontalAndSimple = 'horizontalAndSimple',
}

interface Props {
  color: string;
  variant?: WaveVariant;
  className?: string;
  decideBasedOnSize?: boolean;
}

export default function Wave({
  color,
  variant = WaveVariant.verticalAndComplex,
  className,
  decideBasedOnSize: decideBasedOnMediaQuery,
}: Props): React.ReactElement {
  const desktopWave = (
    <svg
      viewBox="0 0 309.142 1151"
      className={classnames(styles.verticalWave, className)}
    >
      <path
        d="M309.142,1151H0V0H139.284c-.093.1-9.579,10.359-23.267,27.69C108,37.84,100.15,48.376,92.684,59,83.352,72.289,74.6,85.757,66.664,99.035,46.47,132.83,31.409,165.6,21.9,196.449c-11.886,38.554-15.08,74.183-9.5,105.9,1.337,7.593,3.044,15.111,5.072,22.347,1.929,6.881,4.212,13.727,6.786,20.348a230.941,230.941,0,0,0,17.642,35.734c13.214,21.962,28.9,40.715,44.073,58.85,9.505,11.363,18.483,22.094,26.829,33.488,4.484,6.121,8.352,11.846,11.825,17.5a187.751,187.751,0,0,1,10.11,18.64,155.613,155.613,0,0,1,13.106,42.593,172.076,172.076,0,0,1,2.012,24.9,211.466,211.466,0,0,1-1.671,27.94c-.935,7.536-2.17,15.225-3.668,22.855-1.426,7.259-3.137,14.7-5.086,22.115a439.323,439.323,0,0,1-13.551,42.292c-10.245,27.43-22.568,53.555-34.485,78.82-31.252,66.257-60.771,128.838-45.6,200.264,13.574,63.924,68.674,106.257,112.507,130.513a412.222,412.222,0,0,0,40.725,19.607c13.116,5.472,26.736,10.412,40.479,14.683,24.57,7.636,49.3,13.02,69.636,15.161Z"
        id="Sustraccion_1"
        fill={color}
        data-name="Sustraccion 1"
      />
    </svg>
  );

  const mobileWave = (
    <svg
      viewBox="0 0 409.369 32.64"
      className={classnames(styles.horizontalWave, className)}
    >
      <path
        id="Subtraction_2"
        data-name="Subtraction 2"
        d="M415.37,230.2H6v-11.87c12.312.965,45.3,3.479,85.643,5.962,50.143,3.086,93.482,5.026,128.814,5.764,2.481.052,5.046.078,7.623.078a477.246,477.246,0,0,0,59.9-4.088c17.7-2.258,36.345-5.463,55.414-9.526,14.81-3.155,29.809-6.81,44.58-10.863,12.22-3.353,21.644-6.254,27.4-8.1V230.2Z"
        transform="translate(-6 -197.564)"
        fill={color}
      />
    </svg>
  );

  if (decideBasedOnMediaQuery) {
    return (
      <>
        <div className={styles.hideOnMobile}>{desktopWave}</div>
        <div className={styles.displayOnMobile}>{mobileWave}</div>
      </>
    );
  }

  if (variant === WaveVariant.verticalAndComplex) {
    return desktopWave;
  }

  return mobileWave;
}
