/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-unused-vars */
import { FetchResult } from '@apollo/client';

import { GenericResponseType } from '../apolloClient';

interface ResponseType extends GenericResponseType {
  id: number;
}

const mutationName = 'DeleteGroup';
type Response = Record<typeof mutationName, ResponseType>;

export default async function deleteTeam(
  teamId: string | number,
): Promise<FetchResult<Response>> {
  return Promise.resolve({
    data: {
      DeleteGroup: {
        ok: true,
        id: 1,
      },
    },
  });

  // TODO: Implement this query from the new schema in the Monolith instead.
  // return apolloClient.mutate<Response>({
  //   mutation: gql`
  //     mutation ${mutationName}(
  //       $id: ID!,
  //     ) {
  //       ${mutationName}(
  //         input: {
  //           groupID: $id,
  //         }
  //       ) {
  //         ok
  //         error {
  //           code
  //           message
  //         }
  //       }
  //     }
  //   `,
  //   variables: {
  //     id: teamId,
  //   },
  // });
}
