import {
  CustomFrequencyDay,
  CustomFrequencyEvery,
} from '../../../enums/schedulingEvents/frequency';

interface Args {
  repeats: boolean;
  datetime: moment.Moment;
  daysOfMonth: number[];
  weekFrequency: CustomFrequencyDay[];
  customFrequency: CustomFrequencyEvery;
}

export default function createCronString({
  repeats,
  datetime,
  daysOfMonth,
  weekFrequency,
  customFrequency,
}: Args): string | null {
  if (!repeats) {
    return null;
  }

  const cronMinuteStr = datetime.format('H');
  const cronHourStr = datetime.format('m');
  const cronMonthStr = '*';
  let cronDayOfMonthStr = '*';
  let cronDayOfWeekStr = '*';

  if (
    customFrequency === CustomFrequencyEvery.Week &&
    weekFrequency?.length &&
    // ...and if there are less than 7 days selected.
    weekFrequency.length < Object.keys(CustomFrequencyDay).length
  ) {
    cronDayOfWeekStr = weekFrequency.map((day) => day.toUpperCase()).join(',');
  }

  if (
    customFrequency === CustomFrequencyEvery.Month &&
    daysOfMonth.length < 31
  ) {
    cronDayOfMonthStr = daysOfMonth.join(',');
  }

  return `${cronHourStr} ${cronMinuteStr} ${cronDayOfMonthStr} ${cronMonthStr} ${cronDayOfWeekStr}`;
}
