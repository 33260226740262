import MuiButton from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import classnames from 'classnames';
import React, { ForwardedRef } from 'react';

import styleVariables from '../../../styles/_exports.module.scss';
import styles from './styles.module.scss';

import generateTheme from '../../../helpers/mui/theme';

export enum ButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
}

export enum ButtonType {
  Default = 'button',
  Reset = 'reset',
  Submit = 'submit',
}

interface MuiVariantsInterface {
  [ButtonVariant.primary]: 'contained';
  [ButtonVariant.secondary]: 'outlined';
  [ButtonVariant.tertiary]: 'text';
}

const VARIANTS_TO_MUI: MuiVariantsInterface = {
  [ButtonVariant.primary]: 'contained',
  [ButtonVariant.secondary]: 'outlined',
  [ButtonVariant.tertiary]: 'text',
};

interface Props {
  flex?: boolean;
  onClick: () => void;
  variant?: ButtonVariant;
  children: string;
  disabled?: boolean;
  className?: string;
  type?: ButtonType;
}

const theme = generateTheme({
  palette: {
    primary: {
      main: styleVariables.primaryColor,
    },
  },
});

function Button(
  {
    flex,
    variant = ButtonVariant.secondary,
    onClick,
    disabled,
    children,
    className,
    type = ButtonType.Default,
    ...otherProps
  }: Props,
  ref: ForwardedRef<HTMLButtonElement>,
): React.ReactElement {
  return (
    <ThemeProvider theme={theme}>
      <MuiButton
        type={type}
        ref={ref}
        {...otherProps}
        variant={VARIANTS_TO_MUI[variant]}
        onClick={onClick}
        disabled={disabled}
        style={{ flex: flex ? 1 : 'none' }}
        className={classnames(styles.button, className, {
          [styles.secondary]: variant === ButtonVariant.secondary,
        })}
      >
        {children}
      </MuiButton>
    </ThemeProvider>
  );
}

export default React.forwardRef(Button);
