export enum PredefinedFrequency {
  Custom = 'Custom',
  EveryMonday = 'EveryMonday',
  EveryFriday = 'EveryFriday',
  EveryWeekday = 'EveryWeekday',
  EveryTwoWeeks = 'EveryTwoWeeks',
  Every1stOfMonth = 'Every1stOfMonth',
}

export enum CustomFrequencyEvery {
  Week = 'Week',
  Month = 'Month',
}

export enum CustomFrequencyDay {
  // Key must be in CamelCase and the Value must be an uppercase three letter code
  // It must also be in order Mon -> Sun
  Mon = 'MON',
  Tue = 'TUE',
  Wed = 'WED',
  Thu = 'THU',
  Fri = 'FRI',
  Sat = 'SAT',
  Sun = 'SUN',
}
