import { useState } from 'react';
import { createRef, useLayoutEffect } from 'react';

interface NewSize {
  width?: number;
  height?: number;
}

interface ReturnType {
  ref: React.RefObject<HTMLDivElement>;
  size: NewSize;
}

export default function useResizeObserver(): ReturnType {
  const ref = createRef<HTMLDivElement>();
  const [containerSize, setContainerSize] = useState<NewSize>({});

  useLayoutEffect(() => {
    let resizeObserver: ResizeObserver;

    if (ref.current) {
      resizeObserver = new ResizeObserver((target) => {
        const container = ref.current || target[0]?.target;

        if (container?.clientWidth && container?.clientHeight) {
          setContainerSize({
            width: container.clientWidth,
            height: container.clientHeight,
          });
        }
      });

      resizeObserver.observe(ref.current);
    } else {
      // TODO: Log error: Ref wasn't attached correctly.
    }

    return () => resizeObserver.disconnect();
  }, []);

  return {
    ref,
    size: containerSize,
  };
}
