import classnames from 'classnames';

import styles from './styles.module.scss';

import P from '../../core-ui/text/P';

interface Props {
  difference: number | null;
}

export default function DifferenceTag({
  difference,
}: Props): React.ReactElement {
  let text = 'Same';

  const isUp = difference && difference > 0;
  const isDown = difference && difference < 0;

  if (isUp) {
    text = `Up ${difference}%`;
  } else if (isDown) {
    text = `Down ${difference}%`;
  }

  return (
    <div
      className={classnames(styles.container, {
        [styles.isUp]: isUp,
        [styles.isDown]: isDown,
      })}
    >
      <P>{text}</P>
    </div>
  );
}
