import moment from 'moment';

import styles from './styles.module.scss';

import NoteFromUser from '../../../../shared/components/core-ui/NoteFromUser';
import Spacing from '../../../../shared/components/core-ui/Spacing';
import P from '../../../../shared/components/core-ui/text/P';

export default function TeammateTaskAssignment(): React.ReactElement {
  return (
    <div className={styles.container}>
      <div className={styles.sectionContainer}>
        <P>Present interests</P>
        <Spacing total={1} />

        <div className={styles.notesContainer}>
          <div className={styles.noteContainer}>
            <NoteFromUser
              text="Sunt veniam minim Lorem reprehenderit dolore elit ea."
              date={moment()}
            />
          </div>

          <div className={styles.noteContainer}>
            <NoteFromUser
              text="Sunt veniam minim Lorem reprehenderit dolore elit ea."
              date={moment()}
            />
          </div>

          <div className={styles.noteContainer}>
            <NoteFromUser
              text="Sunt veniam minim Lorem reprehenderit dolore elit ea."
              date={moment()}
            />
          </div>
        </div>
      </div>

      <div className={styles.sectionContainer}>
        <P>Future interests</P>
        <Spacing total={1} />

        <div className={styles.notesContainer}>
          <div className={styles.noteContainer}>
            <NoteFromUser
              text="Sunt veniam minim Lorem reprehenderit dolore elit ea."
              date={moment()}
            />
          </div>
        </div>
      </div>

      <div className={styles.sectionContainer}>
        <P>Strength</P>
        <Spacing total={1} />

        <div className={styles.notesContainer}>
          <div className={styles.noteContainer}>
            <NoteFromUser
              text="Sunt veniam minim Lorem reprehenderit dolore elit ea."
              date={moment()}
            />
          </div>
        </div>
      </div>

      <div className={styles.sectionContainer}>
        <P>Learning goals</P>
        <Spacing total={1} />

        <div className={styles.notesContainer}>
          <div className={styles.noteContainer}>
            <NoteFromUser
              text="Sunt veniam minim Lorem reprehenderit dolore elit ea."
              date={moment()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
