import classnames from 'classnames';

import styles from './styles.module.scss';

import Spacing from '../core-ui/Spacing';
import Label from '../core-ui/text/Label';
import P from '../core-ui/text/P';
import Title from '../core-ui/text/Title';

export enum SlackButtonSizeVariant {
  small = 'small',
  default = 'default',
  large = 'large',
}

interface Props {
  text: string;
  title: string;
  className?: string;
  percentage: number;
}

export default function VerticalStatSummary({
  text,
  title,
  className,
  percentage,
}: Props): React.ReactElement {
  return (
    <div className={classnames(styles.container, className)}>
      <Label uppercase>{title}</Label>
      <Spacing vertical={1} />

      <Title noMargin>{percentage}%</Title>
      <Spacing vertical={1} />

      <P>{text}</P>
    </div>
  );
}
