import MUIAutocomplete, {
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import MUIBox from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

import styles from './styles.module.scss';

import { ShallowModify } from '../../../helpers/typescript/shallowModify';
import Spacing from '../Spacing';
import TextField, { TextFieldSize } from '../TextField';

interface SingleSelectionProps<OptionType> {
  open?: boolean;
  label: string;
  value?: OptionType | null;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  options: OptionType[];
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  groupBy?: (option: OptionType) => string;
  loading?: boolean;
  multiple?: false;
  freeSolo?: boolean;
  onChange?: (
    event: React.SyntheticEvent,
    option: OptionType | string | (OptionType | string)[] | null,
  ) => void;
  renderInput?: (param: AutocompleteRenderInputParams) => React.ReactElement;
  defaultValue?: OptionType;
  onOpenChange?: (isOpen: boolean) => void;
  textFieldProps?: Partial<Parameters<typeof TextField>[0]>;
  getOptionLabel?: (option: OptionType) => string;
  leftOptionAdornment?: (option: OptionType) => React.ReactElement | null;
  isOptionEqualToValue?: (option: OptionType, value: OptionType) => boolean;
}

type MultipleSelectionProps<OptionType> = ShallowModify<
  SingleSelectionProps<OptionType>,
  {
    value?: OptionType[];
    multiple: true;
    defaultValue?: OptionType[];
  }
>;

type Props<OptionType> =
  | SingleSelectionProps<OptionType>
  | MultipleSelectionProps<OptionType>;

function Autocomplete<OptionType>(
  {
    open,
    label,
    value,
    onBlur,
    onFocus,
    options,
    groupBy,
    loading,
    multiple,
    freeSolo,
    onChange,
    renderInput,
    defaultValue,
    onOpenChange,
    getOptionLabel,
    textFieldProps = {},
    leftOptionAdornment,
    isOptionEqualToValue,
  }: Props<OptionType>,
  ref: React.ForwardedRef<HTMLInputElement>,
): React.ReactElement {
  if (loading) {
    if (!textFieldProps.rightButtonIcon) {
      textFieldProps.rightIcon = <CircularProgress color="inherit" size={20} />;
    } else {
      textFieldProps.rightButtonIcon = (
        <CircularProgress color="inherit" size={20} />
      );
    }
  }

  // Disable default clearable icon ('x') and its styles.
  // The autocomplete types seem to have an issue, in which it won't allow us
  // to set disableClearable and defaultValue at the same time. That's why we
  // skip the check by deconstructing this object into MUIAutocomplete's props.
  const extraProps = {
    forcePopupIcon: false,
    disableClearable: true,
    onChange,
  };

  return (
    <div className={styles.container}>
      <MUIAutocomplete
        ref={ref}
        open={open}
        value={value}
        onBlur={onBlur}
        onOpen={() => onOpenChange && onOpenChange(true)}
        onFocus={onFocus}
        groupBy={groupBy}
        options={options}
        loading={loading}
        onClose={() => onOpenChange && onOpenChange(false)}
        freeSolo={freeSolo}
        multiple={multiple}
        defaultValue={defaultValue}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        renderOption={(optionProps, option) => (
          <MUIBox component="li" {...optionProps}>
            {leftOptionAdornment && (
              <>
                {leftOptionAdornment(option)}
                <Spacing horizontal={1} />
              </>
            )}

            {getOptionLabel ? getOptionLabel(option as OptionType) : option}
          </MUIBox>
        )}
        renderInput={(autocompleteParams) =>
          renderInput?.(autocompleteParams) || (
            <TextField
              label={label}
              {...textFieldProps}
              {...{
                ...autocompleteParams,
                size:
                  autocompleteParams.size &&
                  TextFieldSize[autocompleteParams.size],
              }}
            />
          )
        }
        {...extraProps}
      />
    </div>
  );
}

export default React.forwardRef(Autocomplete) as <T>(
  p: Props<T> & { ref?: React.Ref<HTMLInputElement> },
) => React.ReactElement;
