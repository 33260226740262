import classnames from 'classnames';

import styles from './styles.module.scss';

import P from '../core-ui/text/P';

interface Props {
  icon?: React.ReactNode;
  title?: string;
  onClick?: () => void;
  children: React.ReactNode;
  highlight?: boolean;
}

export default function OutlinedSection({
  icon,
  title,
  onClick,
  children,
  highlight,
}: Props): React.ReactElement {
  return (
    <div
      onClick={onClick}
      className={classnames(styles.container, {
        [styles.withIcon]: !!icon,
        [styles.clickable]: !!onClick,
        [styles.highlight]: highlight,
      })}
    >
      <div className={styles.titleContainer}>
        {title && <P uppercase>{title}</P>}
      </div>

      <div className={styles.content}>
        {icon && <div className={styles.icon}>{icon}</div>}

        {children}
      </div>
    </div>
  );
}
