import classnames from 'classnames';
import truncate from 'lodash/truncate';

import styles from './styles.module.scss';

import SurveyTemplate from '../../types/surveys/template';
import P from '../core-ui/text/P';
import Title from '../core-ui/text/Title';

const DESCRIPTION_LENGTH_TRUNCATE = 60;

interface Props {
  onClick: () => void;
  verticalLayout?: boolean;
  surveyTemplate: SurveyTemplate;
}

export default function SurveyTemplateCard({
  onClick,
  verticalLayout,
  surveyTemplate,
}: Props): React.ReactElement {
  return (
    <div
      className={classnames(styles.container, {
        [styles.verticalLayout]: verticalLayout,
      })}
    >
      <div className={styles.imageContainer}>
        <div
          className={styles.image}
          style={{ backgroundImage: `url(${surveyTemplate.thumbnailUrl})` }}
        />
      </div>

      <section className={styles.contentSection}>
        <main className={styles.content}>
          <Title>{surveyTemplate.name}</Title>

          <P>
            {truncate(surveyTemplate.description, {
              length: DESCRIPTION_LENGTH_TRUNCATE,
            })}
          </P>
        </main>

        <div className={styles.button} onClick={onClick} role="button">
          <P noMargin>Check it out</P>
        </div>
      </section>
    </div>
  );
}
