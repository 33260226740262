import { useMemo } from 'react';

import styles from './styles.module.scss';

import P from '../../../../shared/components/core-ui/text/P';
import getCronText from '../../../../shared/helpers/cronString/getCronText';

interface Props {
  cron: string | null;
}

export default function SchedulingEventFrequencyPreview({
  cron,
}: Props): React.ReactElement {
  const cronString = useMemo(() => {
    return getCronText({ cron });
  }, [cron]);

  return (
    <div className={styles.container}>{cronString && <P>{cronString}.</P>}</div>
  );
}
