import { FetchResult, gql } from '@apollo/client';

import ID from '../../types/id';
import SurveyQuestion from '../../types/surveys/question';
import Survey from '../../types/surveys/survey';
import { GenericResponseType, apolloClient } from '../apolloClient';
import transformSurveysData from './dataTransformers/surveys';

interface ResponseType extends GenericResponseType {
  id: ID;
}

const mutationName = 'surveyCreate';
type Response = Record<typeof mutationName, ResponseType>;

export default async function createSurvey({
  survey,
}: {
  survey: Survey;
}): Promise<FetchResult<Response>> {
  if (survey.id) {
    // TODO: Log error.
  }

  const newSurvey = transformSurveysData(survey);

  return apolloClient.mutate<Response>({
    mutation: gql`
      mutation surveyCreate($createSurveyInput: CreateSurveyInput!) {
        surveyCreate(createSurveyInput: $createSurveyInput) {
          id
        }
      }
    `,
    variables: {
      createSurveyInput: {
        ...newSurvey,
        questions: newSurvey.questions?.map(
          (question: Partial<SurveyQuestion>) => {
            delete question.id;
            return question;
          },
        ),
      },
    },
  });
}
