/* eslint-disable motivato/all-components-should-have-storybook-stories */
import React from 'react';

import { resolveCommonCustomIconProps } from '../../../../../helpers/icons/custom';
import { CommonIconProps } from '../../../../../types/iconsCommon';

function SurveySliderIcon(
  props: CommonIconProps,
  ref: React.ForwardedRef<SVGSVGElement>,
): React.ReactElement {
  const { color, width, height } = resolveCommonCustomIconProps(props);

  return (
    <svg ref={ref} width={width} height={height} viewBox="0 0 22.342 8.077">
      <g
        id="Grupo_15161"
        data-name="Grupo 15161"
        transform="translate(1995.622 3134.161)"
      >
        <path
          id="Trazado_11212"
          data-name="Trazado 11212"
          d="M-1974.269-3131.11h-10.689a4.042,4.042,0,0,0-3.913-3.051,4.044,4.044,0,0,0-3.917,3.051h-1.845a.99.99,0,0,0-.989.99.992.992,0,0,0,.989.989h1.845a4.039,4.039,0,0,0,3.917,3.047,4.038,4.038,0,0,0,3.913-3.047h10.689a.992.992,0,0,0,.989-.989A.99.99,0,0,0-1974.269-3131.11Zm-12.8,1.979a2.059,2.059,0,0,1-1.806,1.068,2.072,2.072,0,0,1-1.81-1.068,2.1,2.1,0,0,1-.252-.989,2.107,2.107,0,0,1,.252-.99,2.067,2.067,0,0,1,1.81-1.073,2.066,2.066,0,0,1,1.806,1.073,2.107,2.107,0,0,1,.252.99A2.1,2.1,0,0,1-1987.065-3129.131Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default React.forwardRef(SurveySliderIcon);
