import { gql, FetchResult } from '@apollo/client';

import SurveyTemplate from '../../types/surveys/template';
import { apolloClient } from '../apolloClient';

type ResponseType = SurveyTemplate[];

const queryName = 'templates';
type Response = Record<typeof queryName, ResponseType | null>;

interface Args {
  limit?: number;
}

export default function fetchSurveyTemplates(
  args?: Args,
): Promise<FetchResult<Response>> {
  return apolloClient
    .query<Response>({
      query: gql`
        query templates {
          templates {
            id
            name
            description
            thumbnailUrl
            tags
            whyUseThis {
              text
              sourceName
              sourceUrl
            }
            questions {
              id
              name
              question
              animation {
                id
                url
              }
            }
          }
        }
      `,
    })
    .then((templatesData) => {
      if (templatesData.data?.templates?.length && args?.limit) {
        return {
          ...templatesData,
          data: {
            templates: templatesData.data.templates?.slice(0, args?.limit),
          },
        };
      }

      return templatesData;
    });
}
