import styles from './styles.module.scss';

import TextField, {
  TextFieldSize,
} from '../../../../../../shared/components/core-ui/TextField';
import { SurveyListResponse } from '../../../../../../shared/types/surveys/questions/listResponse';

interface Props {
  questionConfig: SurveyListResponse;
  onConfigChange: (questionConfig: SurveyListResponse) => void;
}

export default function SurveyListConfig({
  questionConfig,
  onConfigChange,
}: Props): React.ReactElement {
  const changeHandler = (newConfig: SurveyListResponse['config']) => {
    onConfigChange({
      ...questionConfig,
      config: {
        ...questionConfig.config,
        ...newConfig,
      },
    });
  };

  return (
    <div className={styles.container}>
      <TextField
        size={TextFieldSize.small}
        label="Placeholder"
        value={questionConfig.config?.placeholder || ''}
        onChangeText={(placeholder) =>
          changeHandler({
            placeholder,
          })
        }
      />
    </div>
  );
}
