/* eslint-disable motivato/all-components-should-have-storybook-stories */

/* eslint-disable motivato/default-export-name-and-path-should-match */
import SurveyQuestion from '../../../../types/surveys/question';
import Survey from '../../../../types/surveys/survey';
import transformSurveyQuestionsData from '../surveyQuestions';

export default function transformSurveysData(survey: Survey): Survey {
  return {
    id: survey.id,
    name: survey.name,
    isValid: survey.isValid,
    isEnabled: survey.isEnabled,
    questions: survey.questions?.length
      ? (transformSurveyQuestionsData(
          survey.questions,
        ) as unknown as SurveyQuestion[])
      : [],
    templateId: survey.templateId,
  };
}
