import { useMemo } from 'react';

import styles from './styles.module.scss';

import { SlackItemType } from '../../../../shared/components/SlackItemIcon';
import SlackItemIcon from '../../../../shared/components/SlackItemIcon';
import Autocomplete from '../../../../shared/components/core-ui/Autocomplete';
import PossibleAudienceType from '../../../../shared/enums/schedulingEvents/possibleAudienceType';
import PossibleAudience from '../../../../shared/types/surveys/schedulingEvents/possibleAudience';
import getAudienceFromAutocompleteItems from './helpers/getAudienceFromAutocompleteItems';
import getAutocompleteItemsFromPossibleAudience from './helpers/getAutocompleteItemsFromPossibleAudience';
import getPossibleAudienceFlatIds from './helpers/getPossibleAudienceFlatIds';
import AudienceOptionItem from './types/audienceOptionItemAutocomplete';

interface Props {
  loading: boolean;
  onChange: (selectedAudience: PossibleAudience) => void;
  possibleAudience?: PossibleAudience;
  selectedAudience: PossibleAudience;
}

export default function SchedulingEventAudience({
  loading,
  onChange,
  possibleAudience,
  selectedAudience,
}: Props): React.ReactElement {
  const options: AudienceOptionItem[] = useMemo(
    () =>
      possibleAudience
        ? getAutocompleteItemsFromPossibleAudience(possibleAudience)
        : [],
    [
      possibleAudience?.users,
      possibleAudience?.slackUsers,
      possibleAudience?.slackChannels,
    ],
  );

  const changeHandler = (newOptions: AudienceOptionItem[]) => {
    onChange(getAudienceFromAutocompleteItems(newOptions));
  };

  const autocompleteValue = useMemo(() => {
    const ids = getPossibleAudienceFlatIds(selectedAudience);

    return ids
      .map((id) => options.find(({ id: optionId }) => id === optionId))
      .filter((x) => x) as AudienceOptionItem[];
  }, [options, selectedAudience]);

  return (
    <div className={styles.container}>
      <Autocomplete<AudienceOptionItem>
        label="Who should receive it?"
        value={autocompleteValue}
        options={options}
        loading={loading}
        multiple
        freeSolo
        onChange={(__, newOptions) =>
          changeHandler(newOptions as AudienceOptionItem[])
        }
        getOptionLabel={(option: AudienceOptionItem) => {
          return option.name;
        }}
        leftOptionAdornment={(optionItem: AudienceOptionItem) => {
          if (optionItem.type === PossibleAudienceType.User) {
            return null;
          }

          if (optionItem.type === PossibleAudienceType.SlackUser) {
            return <SlackItemIcon type={SlackItemType.user} />;
          }

          return <SlackItemIcon type={SlackItemType.publicChannel} />;
        }}
      />
    </div>
  );
}
