import { gql } from '@apollo/client';

import { GlobalSearchItem } from '../../components/GlobalSearch';
import ID from '../../types/id';
import { apolloClient } from '../apolloClient';
import { transformGlobalSearchTerms } from './dataTransformers/search';

type SearchResult = {
  id: ID;
  name: string;
};

export type NetworkingGlobalSearchResults = {
  users?: SearchResult[];
  surveys?: SearchResult[];
  templates?: SearchResult[];
};

type ResponseType = NetworkingGlobalSearchResults;

const queryName = 'globalSearch';
type Response = Record<typeof queryName, ResponseType | null>;

interface Args {
  searchTerms: string;
}

export default async function fetchGlobalSearch({
  searchTerms,
}: Args): Promise<GlobalSearchItem[]> {
  const result = await apolloClient.query<Response>({
    query: gql`
      query nameSearch($searchTerms: NameSearchInput!) {
        globalSearch(globalSearchInput: $searchTerms) {
          surveys {
            id
            name
          }
          templates {
            id
            name
          }
          users {
            id
            name
          }
        }
      }
    `,
    variables: {
      searchTerms: {
        name: searchTerms,
      },
    },
  });

  return transformGlobalSearchTerms(result.data.globalSearch);
}
