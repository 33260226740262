import styles from './styles.module.scss';

import SurveyTemplateCard from '../../../../shared/components/SurveyTemplateCard';
import Separator from '../../../../shared/components/core-ui/Separator';
import Spacing from '../../../../shared/components/core-ui/Spacing';
import P from '../../../../shared/components/core-ui/text/P';
import Title from '../../../../shared/components/core-ui/text/Title';
import SurveyTemplate from '../../../../shared/types/surveys/template';
import BaselineSurveyCard from '../BaselineSurveyCard';

interface Props {
  surveyTemplates: SurveyTemplate[];
  onTemplateClick: (template: SurveyTemplate) => void;
}

export default function HomeForFirstTimeUserLayout({
  surveyTemplates,
  onTemplateClick,
}: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      <Spacing total={2} />
      <Title center>Welcome to Motivato</Title>
      <P center>Let's get you started</P>

      <Spacing total={5} />

      <div className={styles.baselineContainer}>
        <BaselineSurveyCard />
      </div>

      <Spacing total={2} />

      <div className={styles.separatorContainer}>
        <Separator spacing={3} />
      </div>

      <Title center>Or use one of our signature templates</Title>

      <Spacing total={2} />

      <div className={styles.templateContainer}>
        {surveyTemplates.map((surveyTemplate) => (
          <div key={surveyTemplate.id} className={styles.cardContainer}>
            <SurveyTemplateCard
              onClick={() => onTemplateClick(surveyTemplate)}
              verticalLayout
              surveyTemplate={surveyTemplate}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
