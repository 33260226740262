import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import fetchRoundOfResponses from '../../../../shared/networking/surveys/fetchRoundOfResponses';
import RoundOfResponses from '../../../../shared/types/surveys/roundOfResponses/roundOfResponses';

interface LoadRoundOfResponsesProps {
  surveyId: string;
}

export const loadRoundOfResponses = createAsyncThunk(
  'roundOfResponses/LOAD_ROUND_OF_RESPONSES',
  async ({
    surveyId,
  }: LoadRoundOfResponsesProps): Promise<RoundOfResponses | null> => {
    const response = await fetchRoundOfResponses({ surveyId });

    return response.data?.roundOfResponses || null;
  },
);

interface State {
  data: RoundOfResponses | null;
  loading: boolean;
  loaded: boolean;
}

export const initialState: State = {
  data: null,
  loading: false,
  loaded: false,
};

export default createReducer<State>(initialState, (builder) => {
  builder
    .addCase(loadRoundOfResponses.pending, (state) => {
      state.loading = true;
      state.loaded = false;
    })
    .addCase(loadRoundOfResponses.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.loaded = true;
    });
});
