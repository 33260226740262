/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-unused-vars */
import { FetchResult } from '@apollo/client';

import SlackUser from '../../types/slack/slackUsers';
import { GenericResponseType } from '../apolloClient';

interface ResponseType extends GenericResponseType {
  id: number;
}

const mutationName = 'SetGroup';
type Response = Record<typeof mutationName, ResponseType>;

export default async function createTeam(
  name: string,
  members: SlackUser[],
  teamLeadIDs: string[],
): Promise<FetchResult<Response>> {
  return Promise.resolve({
    data: {
      SetGroup: {
        ok: true,
        id: 1,
      },
    },
  });
  // TODO: Implement this query from the new schema in the Monolith instead.
  // return apolloClient.mutate<Response>({
  //   mutation: gql`
  //     mutation SetGroup(
  //       $customerID: ID!,
  //       $name: String!,
  //       $members: [SlackUserInput!]!,
  //       $teamLeadIDs: [String!]!,
  //     ) {
  //       SetGroup(
  //         input: {
  //           name: $name,
  //           members: $members
  //           customerID: $customerID,
  //           teamLeadsIDs: $teamLeadIDs,
  //         }
  //       ) {
  //         ok
  //         id
  //         error {
  //           code
  //           message
  //         }
  //       }
  //     }
  //   `,
  //   variables: {
  //     name,
  //     customerID: customerIDMock,
  //     teamLeadIDs,
  //     members: members.map(({ id, name: memberName, email }) => ({
  //       id,
  //       name: memberName,
  //       email,
  //     })),
  //   },
  // });
}
