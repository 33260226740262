import { SurveyQuestionType } from '../../../../enums/surveys/questionType';
import SurveyQuestion from '../../../../types/surveys/question';

interface ReturnType {
  percentage: number | null;
  difference: number | null;
}

export default function getReportDataMeta(
  data: { y?: number }[][],
  question: SurveyQuestion,
): ReturnType {
  let percentageCalculated;
  const lastValue = data[0][data[0].length - 1]?.y;
  const secondToLastValue = data[0][data[0].length - 2]?.y;

  if (question.question.type === SurveyQuestionType.Slider) {
    if (lastValue) {
      percentageCalculated =
        (lastValue / question.question.config.segments.length) * 100;
    }
  }

  if (question.question.type === SurveyQuestionType.Boolean) {
    if (lastValue) {
      percentageCalculated = ((lastValue + 1) / 2) * 100;
    }
  }

  let diff: number | null = null;

  if (lastValue && secondToLastValue) {
    diff = (lastValue / secondToLastValue) * 100 - 100;
  }

  return {
    percentage:
      percentageCalculated || percentageCalculated === 0
        ? +percentageCalculated.toFixed(0)
        : null,
    difference: diff || diff === 0 ? +diff?.toFixed(0) : null,
  };
}
