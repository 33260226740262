import merge from 'lodash/merge';
import React from 'react';
import {
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryThemeDefinition,
} from 'victory';

import styles from './styles.module.scss';

import chartTheme from '../../../../constants/chartTheme/theme';
import useResizeObserver from '../../../../hooks/useResizeObserver';

interface Bar {
  x: string | number;
  y: number;
}

interface Props {
  data: Bar[];
  theme?: VictoryThemeDefinition;
}

function BarChart(
  { theme, data }: Props,
  ref: React.ForwardedRef<VictoryChart>,
): React.ReactElement {
  const { ref: contentRef, size } = useResizeObserver();

  return (
    <div ref={contentRef} className={styles.container}>
      <div className={styles.content}>
        <VictoryChart
          ref={ref}
          theme={theme ? merge(chartTheme, theme) : chartTheme}
          width={size?.width}
          height={size?.height}
          domainPadding={{ x: 30 }}
        >
          <VictoryGroup
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
          >
            <VictoryBar
              data={data}
              barRatio={0.6}
              cornerRadius={{ topLeft: 6, topRight: 6 }}
            />
          </VictoryGroup>
        </VictoryChart>
      </div>
    </div>
  );
}

export default React.forwardRef(BarChart);
