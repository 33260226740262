import styles from './styles.module.scss';

import StatSummaryCard from '../../../shared/components/StatSummaryCard';
import VerticalStatSummary from '../../../shared/components/VerticalStatSummary';
import MainButton, {
  MainButtonVariant,
} from '../../../shared/components/core-ui/MainButton';
import Separator from '../../../shared/components/core-ui/Separator';
import Spacing from '../../../shared/components/core-ui/Spacing';
import LineChart from '../../../shared/components/core-ui/charts/LineChart';
import Label from '../../../shared/components/core-ui/text/Label';
import P from '../../../shared/components/core-ui/text/P';
import Title from '../../../shared/components/core-ui/text/Title';

export default function CompanyBurnout(): React.ReactElement {
  return (
    <div>
      <Title>1. Summary</Title>
      <Spacing total={1} />

      <StatSummaryCard
        className={styles.card}
        mainNumber={90}
        description={`You've hit a sweet spot! Both short-term and
          long-term visibility are high. Motivation, code quality and
          timelines are likely not at risk from roadmap visibility.`}
      >
        <LineChart
          lines={[
            [
              { y: 1, z1: 3, name: 'Fugiat' },
              { y: 5, z1: 2, name: 'Nostrud' },
              { y: 3, z1: 3, name: 'Deserunt' },
            ],
          ]}
        />
      </StatSummaryCard>

      <Spacing vertical={5} />

      <Label>2. The breakdown</Label>
      <Separator spacing={1} />
      <Spacing total={1} />

      <Title>2.1 Time Pressure</Title>
      <Spacing total={1} />
      <P>
        Your team feels the pace of work is fast and deadlines are extremely
        unrealistic. This could mean that task achievability is low, timelines
        are at risk, and burnout could be likely.
      </P>

      <Spacing total={3} />

      <div className={styles.row}>
        <VerticalStatSummary
          text="High"
          percentage={80}
          title="Score"
          className={styles.verticalStatSummary}
        />

        <Spacing horizontal={2} />

        <StatSummaryCard
          title="Use the Daily 2.0"
          kicker="Motivato recommends"
          className={styles.card}
          description={`Want to make sure timelines don't get delayed? Try the Daily 2.0,
          so you can get notified whenever task achievability is low and fix the problem when
          there's still time!`}
        >
          <div className={styles.buttonContainer}>
            <MainButton
              variant={MainButtonVariant.secondary}
              onClick={() => {}}
            >
              Schedule Daily 2.0
            </MainButton>
          </div>
        </StatSummaryCard>
      </div>

      <Separator spacing={1} />
      <Spacing total={1} />

      <Title>2.2 Feeling of Accomplishment</Title>
      <Spacing total={1} />
      <P>
        Your team has very little time to code, and they're only somewhat
        satisfied with their outcome. This could be a burnout indicator! If
        unaddressed for too long, mental health and employee retention could be
        at risk.
      </P>

      <Spacing total={3} />

      <div className={styles.row}>
        <VerticalStatSummary
          text="Medium"
          percentage={50}
          title="Score"
          className={styles.verticalStatSummary}
        />

        <Spacing horizontal={2} />

        <StatSummaryCard
          title="Need a quick fix?"
          kicker="Motivato recommends"
          className={styles.card}
          description={`Try reducing the frequency of meetings that don't add
          much value to your team’s productivity / coding time. Check the bottom
          of this page to see which ones your team feels are less valuable.`}
        >
          <div className={styles.buttonContainer}>
            <MainButton
              variant={MainButtonVariant.secondary}
              onClick={() => {}}
            >
              Quick Read: The Hidden Cost of Meetings
            </MainButton>
          </div>
        </StatSummaryCard>
      </div>

      <Separator spacing={1} />
      <Spacing total={1} />

      <Title>2.3 Excitement & Motivation</Title>
      <Spacing total={1} />
      <P>
        Your team feels they're getting enough recognition, but they're not
        satisfied with their learning and growth opportunities. Motivation could
        be at risk.
      </P>

      <Spacing total={3} />

      <div className={styles.row}>
        <VerticalStatSummary
          text="At risk"
          percentage={20}
          title="Score"
          className={styles.verticalStatSummary}
        />

        <Spacing horizontal={2} />

        <StatSummaryCard
          title="Improve growth opportunities"
          kicker="Motivato recommends"
          className={styles.card}
          description={`Try the Growth Goals check-in. Each dev's answers will get
          updated in their Teammate Profiles, so you've got reference points for
          1:1s and when trying to assign tasks that will keep them engaged.`}
        >
          <div className={styles.buttonContainer}>
            <MainButton
              variant={MainButtonVariant.secondary}
              onClick={() => {}}
            >
              Use Growth Goals Template
            </MainButton>
          </div>
        </StatSummaryCard>
      </div>
    </div>
  );
}
