/* eslint-disable motivato/all-components-should-have-storybook-stories */

/* eslint-disable motivato/default-export-name-and-path-should-match */
import ID from '../../../../types/id';
import SurveyQuestion from '../../../../types/surveys/question';

export default function transformSurveyQuestionsData(
  questions?: SurveyQuestion[],
): Record<string, unknown>[] {
  if (questions) {
    return questions.map((question: SurveyQuestion) => {
      const newQuestion: Omit<Partial<SurveyQuestion>, 'animation'> & {
        questionParsedObjectString: string;
        animationId?: ID;
      } = {
        id: question.id,
        name: question.name,
        sortOrder: question.sortOrder,
        animationId: question.animation?.id,
        isEnabled: question.isEnabled,
        isAnonymous: question.isAnonymous,
        questionParsedObjectString: JSON.stringify(question.question),
        parentId: null,
        followUps: question.followUps?.length
          ? (transformSurveyQuestionsData(
              question.followUps,
            ) as unknown as SurveyQuestion[])
          : [],
        globalQuestionId: question.globalQuestionId,
      };

      return newQuestion;
    });
  }

  return [];
}
