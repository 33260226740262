import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';

import { FULL_CALENDAR_MONTH } from '../../../constants/schedulingEvents/monthDays';
import {
  CustomFrequencyDay,
  CustomFrequencyEvery,
} from '../../../enums/schedulingEvents/frequency';

export default function getCronBreakdown(cronString: string): {
  minute: string;
  hour: string;
  monthDay: number[];
  month: string;
  weekDays: CustomFrequencyDay[];
  selectedFrequency: CustomFrequencyEvery;
} {
  let weekDays: CustomFrequencyDay[] = [];
  let selectedFrequency = CustomFrequencyEvery.Week;
  const [minute, hour, monthDay, month, weekDayStr] = cronString.split(' ');

  if (weekDayStr === '*') {
    weekDays = Object.values(CustomFrequencyDay);
  } else {
    weekDays = weekDayStr.split(',').map((weekDay: string) => {
      const key = startCase(
        lowerCase(weekDay),
      ) as keyof typeof CustomFrequencyDay;

      return CustomFrequencyDay[key];
    });
  }

  if (monthDay !== '*') {
    selectedFrequency = CustomFrequencyEvery.Month;
  }

  if (weekDayStr !== '*' && monthDay !== '*') {
    // TODO: Log error. Having specific weekDays and daysOfTheMonth is not supported.
  }

  return {
    minute,
    hour,
    monthDay:
      monthDay === '*'
        ? FULL_CALENDAR_MONTH
        : monthDay.split(',').map((dayOfMonth) => +dayOfMonth),
    month,
    weekDays,
    selectedFrequency,
  };
}
