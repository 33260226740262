import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import truncate from 'lodash/truncate';
import moment from 'moment';
import React from 'react';

import styles from './styles.module.scss';

import User from '../../../types/auth/user';
import Label from '../text/Label';
import P from '../text/P';

const MAX_AMOUNT_OF_LIST_ITEMS = 3;

interface Props {
  user?: User | null;
  text: string | string[];
  date: string | moment.Moment;
}

function NoteFromUser(
  { user, text, date, ...otherProps }: Props,
  ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
  let textComponent: React.ReactNode;

  if (isString(text)) {
    textComponent = (
      <P>
        {truncate(text, {
          length: 82,
        })}
      </P>
    );
  } else if (isArray(text)) {
    textComponent = text.slice(0, MAX_AMOUNT_OF_LIST_ITEMS).map((line) => (
      <P key={line}>
        -{' '}
        {truncate(line, {
          length: 30,
        })}
      </P>
    ));
  }

  return (
    <div ref={ref} className={styles.container} {...otherProps}>
      <Label>{user?.name || 'Anonymous'}</Label>

      {textComponent}

      <div className={styles.dateContainer}>
        <Label>{moment(date).format('MMM DD')}</Label>
      </div>
    </div>
  );
}

export default React.forwardRef(NoteFromUser);
