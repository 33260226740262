import MuiTooltip from '@mui/material/Tooltip';
import React from 'react';

import styles from './styles.module.scss';

interface Props {
  title: string | null | undefined;
  children: React.ReactElement;
  disabled?: boolean;
}

function Tooltip(
  { title, children, disabled }: Props,
  ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
  return (
    <MuiTooltip
      ref={ref}
      title={!title || disabled ? '' : title}
      classes={{ popper: styles.tooltip }}
    >
      {children}
    </MuiTooltip>
  );
}

export default React.forwardRef(Tooltip);
