import { useRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import styles from './styles.module.scss';

import getNewListResponses from '../../../../../../../shared/helpers/surveys/questions/list/getNewListResponses';
import LiveSurveyResponseProps from '../../../../../../../shared/types/surveys/liveSurveys/liveSurveys';
import { SurveyListResponse } from '../../../../../../../shared/types/surveys/questions/listResponse';
import LiveSurveyListAnswerRow from './components/LiveSurveyListAnswerRow';

interface Props extends LiveSurveyResponseProps {
  questionConfig: SurveyListResponse;
}

export default function LiveSurveyListAnswer({
  renderFooter,
  questionConfig,
}: Props): React.ReactElement {
  const containerRef = useRef<HTMLDivElement>(null);
  const [displayingIndex, setDisplayingIndex] = useState(false);
  const [responses, setResponses] = useState<{ text: string }[]>([
    { text: '' },
  ]);

  const textChangeHandler = (index: number, text: string) => {
    const newResponses = getNewListResponses(responses, index, text);

    if (newResponses.length > responses.length) {
      setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.scroll({
            top: containerRef.current.scrollHeight,
            left: 0,
            behavior: 'smooth',
          });
        }
      }, 300);
    }

    setResponses(newResponses);
  };

  return (
    <>
      <div ref={containerRef} className={styles.container}>
        <TransitionGroup className={styles.contentContainer}>
          {responses.map((response, index) => (
            <CSSTransition
              key={index}
              appear
              timeout={300}
              classNames={{
                enterActive: styles.contentEnter,
                enterDone: styles.contentEnterDone,
                exitActive: styles.contentExit,
                exitDone: styles.contentExitActive,
              }}
            >
              <div className={styles.content}>
                <LiveSurveyListAnswerRow
                  text={response?.text}
                  index={+index}
                  onBlur={() => setDisplayingIndex(false)}
                  onFocus={() => setDisplayingIndex(true)}
                  onTextChange={(text) => textChangeHandler(index, text)}
                  shouldDisplayIndex={displayingIndex || responses.length > 1}
                  placeholder={
                    questionConfig?.config?.placeholder || 'Placeholder example'
                  }
                />
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>

      {renderFooter({ buttonDisabled: true })}
    </>
  );
}
