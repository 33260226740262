import { SliderMark } from '../../../../../components/core-ui/Slider';
import { SliderSegmentConfig } from '../../../../../types/surveys/questions/sliderResponse';

export interface SurveySliderMark extends SliderMark {
  hint: string;
}

export default function getSliderMarkFromSegment(
  segments: SliderSegmentConfig[],
): SurveySliderMark[] {
  return segments.map(({ name }, index) => ({
    hint: name || '',
    value: (index / (segments.length - 1)) * 100,
    label: (index === 0 || segments.length - 1 === index) && name ? name : '',
  }));
}
