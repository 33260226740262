import { isBoolean } from 'lodash';
import isNumber from 'lodash/isNumber';

import { SurveyQuestionType } from '../../../../enums/surveys/questionType';
import { LiveSurveyQuestion } from '../../../../types/surveys/liveSurvey';
import { LiveSurveyResponseValue } from '../../../../types/surveys/liveSurveys/liveSurveys';
import SurveyQuestion from '../../../../types/surveys/question';

/**
 * Will return the percentage to pause an animation at, based on the response of a question.
 * For example:
 *  - Booleans will stop an animation at 0% when False, and 100% when True.
 *  - Sliders will stop an animation at 25% when the 2nd segments is selected (out of 5 segments)
 *
 * Other types will return null, which means that the animation should loop.
 */
export default function getAnimationPercentageFromResponse(
  question: SurveyQuestion | LiveSurveyQuestion,
  response: LiveSurveyResponseValue | null,
): number | null {
  if (
    question.question.type === SurveyQuestionType.Boolean &&
    isBoolean(response)
  ) {
    return response ? 100 : 0;
  }

  if (
    question.question.type === SurveyQuestionType.Slider &&
    isNumber(response)
  ) {
    return response;
  }

  return null;
}
