import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import integrateWithSlack from '../../../../../shared/networking/integrations/slack/slackCode';

export const sendSlackCode = createAsyncThunk(
  'slack/SEND_SLACK_CODE',
  async (code: string) => {
    const response = await integrateWithSlack(code);

    return {
      isValid: !!response?.data?.NewSlack.ok,
      companyName: response?.data?.NewSlack.companyName || null,
    };
  },
);

export default createReducer<{
  isSlackCodeValid: boolean;
  suggestedCompanyName: string | null;
  hasSlackCodeBeenSent: boolean;
}>(
  {
    isSlackCodeValid: false,
    suggestedCompanyName: null,
    hasSlackCodeBeenSent: false,
  },
  (builder) => {
    builder.addCase(sendSlackCode.fulfilled, (state, action) => {
      state.isSlackCodeValid = action.payload.isValid;
      state.hasSlackCodeBeenSent = true;
      state.suggestedCompanyName = action.payload.companyName;
    });
  },
);
