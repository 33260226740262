import sortBy from 'lodash/sortBy';

import { CustomFrequencyDay } from '../../../enums/schedulingEvents/frequency';

interface Args {
  cron: string;
}

export default function sortCronDaysOfWeek({ cron }: Args): string {
  const terms = cron.split(' ');
  const weekDays = terms[terms.length - 1].split(',');

  if (weekDays && weekDays.length > 1) {
    const allDaysSorted: string[] = Object.values(CustomFrequencyDay);

    const sortedWeekDays = sortBy(weekDays, (day) =>
      allDaysSorted.indexOf(day),
    );

    terms[4] = sortedWeekDays.join(',');
  }

  return terms.join(' ');
}
