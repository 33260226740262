/* eslint-disable motivato/all-components-should-have-storybook-stories */
import { Menu as MuiMenu } from '@mui/material';
import React from 'react';

interface Props {
  element: HTMLElement | null;
  onCloseHandler: (event: React.MouseEvent<HTMLElement>) => void;
  children: React.ReactNode;
}

function Menu(
  { element, onCloseHandler, children }: Props,
  ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
  const isOpen = !!element;

  return (
    <MuiMenu
      ref={ref}
      open={isOpen}
      onClose={onCloseHandler}
      anchorEl={element}
    >
      {children}
    </MuiMenu>
  );
}

export default React.forwardRef(Menu);
