/* eslint-disable motivato/all-components-should-have-storybook-stories */
import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '../../shared/hooks/redux/useAppSelector';
import TeammatesLayout from './components/TeammatesLayout';
import { loadTeammates } from './redux/teammates';

export default function Teammates(): React.ReactElement {
  const dispatch = useDispatch();

  const { teammatesList } = useAppSelector(({ teammates }) => ({
    teammatesList: teammates.teammates,
  }));

  useEffect(() => {
    dispatch(loadTeammates());
  }, []);

  return <TeammatesLayout teammates={teammatesList} />;
}
