import { FetchResult, gql } from '@apollo/client';
import omit from 'lodash/omit';

import User from '../../types/auth/user';
import SlackUser from '../../types/slack/slackUsers';
import { apolloClient } from '../apolloClient';

const mutationName = 'userCreateInBulk';
type Response = Record<typeof mutationName, User[]>;

export default async function createUsersFromSlack(
  slackUsers: SlackUser[],
): Promise<FetchResult<Response>> {
  return apolloClient.mutate<Response>({
    mutation: gql`
      mutation userCreateInBulk($input: [UserCreateInput!]!) {
        userCreateInBulk(usersCreateInput: $input) {
          id
          name
          email
          slackUserId
          profile {
            image32
            image48
          }
        }
      }
    `,
    variables: {
      input: slackUsers.map((slackUser) => ({
        name: slackUser.name,
        email: slackUser.email,
        teamId: null,
        isTeamLead: false,
        slackUserId: slackUser.id,
        profile: omit(slackUser.profile, '__typename'),
      })),
    },
  });
}
