import MuiIconButton from '@mui/material/IconButton';
import React, { ForwardedRef } from 'react';

export enum IconButtonSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

const MUI_SIZE_MAP: {
  [key in IconButtonSize]: Parameters<typeof MuiIconButton>[0]['size'];
} = {
  [IconButtonSize.Small]: 'small',
  [IconButtonSize.Medium]: 'medium',
  [IconButtonSize.Large]: 'large',
};

interface Props {
  size?: IconButtonSize;
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
}

function IconButton(
  {
    size = IconButtonSize.Medium,
    onClick,
    children,
    disabled,
    ...otherProps
  }: Props,
  ref: ForwardedRef<HTMLButtonElement>,
): React.ReactElement {
  return (
    <MuiIconButton
      ref={ref}
      {...otherProps}
      size={MUI_SIZE_MAP[size]}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </MuiIconButton>
  );
}

export default React.forwardRef(IconButton);
