import moment from 'moment';
import { useState } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './styles.module.scss';

import { ROUTES } from '../../../../router';
import Spinner from '../../../../shared/components/core-ui/Spinner';
import { useAppDispatch } from '../../../../shared/hooks/redux/useAppDispatch';
import { useAppSelector } from '../../../../shared/hooks/redux/useAppSelector';
import FrequencyConfig from '../../../../shared/types/surveys/schedulingEvents/frequencyConfig';
import PossibleAudience from '../../../../shared/types/surveys/schedulingEvents/possibleAudience';
import {
  loadPossibleAudience,
  loadSchedulingEventForSurvey,
  saveSchedulingEvent,
} from '../../redux/schedulingEvents';
import SurveySchedulingEventLayout from '../SurveySchedulingEventLayout';

export default function SurveySchedulingEvent(): React.ReactElement {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { surveyId, schedulingEventId } = useParams();
  const [selectedAudience, setSelectedAudience] = useState<PossibleAudience>({
    users: [],
    slackUsers: [],
    slackChannels: [],
  });

  const {
    possibleAudience,
    schedulingEventToEdit,
    schedulingEventLoaded,
    possibleAudienceLoaded,
  } = useAppSelector(({ schedulingEvents }) => ({
    possibleAudience: schedulingEvents.possibleAudience,
    schedulingEventToEdit: schedulingEvents.schedulingEventToEdit,
    schedulingEventLoaded: schedulingEvents.schedulingEventLoaded,
    possibleAudienceLoaded: schedulingEvents.possibleAudienceLoaded,
  }));

  const isAudienceSelected = useMemo(() => {
    const { users, slackUsers, slackChannels } = selectedAudience;

    return !!(users.length || slackUsers.length || slackChannels.length);
  }, [selectedAudience]);

  useEffect(() => {
    if (surveyId) {
      if (schedulingEventId) {
        dispatch(loadSchedulingEventForSurvey({ surveyId, schedulingEventId }));
      }
    } else {
      // TODO: Log error.
    }

    dispatch(loadPossibleAudience());
  }, []);

  const saveHandler = async ({ cronString, startingDate }: FrequencyConfig) => {
    await dispatch(
      saveSchedulingEvent({
        id: schedulingEventId,
        surveyId: surveyId as string,
        cronString,
        startingDate: startingDate as moment.Moment,
        selectedAudience,
      }),
    );

    navigate(ROUTES.home.route);
  };

  const isLoading = schedulingEventId && !schedulingEventLoaded;

  useEffect(() => {
    if (schedulingEventToEdit) {
      setSelectedAudience({
        users: schedulingEventToEdit.targetAudience.users,
        slackUsers: [],
        slackChannels: schedulingEventToEdit.targetAudience.slackChannels,
      });
    }
  }, [schedulingEventToEdit]);

  if (isLoading) {
    return (
      <div className={styles.spinnerContainer}>
        <Spinner />
      </div>
    );
  }

  return (
    <SurveySchedulingEventLayout
      onSave={saveHandler}
      possibleAudience={possibleAudience}
      saveDisabled={!isAudienceSelected}
      loadingAudience={!possibleAudienceLoaded}
      onAudienceChange={setSelectedAudience}
      selectedAudience={selectedAudience}
      schedulingEventToEdit={schedulingEventToEdit}
    />
  );
}
