import { gql, FetchResult } from '@apollo/client';

import ID from '../../types/id';
import RoundOfResponses from '../../types/surveys/roundOfResponses/roundOfResponses';
import { apolloClient } from '../apolloClient';

type ResponseType = RoundOfResponses;

const queryName = 'roundOfResponses';
type Response = Record<typeof queryName, ResponseType | null>;

interface Args {
  surveyId: ID;
}

export default function fetchRoundOfResponses({
  surveyId,
}: Args): Promise<FetchResult<Response>> {
  return apolloClient.query<Response>({
    query: gql`
      query RoundOfResponses(
        $roundOfResponsesInput: GetRoundOfResponsesInput!
      ) {
        roundOfResponses(roundOfResponsesInput: $roundOfResponsesInput) {
          surveyDispatchLogId
          questions {
            id
            name
            isAnonymous
            type
            config
            totalUsers
            totalResponses
            responses {
              numberResponse
              textResponse
              sliderSegmentName
              booleanResponse
              listResponses
              amountOfAnswers
            }
          }
        }
      }
    `,
    variables: {
      roundOfResponsesInput: {
        surveyId,
      },
    },
  });
}
