/* eslint-disable motivato/all-components-should-have-storybook-stories */
import InsertInvitation from '@mui/icons-material/InsertInvitation';
import React from 'react';

import { resolveCommonIconProps } from '../../../../helpers/icons/common';
import { CommonIconProps } from '../../../../types/iconsCommon';

function CalendarIcon(
  props: CommonIconProps,
  ref: React.ForwardedRef<SVGSVGElement>,
): React.ReactElement {
  return <InsertInvitation ref={ref} {...resolveCommonIconProps(props)} />;
}

export default React.forwardRef(CalendarIcon);
