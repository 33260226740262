import MUIFormControlLabel from '@mui/material/FormControlLabel';
import MUIFormGroup from '@mui/material/FormGroup';
import MuiSwitch from '@mui/material/Switch';
import React from 'react';

import P from '../text/P';

interface Props {
  label?: string;
  checked?: boolean;
  onClick?: () => void;
  onChange?: (newValue: boolean) => void;
}

function Toggle(
  { label, checked, onChange, ...otherProps }: Props,
  ref: React.ForwardedRef<HTMLButtonElement>,
): React.ReactElement {
  const switchElement = (
    <MuiSwitch
      {...otherProps}
      ref={ref}
      checked={checked}
      onChange={(__, newValue) => onChange?.(newValue)}
    />
  );

  if (label?.trim()) {
    return (
      <MUIFormGroup>
        <MUIFormControlLabel label={<P>{label}</P>} control={switchElement} />
      </MUIFormGroup>
    );
  }

  return switchElement;
}

export default React.forwardRef(Toggle);
