import classnames from 'classnames';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

import { ROUTES } from '../../../../router';
import Tabs, { Tab } from '../../../../shared/components/core-ui/Tabs';
import { useAppDispatch } from '../../../../shared/hooks/redux/useAppDispatch';
import { useAppSelector } from '../../../../shared/hooks/redux/useAppSelector';
import { cleanSurveyBuilder } from '../../redux/surveyBuilder';

enum BuilderTab {
  Editor,
  Preview,
}

export default function SurveyBuilderLayout(): React.ReactElement {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const isPreview = !!pathname.match(/preview\/?$/);
  const { surveyToEdit } = useAppSelector(({ surveyBuilder }) => ({
    surveyToEdit: surveyBuilder.surveyToEdit,
  }));

  useEffect(() => {
    return () => {
      dispatch(cleanSurveyBuilder());
    };
  }, []);

  return (
    <div className={styles.container}>
      <div
        className={classnames(styles.tabsContainer, {
          [styles.highlighted]: isPreview,
        })}
      >
        <Tabs
          value={isPreview ? BuilderTab.Preview : BuilderTab.Editor}
          onChangeTab={(value) => {
            if (value === BuilderTab.Preview) {
              navigate('preview');
            } else if (value === BuilderTab.Editor && surveyToEdit) {
              if (surveyToEdit.id) {
                navigate(ROUTES.surveyBuilder.getRoute(surveyToEdit.id));
              }
            }
          }}
        >
          <Tab value={BuilderTab.Editor} label="Editor" />
          <Tab value={BuilderTab.Preview} label="Preview" />
        </Tabs>
      </div>

      <Outlet />
    </div>
  );
}
