import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import styles from './styles.module.scss';

import Button, {
  ButtonVariant,
} from '../../../../shared/components/core-ui/Button';
import Select, {
  SelectOption,
  SelectSize,
} from '../../../../shared/components/core-ui/Select';
import Separator from '../../../../shared/components/core-ui/Separator';
import Spacing from '../../../../shared/components/core-ui/Spacing';
import TextField, {
  TextFieldSize,
} from '../../../../shared/components/core-ui/TextField';
import P from '../../../../shared/components/core-ui/text/P';
import { SurveyQuestionType } from '../../../../shared/enums/surveys/questionType';
import { questionConfigsForType } from '../../../../shared/mocks/surveys/question';
import SurveyQuestion from '../../../../shared/types/surveys/question';
import SurveyResponseType from '../../../../shared/types/surveys/responseTypeConfig';
import SurveyBooleanConfig from './components/SurveyBooleanConfig';
import SurveyListConfig from './components/SurveyListConfig';
import SurveySliderConfig from './components/SurveySliderConfig';
import SurveyTextConfig from './components/SurveyTextConfig';

interface Props {
  onSave: () => void;
  onCancel: () => void;
  question: SurveyQuestion;
  onQuestionChange: (question: SurveyQuestion) => void;
}

export default function QuestionConfigPanel({
  onSave,
  onCancel,
  question,
  onQuestionChange,
}: Props): React.ReactElement {
  const scrollableMainRef = useRef<HTMLDivElement>(null);

  const [isMainScrollable, setIsMainScrollable] = useState(false);

  useEffect(() => {
    // In this useEffect we'll determine if <main /> is scrollable, so we can
    // display a shadow to help the user understand it faster.
    // TODO: We should hide the shadow when the user reaches the end of the scroll.
    //       Also, it may be a good idea to isolate this logic into a core-ui
    //       component as it grows in complexity.

    const updateMainScrollableState = () => {
      let isScrollable = isMainScrollable;
      const scrollHeight = scrollableMainRef?.current?.scrollHeight;
      const clientHeight = scrollableMainRef?.current?.clientHeight;

      if (scrollHeight && clientHeight) {
        isScrollable = scrollHeight > clientHeight;
      } else {
        isScrollable = false;
      }

      setIsMainScrollable(isScrollable);
    };

    updateMainScrollableState();
    window.addEventListener('resize', updateMainScrollableState);

    return () => {
      window.removeEventListener('resize', updateMainScrollableState);
    };
  }, []);

  const configChangeHandler = (config: SurveyResponseType) => {
    onQuestionChange({
      ...question,
      question: config,
    });
  };

  return (
    <div className={styles.container}>
      <main ref={scrollableMainRef} className={styles.content}>
        <P>Content</P>
        <Separator spacing={2} />

        <TextField
          size={TextFieldSize.small}
          label="Question"
          value={question.name || ''}
          onChangeText={(text) =>
            onQuestionChange({
              ...question,
              name: text,
            })
          }
        />

        <Separator spacing={2} />

        <Select
          size={SelectSize.small}
          label="Question type"
          value={question.question.type}
          onChange={(newConfigType) =>
            configChangeHandler(questionConfigsForType[newConfigType])
          }
        >
          {Object.values(SurveyQuestionType).map((type) => (
            <SelectOption
              key={type}
              value={type}
              disabled={!questionConfigsForType[type]}
            >
              {type}
            </SelectOption>
          ))}
        </Select>

        {question.question.type === SurveyQuestionType.Boolean && (
          <>
            <Separator spacing={2} />
            <SurveyBooleanConfig
              questionConfig={question.question}
              onConfigChange={(config) => configChangeHandler(config)}
            />
          </>
        )}

        {question.question.type === SurveyQuestionType.Text && (
          <>
            <Separator spacing={2} />
            <SurveyTextConfig
              questionConfig={question.question}
              onConfigChange={(config) => configChangeHandler(config)}
            />
          </>
        )}

        {question.question.type === SurveyQuestionType.List && (
          <>
            <Separator spacing={2} />
            <SurveyListConfig
              questionConfig={question.question}
              onConfigChange={(config) => configChangeHandler(config)}
            />
          </>
        )}

        {question.question.type === SurveyQuestionType.Slider && (
          <>
            <Separator spacing={2} />
            <SurveySliderConfig
              questionConfig={question.question}
              onConfigChange={(config) => configChangeHandler(config)}
            />
          </>
        )}
      </main>

      <footer
        className={classnames(styles.footer, {
          [styles.footerForScrollableView]: isMainScrollable,
        })}
      >
        <Button flex onClick={onCancel}>
          Cancel
        </Button>

        <Spacing horizontal={2} />

        <Button flex variant={ButtonVariant.primary} onClick={() => onSave()}>
          Save
        </Button>
      </footer>
    </div>
  );
}
