import classnames from 'classnames';

import styles from './styles.module.scss';

import ThreeDottedMenu from '../../../../../../shared/components/core-ui/ThreeDottedMenu';
import P from '../../../../../../shared/components/core-ui/text/P';
import SurveyQuestion from '../../../../../../shared/types/surveys/question';
import SurveyIconContainer from '../SurveyIconContainer';

interface Props {
  question: Omit<SurveyQuestion, 'followUps'>;
  onClick?: (question: SurveyQuestion) => void;
  onDelete?: (question: SurveyQuestion) => void;
  isSelected?: boolean;
}

export default function QuestionRow({
  onClick,
  onDelete,
  question,
  isSelected,
}: Props): React.ReactElement {
  return (
    <div
      onClick={() => onClick?.(question)}
      className={classnames(styles.container, {
        [styles.selected]: isSelected,
      })}
    >
      <SurveyIconContainer questionType={question.question.type} />

      <div className={styles.nameContainer}>
        <P>{question.name}</P>
      </div>

      <div className={styles.menuContainer}>
        <ThreeDottedMenu
          options={[]}
          onDelete={() => onDelete?.(question)}
          onOptionSelected={() => {}}
        />
      </div>
    </div>
  );
}
