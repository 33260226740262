import { FetchResult, gql } from '@apollo/client';

import Survey from '../../types/surveys/survey';
import { GenericResponseType, apolloClient } from '../apolloClient';

interface ResponseType extends GenericResponseType {
  survey: Survey;
}

const mutationName = 'signUp';
type Response = Record<typeof mutationName, ResponseType>;

export default async function signUpMutation({
  code,
  state,
}: {
  code: string;
  state: string;
}): Promise<FetchResult<Response>> {
  return apolloClient.mutate<Response>({
    mutation: gql`
      mutation SignUp($signUpInput: SignUpInput!) {
        signUp(signUpInput: $signUpInput) {
          ok
        }
      }
    `,
    variables: {
      signUpInput: {
        code,
        state,
      },
    },
  });
}
