import truncate from 'lodash/truncate';
import moment from 'moment';

import styles from './styles.module.scss';

import ThreeDottedMenu from '../../../../shared/components/core-ui/ThreeDottedMenu';
import ArrowRightIcon from '../../../../shared/components/core-ui/icons/ArrowRightIcon';
import CalendarIcon from '../../../../shared/components/core-ui/icons/CalendarIcon';
import QuestionIcon from '../../../../shared/components/core-ui/icons/QuestionIcon';
import TimeIcon from '../../../../shared/components/core-ui/icons/TimeIcon';
import P from '../../../../shared/components/core-ui/text/P';
import Title from '../../../../shared/components/core-ui/text/Title';
import { IconSize } from '../../../../shared/enums/icons';
import Survey from '../../../../shared/types/surveys/survey';
import SurveyDispatchLog from '../../../../shared/types/surveys/surveyDispatchLog';
import SurveyCardInfoArticle from './components/SurveyCardInfoArticle';

interface Props {
  survey: Survey;
  onEdit?: (survey: Survey) => void;
  surveyDispatchLog?: SurveyDispatchLog;
  onViewLatestResponses: () => void;
}

type MenuOption = {
  value: string;
  text: string;
};

const MENU_OPTIONS: Record<string, MenuOption> = {
  edit: {
    value: 'EDIT',
    text: 'Edit',
  },
  delete: {
    value: 'DELETE',
    text: 'Delete',
  },
};

export default function SurveyCard({
  survey,
  onEdit,
  surveyDispatchLog,
  onViewLatestResponses,
}: Props): React.ReactElement {
  const amountOfQuestions = survey.questions?.length || 0;

  const menuOptionSelectedHandler = (option: MenuOption) => {
    if (option.value === MENU_OPTIONS.edit.value) {
      onEdit?.(survey);
    }
  };

  const surveyInfoContainer = (
    <div className={styles.surveyInfoContainer}>
      <SurveyCardInfoArticle
        icon={<QuestionIcon size={IconSize.small} />}
        label="Questions"
        textFirstLine={amountOfQuestions.toString()}
        textSecondLine={amountOfQuestions === 1 ? 'Question' : 'Questions'}
      />

      <div className={styles.surveyInfoDivider} />

      <SurveyCardInfoArticle
        icon={<TimeIcon size={IconSize.small} />}
        label="To complete"
        textFirstLine={`${amountOfQuestions * 3}`}
        textSecondLine="Minutes"
      />

      {surveyDispatchLog && (
        <>
          <div className={styles.surveyInfoDivider} />

          <SurveyCardInfoArticle
            icon={<CalendarIcon size={IconSize.small} />}
            label="Last sent"
            textFirstLine={moment(surveyDispatchLog.sentAt).format('MMM DD,')}
            textSecondLine={moment(surveyDispatchLog.sentAt).format('YYYY')}
          />
        </>
      )}
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles.firstLevel}>
        <header className={styles.header}>
          <div className={styles.titleContainer}>
            <Title>{survey.name}</Title>

            <ThreeDottedMenu
              options={Object.values(MENU_OPTIONS)}
              getOptionText={(option) => option.text}
              onOptionSelected={menuOptionSelectedHandler}
            />
          </div>

          {survey.description && (
            <P>
              {truncate(survey.description, {
                length: 82,
              })}
            </P>
          )}
        </header>

        <div className={styles.surveyInfoDesktop}>{surveyInfoContainer}</div>

        <div
          className={styles.actionabilityContainer}
          role="button"
          onClick={onViewLatestResponses}
        >
          {surveyDispatchLog && (
            <div className={styles.amountOfPeopleWhoAnsweredContainer}>
              <Title>{surveyDispatchLog.amountOfUsersWhoAnsweredIt}</Title>
              <P> / {surveyDispatchLog.amountOfUsersWhoReceivedIt}</P>
            </div>
          )}

          <P>View Latest Responses</P>

          <ArrowRightIcon />
        </div>
      </div>

      <div className={styles.surveyInfoMobile}>{surveyInfoContainer}</div>
    </div>
  );
}
