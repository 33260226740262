import { FetchResult, gql } from '@apollo/client';

import ID from '../../types/id';
import SchedulingEvent from '../../types/surveys/schedulingEvents/schedulingEvent';
import { SchedulingEventTargetAudience } from '../../types/surveys/schedulingEvents/schedulingEvent';
import { GenericResponseType, apolloClient } from '../apolloClient';
import { transformSchedulingEventTypeToAudience } from './dataTransformers/audience';

interface ResponseType extends GenericResponseType {
  schedulingEvents: SchedulingEvent[];
}

const mutationName = 'survey';
type Response = Record<typeof mutationName, ResponseType>;

export default async function fetchSchedulingEventsForSurvey({
  surveyId,
}: {
  surveyId: ID;
}): Promise<FetchResult<Response>> {
  const result = await apolloClient.query<Response>({
    query: gql`
      query schedulingEvents($surveyId: ID!) {
        survey(id: $surveyId) {
          schedulingEvents {
            frequency
            timeZone
            id
            startAt
            targetAudience {
              id
              user {
                id
                name
              }
              team {
                id
                name
              }
              slackChannel {
                id
                name
              }
            }
          }
        }
      }
    `,
    variables: {
      surveyId,
    },
  });

  return {
    ...result,
    data: {
      ...(result.data || {}),
      survey: {
        ...(result.data?.survey || {}),
        schedulingEvents: result.data?.survey?.schedulingEvents?.map(
          (schedulingEvent) => ({
            ...schedulingEvent,
            targetAudience: transformSchedulingEventTypeToAudience(
              (schedulingEvent.targetAudience as unknown as SchedulingEventTargetAudience[]) ||
                [],
            ),
          }),
        ),
      },
    },
  };
}
