/* eslint-disable motivato/all-components-should-have-storybook-stories */
import Search from '@mui/icons-material/Search';
import React from 'react';

import { resolveCommonIconProps } from '../../../../helpers/icons/common';
import { CommonIconProps } from '../../../../types/iconsCommon';

function SearchIcon(
  props: CommonIconProps,
  ref: React.ForwardedRef<SVGSVGElement>,
): React.ReactElement {
  return <Search ref={ref} {...resolveCommonIconProps(props)} />;
}

export default React.forwardRef(SearchIcon);
