import { FetchResult, gql } from '@apollo/client';

import getQuestionChangesFromLog from '../../helpers/surveys/questions/getQuestionChangesFromLog';
import getSortedQuestions from '../../helpers/surveys/questions/getSortedQuestions';
import ID from '../../types/id';
import Survey from '../../types/surveys/survey';
import { apolloClient, GenericResponseType } from '../apolloClient';
import transformSurveyQuestionsData from './dataTransformers/surveyQuestions';
import transformSurveysData from './dataTransformers/surveys';

interface ResponseType extends GenericResponseType {
  survey: Survey;
}

const mutationName = 'SurveyUpdate';
type Response = Record<typeof mutationName, ResponseType | null>;

interface Args {
  survey: Partial<Survey> & { id: ID };
  questionsChangeLog: {
    created: Record<ID, boolean>;
    updated: Record<ID, boolean>;
    deleted: Record<ID, boolean>;
  };
}

export default function updateSurvey({
  survey,
  questionsChangeLog,
}: Args): Promise<FetchResult<Response>> {
  if (!survey.id) {
    // TODO: Log error and do not send request.
  }

  const {
    created: createLog,
    updated: updateLog,
    deleted: deleteLog,
  } = getQuestionChangesFromLog(
    { ...survey, questions: getSortedQuestions(survey.questions) } as Survey,
    questionsChangeLog,
  );

  const transformedSurvey = transformSurveysData({
    ...survey,
    questions: [],
  } as Survey);

  delete transformedSurvey.questions;
  delete transformedSurvey.templateId;

  const updateSurveyInput = {
    ...transformedSurvey,
    createQuestions: transformSurveyQuestionsData(createLog).map((question) => {
      const newQuestion = { ...question };
      delete newQuestion.id;

      return newQuestion;
    }),
    updateQuestions: transformSurveyQuestionsData(updateLog),
    deleteQuestions: deleteLog,
  };

  return apolloClient.mutate<Response>({
    mutation: gql`
      mutation SurveyUpdate($updateSurveyInput: UpdateSurveyInput!) {
        surveyUpdate(updateSurveyInput: $updateSurveyInput) {
          id
          questions {
            id
            name
            question
          }
        }
      }
    `,
    variables: {
      updateSurveyInput,
    },
  });
}
