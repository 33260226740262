import styles from './styles.module.scss';

import Highlight from '../core-ui/text/Highlight';

export enum SlackItemType {
  user,
  publicChannel,
  privateChannel,
}

interface Props {
  type: SlackItemType;
}

const ELM_PER_ITEM_TYPE = {
  [SlackItemType.user]: <Highlight bold>@</Highlight>,
  [SlackItemType.publicChannel]: <Highlight bold>#</Highlight>,
  [SlackItemType.privateChannel]: <Highlight bold>x</Highlight>,
};

export default function SlackItemIcon({ type }: Props): React.ReactElement {
  return <div className={styles.container}>{ELM_PER_ITEM_TYPE[type]}</div>;
}
