/* eslint-disable motivato/all-components-should-have-storybook-stories */
import {
  createAsyncThunk,
  createReducer,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';

import NotAuthorizedException from '../../../../shared/exceptions/auth/login/notAuthorizedException';
import getSession from '../../../../shared/helpers/auth/getSession';
import setSession from '../../../../shared/helpers/auth/setSession';
import login from '../../../../shared/networking/auth/login';
import signUpService from '../../../../shared/networking/auth/signUp';
import signUpMutation from '../../../../shared/networking/auth/signUpMutation';
import Session from '../../../../shared/types/auth/session';

interface SharedSessionPayload {
  session: Session | null;
}

interface UserData {
  username: string;
  password: string;
}

export interface SignUpActionPayload {
  session: Session | null;
}

interface SignUpData {
  username: string;
  password: string;
  code: string;
  state: string;
}

export interface SignUpFailed {
  errorMessage: string;
}

export const signUp = createAsyncThunk<
  SignUpActionPayload,
  SignUpData,
  {
    rejectValue: SignUpFailed;
  }
>(
  'auth/SIGN_UP',
  async (
    { username, password, code, state }: SignUpData,
    { rejectWithValue },
  ) => {
    try {
      await signUpService(username, password);
    } catch (error) {
      return rejectWithValue({ errorMessage: 'Internal server error' });
    }

    let session = null;

    try {
      session = (await login(username, password)) || null;
    } catch (error) {
      if (error instanceof NotAuthorizedException) {
        return rejectWithValue({ errorMessage: 'Invalid credentials' });
      }

      return rejectWithValue({ errorMessage: 'Internal server error' });
    }

    if (session) {
      setSession(session);
    }

    try {
      await signUpMutation({ code, state });
    } catch (error) {
      return rejectWithValue({
        errorMessage: (error as { message: string }).message,
      });
    }

    if (session) {
      await Auth.signOut();
      session = (await login(username, password)) || null;

      setSession(session);
    }

    return {
      session,
    };
  },
);

export const authUser = createAsyncThunk<
  SharedSessionPayload,
  UserData,
  {
    rejectValue: SignUpFailed;
  }
>('auth/AUTH_USER', async ({ username, password }, { rejectWithValue }) => {
  let session = null;

  try {
    session = (await login(username, password)) || null;
  } catch (error) {
    if (error instanceof NotAuthorizedException) {
      return rejectWithValue({ errorMessage: 'Invalid credentials' });
    }

    return rejectWithValue({ errorMessage: 'Internal server error' });
  }

  if (session) {
    setSession(session);
  }

  return {
    session,
  };
});

export const loadUserSession = createAsyncThunk(
  'auth/LOAD_USER_SESSION',
  async (): Promise<SharedSessionPayload> => {
    const session = getSession();

    return {
      session,
    };
  },
);

export const logoutUser = createAsyncThunk('auth/LOGOUT_USER', async () => {
  await Auth.signOut();
  await setSession(null);

  setTimeout(() => location.reload(), 0);
});

interface State {
  session: Session | null;
  wasSessionLoaded: boolean;
  loading: boolean;
  error: string | null | SerializedError;
}

const initialState = {
  session: null,
  wasSessionLoaded: false,
  loading: false,
  signUpSuccess: false,
  error: null,
};

export default createReducer<State>(initialState, (builder) => {
  builder
    .addCase(signUp.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(signUp.fulfilled, (state, action) => {
      state.loading = false;
      state.session = action.payload.session;
    })
    .addCase(signUp.rejected, (state, action) => {
      state.loading = false;

      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error;
      }
    });

  builder.addCase(logoutUser.fulfilled, (state) => {
    state.session = null;
  });

  const loadSession = (
    state: State,
    action: PayloadAction<SharedSessionPayload>,
  ) => {
    state.session = action.payload.session;
    state.wasSessionLoaded = true;
  };

  builder
    .addCase(authUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(authUser.fulfilled, (state, action) => {
      state.loading = false;
      state.session = action.payload.session;
    })
    .addCase(authUser.rejected, (state, action) => {
      state.loading = false;

      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error;
      }
    });

  builder.addCase(loadUserSession.fulfilled, loadSession);
});
