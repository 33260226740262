import { gql, FetchResult } from '@apollo/client';

import User from '../../types/auth/user';
import { apolloClient } from '../apolloClient';

type ResponseType = User[];

const queryName = 'users';
type Response = Record<typeof queryName, ResponseType | null>;

interface Args {
  limit?: number;
}

export default function fetchTeammates(
  args?: Args,
): Promise<FetchResult<Response>> {
  return apolloClient
    .query<Response>({
      query: gql`
        query users {
          users {
            name
            email
            id
            profile {
              imageOriginal
            }
          }
        }
      `,
    })
    .then((usersData) => {
      if (usersData.data?.users?.length && args?.limit) {
        return {
          ...usersData,
          data: {
            users: usersData.data.users?.slice(0, args?.limit),
          },
        };
      }

      return usersData;
    });
}
