import classnames from 'classnames';
import React, { useState, useMemo } from 'react';

import styles from './styles.module.scss';

import GlobalSearchItemType from '../../enums/globalSearch/globalSearchItemType';
import { IconSize } from '../../enums/icons';
import isItMacOs from '../../helpers/agent/isItMacOs';
import ID from '../../types/id';
import Autocomplete from '../core-ui/Autocomplete';
import IconButton, { IconButtonSize } from '../core-ui/IconButton';
import CloseIcon from '../core-ui/icons/CloseIcon';
import SearchIcon from '../core-ui/icons/SearchIcon';

const ITEM_TYPES = {
  [GlobalSearchItemType.User]: 'Users',
  [GlobalSearchItemType.Survey]: 'Surveys',
  [GlobalSearchItemType.Template]: 'Templates',
};

export interface GlobalSearchItem {
  type: GlobalSearchItemType;
  item: { id?: ID; name: string };
}

interface Props {
  onBlur: () => void;
  onFind: (value: GlobalSearchItem | null) => void;
  onFocus: () => void;
  options: GlobalSearchItem[];
  loading?: boolean;
  onSearchTermsChange: (terms: string) => void;
}

function GlobalSearch(
  { onBlur, onFind, onFocus, options, loading, onSearchTermsChange }: Props,
  ref: React.ForwardedRef<HTMLInputElement>,
): React.ReactElement {
  const isMacOs = useMemo(() => isItMacOs(), []);
  const [isOnFocus, setIsOnFocus] = useState(false);
  const [value, setValue] = useState<{
    text: string;
    item: GlobalSearchItem | null;
  }>({ text: '', item: null });

  const resetText = () => {
    setValue({ text: '', item: null });
  };

  const blurHandler = () => {
    onBlur();
    resetText();
    setIsOnFocus(false);
  };

  const changeHandler = (newValue: GlobalSearchItem | null) => {
    resetText();
    onFind(newValue);
  };

  const focusHandler = () => {
    onFocus();
    setIsOnFocus(true);
  };

  return (
    <Autocomplete<GlobalSearchItem>
      ref={ref}
      label="Search"
      value={value.item}
      onBlur={blurHandler}
      loading={loading}
      options={options}
      onFocus={focusHandler}
      groupBy={(user) => ITEM_TYPES[user.type]}
      getOptionLabel={(option) => option.item.name || ''}
      onChange={(__, newValue) =>
        changeHandler(newValue as GlobalSearchItem | null)
      }
      renderInput={(inputProps) => (
        <div
          ref={inputProps.InputProps.ref}
          className={classnames(styles.inputContainer, {
            [styles.onFocus]: isOnFocus,
          })}
        >
          <SearchIcon />

          <input
            {...inputProps.inputProps}
            value={value.text}
            className={styles.input}
            placeholder="Search for People, Surveys or Templates"
            onChange={(event) => {
              setValue({ text: event.target.value, item: value.item });
              onSearchTermsChange(event.target.value);
              inputProps.inputProps.onChange?.(event);
            }}
          />

          <div className={styles.shortcutWrapper}>
            <div
              className={classnames(styles.shortcutContainer, {
                [styles.macOsShortcut]: isMacOs,
                [styles.hiddenShortcut]: isOnFocus,
              })}
            >
              {isMacOs ? '⌘K' : 'Ctrl + K'}
            </div>
          </div>

          <div
            className={classnames(styles.closeContainer, {
              [styles.visibleCloseButton]: !!value.text,
            })}
          >
            <IconButton size={IconButtonSize.Large} onClick={resetText}>
              <CloseIcon size={IconSize.small} />
            </IconButton>
          </div>
        </div>
      )}
    />
  );
}

export default React.forwardRef(GlobalSearch);
