import React from 'react';

import styleVariables from '../../styles/_exports.module.scss';

import { IconColor, IconSize } from '../../enums/icons';
import { CommonIconProps } from '../../types/iconsCommon';

type MUISizes = 'small' | 'medium' | 'large';

type MUIColors =
  | 'inherit'
  | 'action'
  | 'disabled'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning';

const ICON_SIZES: { [key in keyof typeof IconSize]: MUISizes } = {
  [IconSize.small]: 'small',
  [IconSize.medium]: 'medium',
  [IconSize.large]: 'large',
};

const ICON_COLOR_MAP_TO_MUI_LIB: {
  [key in keyof typeof IconColor]: MUIColors;
} = {
  [IconColor.default]: styleVariables.defaultIconColor as MUIColors,
  [IconColor.disabled]: 'disabled',
  [IconColor.primary]: 'primary',
  [IconColor.secondary]: 'secondary',
  [IconColor.error]: 'error',
};

interface ResolvedIconsProps {
  color: MUIColors;
  style?: React.CSSProperties;
  fontSize: MUISizes;
}

export function resolveCommonIconProps({
  size = IconSize.small,
  color = IconColor.default,
}: CommonIconProps): ResolvedIconsProps {
  const resolvedProps: ResolvedIconsProps = {
    color: ICON_COLOR_MAP_TO_MUI_LIB[color],
    fontSize: ICON_SIZES[size],
  };

  if (color === IconColor.default) {
    resolvedProps.style = { color: styleVariables.defaultIconColor };
  }

  return resolvedProps;
}
