import React from 'react';

import styles from './styles.module.scss';

import SurveyTemplateCard from '../../../../shared/components/SurveyTemplateCard';
import Button, {
  ButtonVariant,
} from '../../../../shared/components/core-ui/Button';
import Spacing from '../../../../shared/components/core-ui/Spacing';
import Title from '../../../../shared/components/core-ui/text/Title';
import DetailedReportChart from '../../../../shared/components/reporting/DetailedReportChart';
import { sliderSurveyQuestion } from '../../../../shared/mocks/surveys/question';
import SurveyQuestionReport from '../../../../shared/types/surveys/reporting/reporting';
import Survey from '../../../../shared/types/surveys/survey';
import SurveyTemplate from '../../../../shared/types/surveys/template';
import SurveyCard from '../SurveyCard';

interface Props {
  stats: SurveyQuestionReport[];
  surveys: Survey[];
  onSurveyEdit: (survey: Survey) => void;
  surveyTemplates: SurveyTemplate[];
  onTemplateClick: (template: SurveyTemplate) => void;
  onViewLatestResponses: (survey: Survey) => void;
  onSeeAllTemplatesClick: () => void;
}

export default function HomeLayout({
  stats,
  surveys,
  onSurveyEdit,
  surveyTemplates,
  onTemplateClick,
  onViewLatestResponses,
  onSeeAllTemplatesClick,
}: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      <main>
        {!!stats.length && (
          <>
            <div className={styles.homeStatsTitleContainer}>
              <Title>.</Title>
            </div>

            <DetailedReportChart
              name="Roadmap visibility"
              report={stats}
              question={sliderSurveyQuestion}
              description="You've hit a sweet spot! Both short-term and long-term visibility are high. Motivation, code quality and timelines are likely not at risk from roadmap visibility."
            />

            <Spacing vertical={2} />
          </>
        )}

        <Title>Surveys</Title>

        <div className={styles.surveyContainer}>
          {surveys.map((survey) => (
            <React.Fragment key={survey.id}>
              <SurveyCard
                onEdit={() => onSurveyEdit(survey)}
                survey={survey}
                onViewLatestResponses={() => onViewLatestResponses(survey)}
              />
              <Spacing vertical={2} />
            </React.Fragment>
          ))}
        </div>
      </main>

      <aside>
        <div className={styles.asideHeader}>
          <Title>Templates</Title>

          <Button
            onClick={onSeeAllTemplatesClick}
            variant={ButtonVariant.tertiary}
          >
            See all
          </Button>
        </div>

        {surveyTemplates.map((surveyTemplate) => (
          <React.Fragment key={surveyTemplate.id}>
            <SurveyTemplateCard
              surveyTemplate={surveyTemplate}
              onClick={() => onTemplateClick(surveyTemplate)}
            />

            <Spacing vertical={2} />
          </React.Fragment>
        ))}
      </aside>
    </div>
  );
}
