import {
  createAction,
  createAsyncThunk,
  createReducer,
} from '@reduxjs/toolkit';

import resolveAnimationsForLiveSurvey from '../../../../shared/helpers/liveSurvey/resolveAnimationsForLiveSurvey';
import fetchLiveSurvey from '../../../../shared/networking/surveys/fetchLiveSurvey';
import ID from '../../../../shared/types/id';
import LiveSurvey, {
  LiveSurveyQuestion,
} from '../../../../shared/types/surveys/liveSurvey';

interface LoadLiveSurveyReturn {
  liveSurvey: LiveSurvey | null;
}

const loadLiveSurveyData = createAction<LoadLiveSurveyReturn>(
  'liveSurvey/LOAD_LIVE_SURVEY_DATA',
);

const changeQuestions = createAction<LiveSurveyQuestion[]>(
  'liveSurvey/CHANGE_QUESTIONS',
);

export const loadLiveSurvey = createAsyncThunk<void, { id: ID }>(
  'liveSurvey/LOAD_LIVE_SURVEY',
  async ({ id }, { dispatch }) => {
    const result = await fetchLiveSurvey({ surveyCompletionId: id });
    const liveSurvey = result.data?.liveSurvey || null;

    dispatch(
      loadLiveSurveyData({
        liveSurvey,
      }),
    );

    if (liveSurvey) {
      resolveAnimationsForLiveSurvey(
        liveSurvey,
        (changedQuestion: LiveSurveyQuestion[]) => {
          dispatch(changeQuestions(changedQuestion));
        },
      );
    }
  },
);

interface State {
  activeLiveSurvey: LiveSurvey | null;
}

export const initialState: State = {
  activeLiveSurvey: null,
};

export default createReducer<State>(initialState, (builder) => {
  builder.addCase(loadLiveSurveyData, (state, action) => {
    state.activeLiveSurvey = action.payload.liveSurvey;
  });

  builder.addCase(changeQuestions, (state, action) => {
    if (state.activeLiveSurvey) {
      state.activeLiveSurvey.questions = state.activeLiveSurvey?.questions.map(
        (question) => {
          const questionToReplace = action.payload.find(
            (q) => q.id === question.id,
          );

          return questionToReplace || question;
        },
      );
    }
  });
});
