import { Auth } from 'aws-amplify';

import {
  COGNITO_ATTRIBUTE_CUSTOMER_ID,
  COGNITO_ATTRIBUTE_EMAIL,
  COGNITO_ATTRIBUTE_EMAIL_VERIFIED,
  COGNITO_ATTRIBUTE_GROUPS,
  COGNITO_ATTRIBUTE_ID,
} from '../../constants/auth/cognitoAttributes';
import AuthChallenge from '../../enums/auth/authChallenge';
import signInErrorExceptions from '../../errorHandling/auth/login';
import Session from '../../types/auth/session';

export default async function login(
  username: string,
  password: string,
): Promise<Session | undefined> {
  try {
    const {
      challengeName,
      attributes,
      signInUserSession: {
        idToken: { jwtToken: idToken, payload },
        accessToken: { jwtToken: accessToken },
        refreshToken: { jwtToken: refreshToken },
      },
    } = await Auth.signIn(username, password);

    if (challengeName === AuthChallenge.NewPasswordRequired) {
      // TODO: handle new password required challenge
    }

    return {
      attributes: {
        email: attributes[COGNITO_ATTRIBUTE_EMAIL],
        id: attributes[COGNITO_ATTRIBUTE_ID],
        emailVerified: attributes[COGNITO_ATTRIBUTE_EMAIL_VERIFIED],
        customerId: attributes[COGNITO_ATTRIBUTE_CUSTOMER_ID],
        roles: payload[COGNITO_ATTRIBUTE_GROUPS],
      },
      idToken,
      accessToken,
      refreshToken,
    };
  } catch (error) {
    throw signInErrorExceptions[
      (error as { code: keyof typeof signInErrorExceptions }).code
    ];
  }
}
