import { GlobalSearchItem } from '../../../../components/GlobalSearch';
import GlobalSearchItemType from '../../../../enums/globalSearch/globalSearchItemType';
import { NetworkingGlobalSearchResults } from '../../searchGlobally';

export function transformGlobalSearchTerms(
  networkingSearchResult?: NetworkingGlobalSearchResults | null,
): GlobalSearchItem[] {
  const results: GlobalSearchItem[] = [];

  networkingSearchResult?.users?.forEach((user) => {
    results.push({
      type: GlobalSearchItemType.User,
      item: user,
    });
  });

  networkingSearchResult?.surveys?.forEach((survey) => {
    results.push({
      type: GlobalSearchItemType.Survey,
      item: survey,
    });
  });

  networkingSearchResult?.templates?.forEach((template) => {
    results.push({
      type: GlobalSearchItemType.Template,
      item: template,
    });
  });

  return results;
}
