import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Title from '../../../../shared/components/core-ui/text/Title';
import { useAppDispatch } from '../../../../shared/hooks/redux/useAppDispatch';
import { useAppSelector } from '../../../../shared/hooks/redux/useAppSelector';
import LiveSurveyController from '../../../LiveSurvey/components/LiveSurveyController';
import { loadSurveyToEdit } from '../../redux/surveyBuilder';

export default function SurveyBuilderPreview(): React.ReactElement {
  const { surveyId } = useParams();
  const dispatch = useAppDispatch();

  const { surveyToEdit } = useAppSelector(({ surveyBuilder }) => ({
    surveyToEdit: surveyBuilder.surveyToEdit,
    selectedQuestion: surveyBuilder.selectedQuestion,
  }));

  useEffect(() => {
    if (surveyId && !surveyToEdit) {
      dispatch(loadSurveyToEdit({ id: surveyId }));
    }
  }, []);

  return (
    <>
      {surveyToEdit && <LiveSurveyController survey={surveyToEdit} />}
      {!surveyToEdit && <Title>No survey</Title>}
    </>
  );
}
