import classnames from 'classnames';

import styles from './styles.module.scss';

import StatSummaryCard from '../../../../shared/components/StatSummaryCard';
import MainButton, {
  MainButtonVariant,
} from '../../../../shared/components/core-ui/MainButton';
import Spacing from '../../../../shared/components/core-ui/Spacing';
import LineChart from '../../../../shared/components/core-ui/charts/LineChart';

export enum TeammateStatWithTipVariation {
  TimePressure,
  RoadmapVisibility,
}

interface Props {
  variation: TeammateStatWithTipVariation;
}

export default function TeammateStatWithTip({
  variation,
}: Props): React.ReactElement {
  return (
    <div
      className={classnames(styles.container, {
        [styles.roadmapVisibility]:
          variation === TeammateStatWithTipVariation.RoadmapVisibility,
        [styles.timePressure]:
          variation === TeammateStatWithTipVariation.TimePressure,
      })}
    >
      <StatSummaryCard
        mainNumber={80}
        description={`Ada is higly confident working on the codebase and completely
        satisfied with the amount of time for tackling tech debt. However,
        they're satisfied with testing and code review thoroughness. This
        means the likelihood of future bugs is medium.`}
      >
        <div className={styles.chartContainer}>
          <LineChart
            lines={[
              [
                { y: 1, z1: 3, name: 'Fugiat' },
                { y: 5, z1: 2, name: 'Nostrud' },
                { y: 3, z1: 3, name: 'Deserunt' },
              ],
            ]}
          />
        </div>
      </StatSummaryCard>

      <div className={styles.sectionInBetweenCards}>
        <div className={styles.sectionConnector}>
          <svg
            width="123"
            height="58"
            viewBox="0 0 123 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 58H39V50C39 43.3726 33.6274 38 27 38H0V58Z"
              className={styles.roadmapSeparatorDarkColor}
            />
            <path
              d="M123 0H88V58H123V0Z"
              className={styles.roadmapSeparatorLightColor}
            />
            <path
              d="M88 0H54V23C54 29.6274 59.3726 35 66 35H88V0Z"
              className={styles.roadmapSeparatorDarkColor}
            />
          </svg>
        </div>
      </div>

      <StatSummaryCard
        className={styles.tipCard}
        description={`Clarify both the short-term and long-term roadmaps. Giving everyone
        a single place to access this info will keep everyone on the same
        page, so share it before your next team meeting or 1:1 check-in!
        That will give everyone time to digest and prepare any questions
        ahead of time.`}
      >
        <div className={styles.buttonContainer}>
          <MainButton variant={MainButtonVariant.primary} onClick={() => {}}>
            Schedule survey
          </MainButton>

          <Spacing total={1} />

          <MainButton variant={MainButtonVariant.secondary} onClick={() => {}}>
            Schedule survey
          </MainButton>
        </div>
      </StatSummaryCard>
    </div>
  );
}
