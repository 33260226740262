import { FetchResult } from '@apollo/client';
import moment from 'moment';

import generateMockId from '../../helpers/mocks/generateMockId';
import SurveyQuestionReport from '../../types/surveys/reporting/reporting';

type Response = Record<'surveyQuestionsResults', SurveyQuestionReport[]>;

export default async function fetchHomeStats(): Promise<FetchResult<Response>> {
  return Promise.resolve({
    data: {
      surveyQuestionsResults: [
        {
          questionId: generateMockId(),
          result: [
            {
              value: 3,
              endDate: moment().toISOString(),
              startDate: moment().toISOString(),
            },
            {
              value: 4,
              endDate: moment().toISOString(),
              startDate: moment().toISOString(),
            },
            {
              value: 3.5,
              endDate: moment().toISOString(),
              startDate: moment().toISOString(),
            },
            {
              value: 3.8,
              endDate: moment().toISOString(),
              startDate: moment().toISOString(),
            },
            {
              value: 3,
              endDate: moment().toISOString(),
              startDate: moment().toISOString(),
            },
            {
              value: 2.8,
              endDate: moment().toISOString(),
              startDate: moment().toISOString(),
            },
            {
              value: 2.9,
              endDate: moment().toISOString(),
              startDate: moment().toISOString(),
            },
            {
              value: 3.5,
              endDate: moment().toISOString(),
              startDate: moment().toISOString(),
            },
            {
              value: 4.2,
              endDate: moment().toISOString(),
              startDate: moment().toISOString(),
            },
            {
              value: 3,
              endDate: moment().toISOString(),
              startDate: moment().toISOString(),
            },
            {
              value: 4.5,
              endDate: moment().toISOString(),
              startDate: moment().toISOString(),
            },
          ],
        },
      ],
    },
  });
}
