import ID from '../../../../types/id';
import SurveyQuestion from '../../../../types/surveys/question';
import Survey from '../../../../types/surveys/survey';
import getQuestionFromSurvey from '../getQuestionFromSurvey';
import normalizeQuestionLog from '../normalizeQuestionLog';

type ReturnType = {
  created: SurveyQuestion[];
  updated: SurveyQuestion[];
  deleted: ID[];
};

export default function getQuestionChangesFromLog(
  survey: Survey,
  changeLog: {
    created: Record<ID, boolean>;
    updated: Record<ID, boolean>;
    deleted: Record<ID, boolean>;
  },
): ReturnType {
  const {
    created: createdNormalizedLog,
    updated: updatedNormalizedLog,
    deleted: deletedNormalizedLog,
  } = normalizeQuestionLog(changeLog);

  const replaceIdsWithQuestions = (ids: ID[]): SurveyQuestion[] => {
    const questions: SurveyQuestion[] = [];

    ids.forEach((id) => {
      const question = getQuestionFromSurvey(survey, id);

      if (question) {
        questions.push({ ...question, followUps: [] });
      } else {
        // TODO: Log error, this question should not be here and will not be changed.
      }

      return question;
    });

    return questions;
  };

  return {
    created: replaceIdsWithQuestions(createdNormalizedLog),
    updated: replaceIdsWithQuestions(updatedNormalizedLog),
    deleted: deletedNormalizedLog,
  };
}
