import { createAction, createReducer } from '@reduxjs/toolkit';
import isUndefined from 'lodash/isUndefined';

import { OnboardingAnimation } from '../../constants/onboardingAnimations';

interface State {
  animationType: OnboardingAnimation | null;
  collapsedLogo: boolean;
  continueButtonText: string;
  continueButtonDisabled: boolean;
  continueButtonHelperText: string | null;
}

// Behaves as a Patch for this section of the state.
export const changeOnboardingStep = createAction(
  'onboardingContainer/CHANGE_ONBOARDING_STEP',
  (changes: Partial<State>) => ({
    payload: changes,
  }),
);

const initialState: State = {
  animationType: null,
  collapsedLogo: false,
  continueButtonText: '',
  continueButtonDisabled: true,
  continueButtonHelperText: null,
};

export default createReducer<State>(initialState, (builder) => {
  builder.addCase(changeOnboardingStep, (state, action) => {
    if (!isUndefined(action.payload.animationType)) {
      state.animationType = action.payload.animationType;
    }

    if (!isUndefined(action.payload.collapsedLogo)) {
      state.collapsedLogo = action.payload.collapsedLogo;
    }

    if (!isUndefined(action.payload.continueButtonText)) {
      state.continueButtonText = action.payload.continueButtonText;
    }

    if (!isUndefined(action.payload.continueButtonDisabled)) {
      state.continueButtonDisabled = action.payload.continueButtonDisabled;
    }

    if (!isUndefined(action.payload.continueButtonHelperText)) {
      state.continueButtonHelperText = action.payload.continueButtonHelperText;
    }
  });
});
