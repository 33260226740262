interface GetDeviceEmulatorScaleArgs {
  containerWidth: number;
  containerHeight: number;
  deviceWidth: number;
  deviceHeight: number;
}

export function getDeviceEmulatorScale({
  containerWidth,
  containerHeight,
  deviceWidth,
  deviceHeight,
}: GetDeviceEmulatorScaleArgs): number {
  const getDimensionScale = (
    containerDimension: number,
    deviceDimension: number,
  ) => {
    return Math.min(containerDimension / deviceDimension, 1);
  };

  const widthScale = getDimensionScale(containerWidth, deviceWidth);
  const heightScale = getDimensionScale(containerHeight, deviceHeight);

  return Math.min(widthScale, heightScale);
}
