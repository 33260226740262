// eslint-disable-next-line motivato/mui-should-not-be-imported-outside-of-core-ui
import { Theme, ThemeOptions, createTheme } from '@mui/material/styles';
import _ from 'lodash';

import variables from '../../styles/_exports.module.scss';

import isTestEnv from '../environment/isTestEnv';

export default function generateTheme(patch: ThemeOptions = {}): Theme {
  if (isTestEnv()) {
    // Jest is not able to export values from SASS
    return createTheme();
  }

  return createTheme(
    _.merge(
      {
        status: {
          danger: '#f00',
        },
        palette: {
          mode: 'dark',
          error: {
            main: variables.errorColor,
          },
          primary: {
            main: variables.primaryColorOld,
          },
          secondary: {
            main: variables.primaryColor,
          },
          disabled: {
            main: variables.defaultDisabledColor,
          },
        },
        components: {
          MuiIcon: {
            defaultProps: {
              // Replace the `material-icons` default value.
              baseClassName: 'material-icons-two-tone',
            },
          },
        },
      },
      patch,
    ),
  );
}
