import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import fetchHomeStats from '../../../../shared/networking/home/fetchHomeStats';
import fetchSurveyTemplates from '../../../../shared/networking/surveyTemplates/fetchSurveyTemplates';
import fetchSurveys from '../../../../shared/networking/surveys/fetchSurveys';
import SurveyQuestionReport from '../../../../shared/types/surveys/reporting/reporting';
import Survey from '../../../../shared/types/surveys/survey';
import SurveyTemplate from '../../../../shared/types/surveys/template';

export const loadHomeStats = createAsyncThunk(
  'home/LOAD_HOME_STATS',
  async (): Promise<SurveyQuestionReport[]> => {
    const result = await fetchHomeStats();

    return result.data?.surveyQuestionsResults || [];
  },
);

export const loadSurveys = createAsyncThunk(
  'home/LOAD_SURVEYS',
  async (): Promise<Survey[]> => {
    const result = await fetchSurveys();

    return result.data?.surveys || [];
  },
);

export const loadSurveyTemplates = createAsyncThunk(
  'home/LOAD_SURVEY_TEMPLATES',
  async (): Promise<SurveyTemplate[]> => {
    const result = await fetchSurveyTemplates({ limit: 3 });

    return result.data?.templates || [];
  },
);

interface State {
  surveys: Survey[];
  surveysLoaded: boolean;
  homeStats: SurveyQuestionReport[];
  homeStatsLoaded: boolean;
  surveyTemplates: SurveyTemplate[];
  surveyTemplatesLoaded: boolean;
}

export const initialState: State = {
  surveys: [],
  surveysLoaded: false,
  homeStats: [],
  homeStatsLoaded: false,
  surveyTemplates: [],
  surveyTemplatesLoaded: false,
};

export default createReducer<State>(initialState, (builder) => {
  builder.addCase(loadSurveys.fulfilled, (state, action) => {
    state.surveys = action.payload;
    state.surveysLoaded = true;
  });

  builder.addCase(loadSurveyTemplates.fulfilled, (state, action) => {
    state.surveyTemplates = action.payload;
    state.surveyTemplatesLoaded = true;
  });

  builder.addCase(loadHomeStats.fulfilled, (state, action) => {
    state.homeStats = action.payload;
    state.homeStatsLoaded = true;
  });
});
