/* eslint-disable motivato/all-components-should-have-storybook-stories */
import moment from 'moment';
import React, { Fragment } from 'react';

import styles from './styles.module.scss';

import DatePickerTextField from '../../../../shared/components/core-ui/DatePickerTextField';
import Select, {
  SelectOption,
} from '../../../../shared/components/core-ui/Select';
import Spacing from '../../../../shared/components/core-ui/Spacing';
import Label from '../../../../shared/components/core-ui/text/Label';
import Title from '../../../../shared/components/core-ui/text/Title';
import RoundOfResponses from '../../../../shared/types/surveys/roundOfResponses/roundOfResponses';
import QuestionResponsesCard from '../QuestionResponsesCard';

interface Props {
  roundOfResponses: RoundOfResponses | null;
}

export default function RoundOfResponsesLayout({
  roundOfResponses,
}: Props): React.ReactElement {
  return (
    <>
      <header className={styles.header}>
        <div className={styles.titles}>
          <Title noMargin>Burnout Check-in</Title>
          <Label>{roundOfResponses?.questions.length} Questions</Label>
        </div>

        <div className={styles.selects}>
          <div className={styles.selectAudience}>
            <Select value={1} label="Audience">
              <SelectOption value={1}>All Teammates</SelectOption>
            </Select>
          </div>

          <div className={styles.selectBatchDate}>
            <DatePickerTextField
              label="Batch Date"
              value={moment()}
              onChange={() => {}}
            />
          </div>
        </div>
      </header>

      <div className={styles.charts}>
        {roundOfResponses?.questions.map((surveyQuestion, index) => (
          <Fragment key={surveyQuestion.id}>
            <QuestionResponsesCard
              questionIndex={index}
              questionResponses={surveyQuestion}
            />

            <Spacing vertical={6} />
          </Fragment>
        ))}
      </div>
    </>
  );
}
