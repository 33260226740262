import classnames from 'classnames';
import React from 'react';

import textStyles from '../../../../styles/core-ui/textStyles.module.scss';
import styles from './styles.module.scss';

interface Props {
  bold?: boolean;
  children: React.ReactNode;
}

function Highlight(
  { bold, children }: Props,
  ref: React.ForwardedRef<HTMLSpanElement>,
): React.ReactElement {
  return (
    <span
      ref={ref}
      className={classnames(textStyles.base, styles.highlight, {
        [styles.bold]: bold,
      })}
    >
      {children}
    </span>
  );
}

export default React.forwardRef(Highlight);
