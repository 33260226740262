import IconButton from '@mui/material/IconButton';
import React from 'react';

import { IconSize } from '../../../enums/icons';
import Menu from '../Menu';
import MenuItem from '../MenuItem';
import ThreeDottedMenuIcon from '../icons/ThreeDottedMenuIcon';

interface Props<MenuItemType> {
  options: MenuItemType[];
  // If a callback onDelete is passed, a Delete option will be added
  onDelete?: () => void;
  getOptionText?: (option: MenuItemType) => string;
  deleteItemText?: string;
  onOptionSelected: (selectedOption: MenuItemType) => void;
}

function ThreeDottedMenu<MenuItemType = string>(
  {
    onDelete,
    options,
    getOptionText,
    deleteItemText,
    onOptionSelected,
  }: Props<MenuItemType>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const closeHandler = () => setAnchorEl(null);
  const openHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const optionSelectedHandler = (option: MenuItemType) => {
    closeHandler();
    onOptionSelected(option);
  };

  const deleteHandler = () => {
    closeHandler();
    onDelete?.();
  };

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <IconButton ref={ref} onClick={openHandler}>
        <ThreeDottedMenuIcon size={IconSize.medium} />
      </IconButton>

      <Menu onCloseHandler={closeHandler} element={anchorEl}>
        {options.map((option, index) => (
          <MenuItem
            key={index}
            onOptionSelected={() => optionSelectedHandler(option)}
            option={getOptionText ? getOptionText(option) : option}
          />
        ))}

        {onDelete && (
          <MenuItem
            key="delete"
            onOptionSelected={deleteHandler}
            option={deleteItemText || 'Delete'}
          />
        )}
      </Menu>
    </div>
  );
}

export default React.forwardRef(ThreeDottedMenu) as <T>(
  p: Props<T> & { ref?: React.Ref<HTMLButtonElement> },
) => React.ReactElement;
