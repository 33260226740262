import React from 'react';
import { matchPath, Outlet, useLocation, useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

import { ROUTES } from '../../../router';
import ComplexTab from '../../../shared/components/ComplexTab';
import Spacing from '../../../shared/components/core-ui/Spacing';
import Title from '../../../shared/components/core-ui/text/Title';

export default function CompanyInsightsLayout(): React.ReactElement {
  const TABS = [
    {
      route: ROUTES.companyInsights.routes.burnoutLikelihood.route,
      name: 'Burnout likelihood',
      color: styles.burnoutLikelihoodColor,
    },
    {
      route: ROUTES.companyInsights.routes.workflowSatisfaction.route,
      name: 'Workflow satisfaction',
      color: styles.workflowSatisfactionColor,
    },
    {
      route: ROUTES.companyInsights.routes.trustAndBelonging.route,
      name: 'Trust and belonging',
      color: styles.trustAndBelongingColor,
    },
    {
      route: ROUTES.companyInsights.routes.roadmapVisibility.route,
      name: 'Roadmap visibility',
      color: styles.roadmapVisibilityColor,
    },
  ];

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const activeStep = TABS.find((tab) => {
    return matchPath({ path: `${tab.route}/*` }, pathname);
  });

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.pageTitleContainer}>
          <Title>Company insights</Title>
        </div>

        <div className={styles.tabsWrapper}>
          <div className={styles.tabsContainer}>
            {TABS.map((route) => (
              <React.Fragment key={route.route}>
                <ComplexTab
                  text={route.name}
                  active={route.route === activeStep?.route}
                  onClick={() => navigate(route.route)}
                  percentage={20}
                  activeColor={route.color}
                />

                <Spacing total={3} />
              </React.Fragment>
            ))}
          </div>
        </div>
      </header>

      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
}
