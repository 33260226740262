import merge from 'lodash/merge';
import React from 'react';
import { useMemo } from 'react';
import {
  VictoryArea,
  VictoryChart,
  VictoryGroup,
  VictoryScatter,
  VictoryContainer,
  VictoryThemeDefinition,
  VictoryAxis,
} from 'victory';

import styles from './styles.module.scss';

import chartTheme from '../../../../constants/chartTheme/theme';
import resolveChartData from '../../../../helpers/surveys/reports/resolveChartData';
import useResizeObserver from '../../../../hooks/useResizeObserver';

type FormatAxisLabelType =
  | ((tick: number, index: number, ticks: number[]) => string)
  | string[];

interface Props<PointChartType> {
  lines: PointChartType[][];
  theme?: VictoryThemeDefinition;
  tickValuesX?: string[];
  formatAxisLabelX?: FormatAxisLabelType;
  formatAxisLabelY?: FormatAxisLabelType;
}

function LineChart<PointChartType extends { y: number }>(
  {
    theme,
    tickValuesX,
    formatAxisLabelX,
    formatAxisLabelY,
    lines: unresolvedLines,
  }: Props<PointChartType>,
  ref: React.ForwardedRef<VictoryArea>,
): React.ReactElement {
  const { ref: contentRef, size } = useResizeObserver();

  const resolvedLines = useMemo(
    () => resolveChartData(unresolvedLines),
    [unresolvedLines],
  );

  return (
    <div ref={contentRef} className={styles.container}>
      <div className={styles.content}>
        <VictoryChart
          theme={theme ? merge(chartTheme, theme) : chartTheme}
          width={size?.width}
          height={size?.height}
          domainPadding={{ y: [0, 20] }}
          containerComponent={<VictoryContainer responsive={false} />}
        >
          {resolvedLines.map((lineData, index) => (
            <VictoryGroup
              key={index}
              data={lineData.dots}
              animate={{
                duration: 2000,
                onLoad: { duration: 1000 },
              }}
            >
              <VictoryArea
                ref={ref}
                interpolation="natural"
                style={{
                  data: {
                    fill: lineData.color.background,
                    stroke: lineData.color.line,
                    strokeWidth: 1,
                  },
                }}
              />

              <VictoryScatter
                size={4}
                animate={{
                  onLoad: {
                    duration: 3000,
                    before: () => ({ opacity: 0 }),
                    after: () => ({ opacity: 1 }),
                  },
                }}
                style={{
                  data: {
                    fill: lineData.color.dot,
                    opacity: ({ datum }) => datum.opacity,
                  },
                }}
              />
            </VictoryGroup>
          ))}

          {/* Y axis labels */}
          <VictoryAxis
            tickFormat={formatAxisLabelY}
            dependentAxis
            domainPadding={20}
            singleQuadrantDomainPadding={{ x: true }}
          />

          {/* X axis labels */}
          <VictoryAxis tickFormat={formatAxisLabelX} tickValues={tickValuesX} />
        </VictoryChart>
      </div>
    </div>
  );
}

export default React.forwardRef(LineChart) as <T>(
  p: Props<T> & { ref?: React.Ref<HTMLButtonElement> },
) => React.ReactElement;
