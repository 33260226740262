import classnames from 'classnames';

import styles from './styles.module.scss';

import Emulator from '../../../../shared/components/Emulator';
import ErrorBoundary from '../../../../shared/components/ErrorBoundary';
import SurveyQuestion from '../../../../shared/types/surveys/question';
import Survey from '../../../../shared/types/surveys/survey';
import LiveSurveyScreen from '../../../LiveSurvey/components/LiveSurveyScreen';
import QuestionConfigPanel from '../QuestionConfigPanel';
import QuestionsBrowser from '../QuestionsBrowser';

interface Props {
  survey: Survey;
  onSave: () => void;
  onCancel: () => void;
  onQuestionDelete: (question: SurveyQuestion) => void;
  onQuestionCreate: (sectionName: string) => void;
  selectedQuestion: SurveyQuestion | null;
  onQuestionBrowserChange: (question: SurveyQuestion) => void;
  onQuestionConfigPanelChange: (question: SurveyQuestion) => void;
}

export default function SurveyBuilderEditorLayout({
  survey,
  onSave,
  onCancel,
  onQuestionDelete,
  onQuestionCreate,
  selectedQuestion,
  onQuestionBrowserChange,
  onQuestionConfigPanelChange,
}: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      <div
        className={classnames(
          styles.panelsContainer,
          styles.questionsBrowserContainer,
        )}
      >
        <QuestionsBrowser
          survey={survey}
          onCreate={onQuestionCreate}
          onDelete={onQuestionDelete}
          selectedQuestion={selectedQuestion}
          onQuestionChange={onQuestionBrowserChange}
        />
      </div>

      <main className={styles.content}>
        <ErrorBoundary>
          {selectedQuestion && selectedQuestion.animation?.lottie ? (
            <Emulator className={styles.emulator}>
              <LiveSurveyScreen
                question={selectedQuestion}
                isEditing
                onContinue={() => {}}
                animationJSON={selectedQuestion.animation.lottie}
              />
            </Emulator>
          ) : (
            <></>
          )}
        </ErrorBoundary>
      </main>

      <div className={styles.panelsContainer}>
        {selectedQuestion && (
          <QuestionConfigPanel
            onSave={onSave}
            question={selectedQuestion}
            onCancel={onCancel}
            onQuestionChange={onQuestionConfigPanelChange}
          />
        )}
      </div>
    </div>
  );
}
