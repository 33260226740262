/* eslint-disable motivato/all-components-should-have-storybook-stories */
import React from 'react';

import ArrowRightIcon from '../ArrowRightIcon';

function ArrowLeftIcon(
  props: Parameters<typeof ArrowRightIcon>[0],
  ref: React.ForwardedRef<SVGSVGElement>,
): React.ReactElement {
  return (
    <div style={{ transform: 'rotate(180deg)' }}>
      <ArrowRightIcon {...props} ref={ref} />
    </div>
  );
}

export default React.forwardRef(ArrowLeftIcon);
