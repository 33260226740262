/* eslint-disable motivato/all-components-should-have-storybook-stories */
import PossibleAudienceType from '../../../../../../shared/enums/schedulingEvents/possibleAudienceType';
import PossibleAudience from '../../../../../../shared/types/surveys/schedulingEvents/possibleAudience';
import AudienceOptionItem from '../../types/audienceOptionItemAutocomplete';

export default function getAutocompleteItemsFromPossibleAudience({
  users,
  slackUsers,
  slackChannels,
}: PossibleAudience): AudienceOptionItem[] {
  const optionsToReturn: AudienceOptionItem[] = [];

  users.forEach((user) => {
    optionsToReturn.push({
      id: user.id,
      name: user.name,
      type: PossibleAudienceType.User,
      object: user,
    });
  });

  slackUsers.forEach((slackUser) => {
    optionsToReturn.push({
      id: slackUser.id,
      name: slackUser.name,
      type: PossibleAudienceType.SlackUser,
      object: slackUser,
    });
  });

  slackChannels.forEach((slackChannel) => {
    optionsToReturn.push({
      id: slackChannel.id,
      name: slackChannel.name,
      type: PossibleAudienceType.SlackChannel,
      object: slackChannel,
    });
  });

  return optionsToReturn;
}
