import MuiSlider from '@mui/material/Slider';
import React, { ForwardedRef } from 'react';

export interface SliderMark {
  value: number;
  label: string;
}

interface Props {
  step?: number;
  value?: number;
  marks?: SliderMark[];
  defaultValue?: number;
  valueLabelFormat?: (value: number) => string;
  onChangeCommitted?: (value: number) => void;
  forceLabelToBeDisplayed?: boolean;
}

/**
 * @param marks - Array of values and labels for the slider
 * @param defaultValue - Value that will be initialized with, won't mirror after the initial mount
 * @param valueLabelFormat - Allows to format the Slider labels
 * @param onChangeCommitted - Fires only when the user has let go of the slider (onMouseUp)
 */
function Slider(
  { forceLabelToBeDisplayed, onChangeCommitted, ...otherProps }: Props,
  ref: ForwardedRef<HTMLSpanElement>,
): React.ReactElement {
  const otherConfig: Partial<Parameters<typeof MuiSlider>[0]> = {};

  if (forceLabelToBeDisplayed) {
    otherConfig.valueLabelDisplay = 'on';
  }

  return (
    <MuiSlider
      ref={ref}
      {...otherConfig}
      {...otherProps}
      onChangeCommitted={(__, value) => {
        onChangeCommitted?.(value as number);
      }}
    />
  );
}

export default React.forwardRef(Slider);
