import fetchAnimation from '../../../../networking/surveys/fetchAnimation';
import Animation from '../../../../types/surveys/animation';
import SurveyQuestion from '../../../../types/surveys/question';
import Survey from '../../../../types/surveys/survey';

export default function resolveAnimationsForSurvey(
  survey: Survey,
  onQuestionBatchReady: (processedSurveyQuestions: SurveyQuestion[]) => void,
): void {
  const resolveAnimation = async (
    surveyQuestion: SurveyQuestion,
  ): Promise<SurveyQuestion> => ({
    ...surveyQuestion,
    animation: {
      ...(surveyQuestion.animation as Animation),
      lottie: await fetchAnimation(surveyQuestion.animation as Animation),
    },
  });

  const [firstQuestion, secondQuestion, ...rest] = survey.questions || [];

  if (firstQuestion) {
    resolveAnimation(firstQuestion).then((surveyQuestion) =>
      onQuestionBatchReady([surveyQuestion]),
    );
  }

  if (secondQuestion) {
    resolveAnimation(secondQuestion).then((surveyQuestion) =>
      onQuestionBatchReady([surveyQuestion]),
    );
  }

  if (rest.length) {
    Promise.all(
      rest.map((surveyQuestion) => resolveAnimation(surveyQuestion)),
    ).then(onQuestionBatchReady);
  }
}
