import { gql, FetchResult } from '@apollo/client';

import PossibleAudience from '../../types/surveys/schedulingEvents/possibleAudience';
import { apolloClient } from '../apolloClient';

type Response = PossibleAudience;

export default async function fetchAudienceTargets(): Promise<
  FetchResult<Response>
> {
  const { data } = await apolloClient.query<Response>({
    query: gql`
      query audienceTargets {
        users {
          id
          name
          email
          slackUserId
          profile {
            image32
            image48
          }
        }
        slackUsers {
          id
          name
          email
          profile {
            image32
            image48
          }
        }
        slackChannels {
          id
          name
        }
      }
    `,
  });

  const users = data.users || [];

  return {
    data: {
      users,
      slackUsers: (data.slackUsers || []).filter((slackUser) => {
        return !users.find((user) => user.slackUserId === slackUser.id);
      }),
      slackChannels: data.slackChannels,
    },
  };
}
