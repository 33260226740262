import MUIToggleButton from '@mui/material/ToggleButton';
import MUIToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import React from 'react';

import styles from './styles.module.scss';

export enum ToggleButtonGroupSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

interface ButtonConfig {
  value: string;
  content: React.ReactNode;
}

interface Props {
  size?: ToggleButtonGroupSize;
  value: string | string[] | null;
  buttons: ButtonConfig[];
  onChange: (newValue: string | string[] | null) => void;
}

function ToggleButtonGroup(
  {
    size = ToggleButtonGroupSize.Medium,
    value,
    buttons,
    onChange,
    ...otherProps
  }: Props,
  ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
  const handleChange = (
    __: React.MouseEvent<HTMLElement>,
    newValue: string | string[] | null,
  ) => {
    if (isString(value) && isArray(newValue)) {
      // To deal with MUI's issue (it tries to value.splice), we had to give
      // it an array as value. This logic helps us keep it as a non-multi
      // option widget when the provided value is a string.
      if (newValue.length > 1) {
        return onChange(
          newValue.find((newValueItem) => newValueItem !== value) || null,
        );
      } else {
        return onChange(newValue?.[0] || null);
      }
    }

    onChange(newValue);
  };

  return (
    <MUIToggleButtonGroup
      ref={ref}
      {...otherProps}
      size={size}
      // We need to do this because MUI's component tries to value.splice
      value={isString(value) ? [value] : value}
      onChange={handleChange}
    >
      {buttons.map(({ value: buttonValue, content }) => (
        <MUIToggleButton
          className={styles.button}
          key={buttonValue}
          value={buttonValue}
        >
          {content}
        </MUIToggleButton>
      ))}
    </MUIToggleButtonGroup>
  );
}

export default React.forwardRef(ToggleButtonGroup);
