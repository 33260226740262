import {
  createAction,
  createAsyncThunk,
  createReducer,
} from '@reduxjs/toolkit';

import createTeam from '../../../../../shared/networking/teams/createTeam';
import updateTeam from '../../../../../shared/networking/teams/updateTeam';
import SlackUser from '../../../../../shared/types/slack/slackUsers';
import { Team } from '../../../../../shared/types/teams';

export const setDraftMembers = createAction<SlackUser[]>(
  'editTeam/SET_DRAFT_MEMBERS',
);

export const removeDraftMember = createAction<string>(
  'editTeam/REMOVE_DRAFT_MEMBER',
);

export const setTeamLead = createAction(
  'editTeam/SET_TEAM_LEAD',
  (userId: string, isTeamLead: boolean) => ({
    payload: {
      userId,
      isTeamLead,
    },
  }),
);

export const loadTeam = createAction<State>('editTeam/LOAD_TEAM');

// This will change the teamsList reducer
export const changeTeam = createAsyncThunk(
  'editTeam/CHANGE_TEAM',
  async ({
    id,
    name,
    members,
    teamLeadIds,
  }: {
    id?: string;
    name: string;
    members: SlackUser[];
    teamLeadIds: string[];
  }): Promise<{ isEdit: boolean; team: Team }> => {
    let newId: string | undefined = id;

    if (id) {
      await updateTeam(id, name, members, teamLeadIds);
    } else {
      const response = await createTeam(name, members, teamLeadIds);
      newId = response?.data?.SetGroup.id.toString();
    }

    return {
      isEdit: !!id,
      team: {
        id: newId ? +newId : -1,
        name,
        users: members,
      },
    };
  },
);

export const cleanEditTeam = createAction('editTeam/CLEAN_EDIT_TEAM');

interface State {
  teamLeads: { [key: string]: boolean };
  draftMembers: SlackUser[];
}

export const initialState: State = { teamLeads: {}, draftMembers: [] };

export default createReducer<State>(initialState, (builder) => {
  builder.addCase(setDraftMembers, (state, action) => {
    state.draftMembers = action.payload;
  });

  builder.addCase(removeDraftMember, (state, action) => {
    state.draftMembers = state.draftMembers.filter(
      (draftMember) => draftMember.id !== action.payload,
    );

    if (state.teamLeads[action.payload]) {
      // If the member being removed was a teamLead, remove
      // their userId from the teamLeads array.
      delete state.teamLeads[action.payload];
    }
  });

  builder.addCase(loadTeam, (state, action) => {
    state.teamLeads = action.payload.teamLeads;
    state.draftMembers = action.payload.draftMembers;
  });

  builder.addCase(setTeamLead, (state, action) => {
    state.teamLeads[action.payload.userId] = action.payload.isTeamLead;
  });

  builder.addCase(cleanEditTeam, () => initialState);
});
