/* eslint-disable motivato/all-components-should-have-storybook-stories */
import moment from 'moment';

import TeammateProfileLayout from '../TeammateProfileLayout';

export default function TeammateProfile(): React.ReactElement {
  return (
    <TeammateProfileLayout
      day={moment()}
      onDayChange={() => {}}
      profileImageUrl=""
    />
  );
}
