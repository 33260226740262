import { FetchResult } from '@apollo/client';

import ID from '../../types/id';
import SurveyTemplate from '../../types/surveys/template';
import fetchSurveyTemplates from './fetchSurveyTemplates';

type ResponseType = SurveyTemplate | null;

const queryName = 'template';
type Response = Record<typeof queryName, ResponseType | null>;

interface Args {
  id: ID;
}

export default async function fetchSurveyTemplate(
  args: Args,
): Promise<FetchResult<Response>> {
  const templatesData = await fetchSurveyTemplates();

  return {
    ...templatesData,
    data: {
      template:
        templatesData.data?.templates?.find(({ id }) => id === args.id) || null,
    },
  };
}
