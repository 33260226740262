/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-unused-vars */
import { FetchResult } from '@apollo/client';

import { GenericResponseType } from '../../apolloClient';

interface ResponseType extends GenericResponseType {
  companyName: string;
}

const mutationName = 'NewSlack';
type Response = Record<typeof mutationName, ResponseType>;

export default async function integrateWithSlack(
  code: string,
): Promise<FetchResult<Response>> {
  return Promise.resolve({
    data: {
      NewSlack: {
        code: 'test',
        ok: true,
        companyName: 'Motivato (Mocked)',
      },
    },
  });

  // return apolloClient.mutate<Response>({
  //   mutation: gql`
  //     mutation ${mutationName}($customerID: ID!, $slackCode: String!) {
  //       NewSlack(input: { customerID: $customerID, slackCode: $slackCode }) {
  //         ok
  //         companyName
  //         error {
  //           code
  //           message
  //         }
  //       }
  //     }
  //   `,
  //   variables: {
  //     slackCode: code,
  //     customerID: customerIDMock,
  //   },
  // });
}
