/* eslint-disable motivato/all-components-should-have-storybook-stories */
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

import { ROUTES } from '../../router';
import TemplateDialog from '../../shared/components/TemplateDialog';
import Spinner from '../../shared/components/core-ui/Spinner';
import { useAppDispatch } from '../../shared/hooks/redux/useAppDispatch';
import { useAppSelector } from '../../shared/hooks/redux/useAppSelector';
import SurveyTemplate from '../../shared/types/surveys/template';
import HomeForFirstTimeUser from '../HomeForFirstTimeUser';
import HomeLayout from './components/HomeLayout';
import { loadHomeStats, loadSurveys, loadSurveyTemplates } from './redux/home';

export default function Home(): React.ReactElement {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedTemplate, setSelectedTemplate] =
    useState<SurveyTemplate | null>(null);

  const {
    surveys,
    surveysLoaded,
    homeStats,
    homeStatsLoaded,
    surveyTemplates,
    surveyTemplatesLoaded,
  } = useAppSelector(({ home }) => ({
    surveys: home.surveys,
    surveysLoaded: home.surveysLoaded,
    homeStats: home.homeStats,
    homeStatsLoaded: home.homeStatsLoaded,
    surveyTemplates: home.surveyTemplates,
    surveyTemplatesLoaded: home.surveyTemplatesLoaded,
  }));

  useEffect(() => {
    dispatch(loadSurveys());
    dispatch(loadHomeStats());
    dispatch(loadSurveyTemplates());
  }, []);

  if (!surveysLoaded || !surveyTemplatesLoaded || !homeStatsLoaded) {
    return (
      <div className={styles.spinnerContainer}>
        <Spinner />
      </div>
    );
  }

  if (!surveys.length) {
    return <HomeForFirstTimeUser />;
  }

  return (
    <>
      <HomeLayout
        stats={homeStats}
        surveys={surveys}
        surveyTemplates={surveyTemplates}
        onSeeAllTemplatesClick={() => navigate(ROUTES.templates.route)}
        onViewLatestResponses={(survey) =>
          survey.id && navigate(ROUTES.roundOfResponses.getRoute(survey.id))
        }
        onSurveyEdit={(survey) =>
          survey.id && navigate(ROUTES.surveyBuilder.getRoute(survey.id))
        }
        onTemplateClick={(template) =>
          template.id && setSelectedTemplate(template)
        }
      />

      {selectedTemplate && (
        <TemplateDialog
          onClose={() => setSelectedTemplate(null)}
          template={selectedTemplate}
        />
      )}
    </>
  );
}
