import classnames from 'classnames';
import { createRef } from 'react';

import styles from './styles.module.scss';

import TextField, {
  TextFieldSize,
  TextFieldVariant,
} from '../../../../../../../../../shared/components/core-ui/TextField';
import P from '../../../../../../../../../shared/components/core-ui/text/P';

interface Props {
  text: string;
  index: number;
  onBlur: () => void;
  onFocus: () => void;
  placeholder: string;
  onTextChange: (text: string) => void;
  shouldDisplayIndex: boolean;
}

export default function LiveSurveyListAnswerRow({
  text,
  index,
  onBlur,
  onFocus,
  placeholder,
  onTextChange,
  shouldDisplayIndex,
}: Props): React.ReactElement {
  const inputRef = createRef<HTMLInputElement>();

  const containerClickHandler = () => {
    inputRef.current?.focus();
  };

  return (
    <div
      onClick={containerClickHandler}
      className={classnames(styles.row, {
        [styles.displayingIndex]: shouldDisplayIndex,
      })}
    >
      <div className={styles.textContainer}>
        <P>{index + 1}.</P>
      </div>

      <div className={styles.divContainer}>
        <TextField
          value={text || ''}
          size={TextFieldSize.medium}
          onBlur={onBlur}
          variant={TextFieldVariant.standard}
          onFocus={onFocus}
          inputRef={inputRef}
          multiline
          onChangeText={onTextChange}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}
