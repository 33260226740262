import React from 'react';

import variables from '../../../styles/_exports.module.scss';

interface Props {
  flex?: boolean;
  total?: number;
  vertical?: number;
  horizontal?: number;
}

function Spacing(
  { flex, total, vertical, horizontal }: Props,
  ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
  const spacing = parseFloat(variables.spacingValue) || 0;

  const getPadding = (dimension?: number): number => {
    if (dimension) {
      return spacing * dimension;
    }

    if (total) {
      return spacing * total;
    }

    return 0;
  };

  return (
    <div
      ref={ref}
      style={{
        flex: flex ? 1 : '0 1 auto',
        width: getPadding(horizontal),
        height: getPadding(vertical),
        minWidth: getPadding(horizontal),
        minHeight: getPadding(vertical),
      }}
    />
  );
}

export default React.forwardRef(Spacing);
