import classnames from 'classnames';
import React, { ForwardedRef } from 'react';

import textStyles from '../../../../styles/core-ui/textStyles.module.scss';
import styles from './styles.module.scss';

import {
  CommonTextProps,
  resolveReusableClassNamesForText,
} from '../../../../helpers/text';

interface Props extends CommonTextProps {
  children: React.ReactNode;
}

function Label(
  { children, ...extraProps }: Props,
  ref: ForwardedRef<HTMLParagraphElement>,
): React.ReactElement {
  const { otherProps, reusableStyles } =
    resolveReusableClassNamesForText(extraProps);

  return (
    <p
      ref={ref}
      {...otherProps}
      className={classnames(textStyles.base, reusableStyles, styles.label)}
    >
      {children}
    </p>
  );
}

export default React.forwardRef(Label);
