import classnames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

import P from '../core-ui/text/P';
import Title from '../core-ui/text/Title';
import ComplexTabAsset from './components/ComplexTabAsset';

interface Props {
  text: string;
  active?: boolean;
  onClick: () => void;
  percentage: number;
  activeColor?: string;
}

export default function ComplexTab({
  text,
  active,
  onClick,
  percentage,
  activeColor,
}: Props): React.ReactElement {
  const tabStyles: React.CSSProperties = {};

  if (active) {
    tabStyles.backgroundColor = activeColor;
  }

  return (
    <div
      role="button"
      style={tabStyles}
      onClick={onClick}
      className={classnames(styles.container, { [styles.active]: active })}
    >
      <P>{text}</P>
      <Title noMargin>{percentage}%</Title>

      <div className={styles.assetContainer}>
        <ComplexTabAsset />
      </div>
    </div>
  );
}
