/* eslint-disable motivato/all-components-should-have-storybook-stories */
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';
import React from 'react';

import { resolveCommonIconProps } from '../../../../helpers/icons/common';
import { CommonIconProps } from '../../../../types/iconsCommon';

function ArrowRightIcon(
  props: CommonIconProps,
  ref: React.ForwardedRef<SVGSVGElement>,
): React.ReactElement {
  return <ArrowRightAlt ref={ref} {...resolveCommonIconProps(props)} />;
}

export default React.forwardRef(ArrowRightIcon);
