import { SurveyQuestionType } from '../../../../../../../shared/enums/surveys/questionType';
import LiveSurveyResponseProps from '../../../../../../../shared/types/surveys/liveSurveys/liveSurveys';
import LiveSurveyBooleanAnswer from '../LiveSurveyBooleanAnswer';
import LiveSurveyListAnswer from '../LiveSurveyListAnswer';
import LiveSurveySliderAnswer from '../LiveSurveySliderAnswer';
import LiveSurveyTextAnswer from '../LiveSurveyTextAnswer';

export default function LiveSurveyAnswer({
  questionConfig,
  ...otherProps
}: LiveSurveyResponseProps): React.ReactElement {
  return (
    <>
      {questionConfig.type === SurveyQuestionType.Boolean && (
        <LiveSurveyBooleanAnswer
          {...otherProps}
          questionConfig={questionConfig}
        />
      )}

      {questionConfig.type === SurveyQuestionType.Text && (
        <LiveSurveyTextAnswer {...otherProps} questionConfig={questionConfig} />
      )}

      {questionConfig.type === SurveyQuestionType.List && (
        <LiveSurveyListAnswer {...otherProps} questionConfig={questionConfig} />
      )}

      {questionConfig.type === SurveyQuestionType.Slider && (
        <LiveSurveySliderAnswer
          {...otherProps}
          questionConfig={questionConfig}
        />
      )}
    </>
  );
}
