import { useState } from 'react';

import styles from './styles.module.scss';

import TextField, {
  TextFieldSize,
  TextFieldVariant,
} from '../../../../../../../shared/components/core-ui/TextField';
import LiveSurveyResponseProps from '../../../../../../../shared/types/surveys/liveSurveys/liveSurveys';
import { SurveyTextResponse } from '../../../../../../../shared/types/surveys/questions/textResponse';

interface Props extends LiveSurveyResponseProps {
  questionConfig: SurveyTextResponse;
}

export default function LiveSurveyTextAnswer({
  isEditing,
  renderFooter,
  questionConfig,
}: Props): React.ReactElement {
  const [text, setText] = useState('');

  return (
    <>
      <div className={styles.container}>
        <TextField
          value={text}
          size={TextFieldSize.medium}
          variant={TextFieldVariant.standard}
          multiline
          autoFocus={!isEditing}
          onChangeText={setText}
          placeholder={
            questionConfig?.config?.placeholder || 'Placeholder example'
          }
        />
      </div>

      {renderFooter({ buttonDisabled: true })}
    </>
  );
}
