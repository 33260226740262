/* eslint-disable motivato/all-components-should-have-storybook-stories */
import { Navigate } from 'react-router-dom';

interface Props {
  allow: boolean;
  redirectsTo: string;
  element: JSX.Element;
}

export default function ProtectedRoute({
  allow,
  redirectsTo,
  element,
}: Props): React.ReactElement {
  if (allow) {
    return element;
  }

  return <Navigate to={redirectsTo} replace />;
}
