import React from 'react';

import styles from './styles.module.scss';

import {
  COLORS_FOR_SURVEY_QUESTION_TYPE,
  ICONS_FOR_SURVEY_QUESTION_TYPE,
} from '../../../../../../shared/constants/surveys/questionTypes';
import { SurveyQuestionType } from '../../../../../../shared/enums/surveys/questionType';

interface Props {
  questionType: SurveyQuestionType;
}

export default function SurveyIconContainer({
  questionType,
}: Props): React.ReactElement {
  const icon = ICONS_FOR_SURVEY_QUESTION_TYPE[questionType];
  const color = COLORS_FOR_SURVEY_QUESTION_TYPE[questionType];

  return (
    <div style={{ backgroundColor: color }} className={styles.container}>
      {React.createElement(icon, {})}
    </div>
  );
}
