import { gql, FetchResult } from '@apollo/client';

import ID from '../../types/id';
import Survey from '../../types/surveys/survey';
import { apolloClient } from '../apolloClient';

type ResponseType = Survey;

const queryName = 'survey';
type Response = Record<typeof queryName, ResponseType | null>;

interface Args {
  id: ID;
}

export default function fetchSurvey({
  id,
}: Args): Promise<FetchResult<Response>> {
  return apolloClient.query<Response>({
    query: gql`
      query survey{
        survey(id: "${id}") {
          id
          name
          isValid
          isEnabled
          description
          questions {
            id
            name
            question
            sortOrder
            followUps {
              id
              name
              question
              sortOrder
              followUps {
                id
                name
                question
                sortOrder
              }
            }
            animation {
              id
              url
            }
          }
          schedulingEvents {
            id
          }
        }
      }
    `,
  });
}
