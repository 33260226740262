import styleVariables from '../../styles/_exports.module.scss';

import SurveyBooleanIcon from '../../components/core-ui/icons/surveys/SurveyBooleanIcon';
import SurveyListIcon from '../../components/core-ui/icons/surveys/SurveyListIcon';
import SurveySliderIcon from '../../components/core-ui/icons/surveys/SurveySliderIcon';
import SurveyTextIcon from '../../components/core-ui/icons/surveys/SurveyTextIcon';
import { SurveyQuestionType } from '../../enums/surveys/questionType';
import { ComponentType } from '../../types/components';
import { CommonIconProps } from '../../types/iconsCommon';

export const ICONS_FOR_SURVEY_QUESTION_TYPE: {
  [key in keyof typeof SurveyQuestionType]: ComponentType<
    CommonIconProps,
    SVGSVGElement
  >;
} = {
  [SurveyQuestionType.Text]: SurveyTextIcon,
  [SurveyQuestionType.List]: SurveyListIcon,
  [SurveyQuestionType.Slider]: SurveySliderIcon,
  [SurveyQuestionType.Boolean]: SurveyBooleanIcon,
};

export const COLORS_FOR_SURVEY_QUESTION_TYPE: {
  [key in keyof typeof SurveyQuestionType]: string;
} = {
  [SurveyQuestionType.Text]: styleVariables.surveyTextColor,
  [SurveyQuestionType.List]: styleVariables.surveyListColor,
  [SurveyQuestionType.Slider]: styleVariables.surveySliderColor,
  [SurveyQuestionType.Boolean]: styleVariables.surveyBooleanColor,
};
