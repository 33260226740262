import SurveyQuestion from '../../../../types/surveys/question';
import Survey from '../../../../types/surveys/survey';

export default function replaceQuestionInSurvey(
  survey: Survey,
  newQuestion: SurveyQuestion,
): Survey {
  const mapFollowUps = (
    followUps?: SurveyQuestion[],
  ): SurveyQuestion[] | undefined => {
    if (followUps) {
      return followUps.map((question) => {
        if (question.id === newQuestion.id) {
          return newQuestion;
        }

        if (question.followUps?.length) {
          return {
            ...question,
            followUps: mapFollowUps(question.followUps),
          };
        }

        return question;
      });
    }

    return followUps;
  };

  return {
    ...survey,
    // The very first level of questions can be found in `Survey.questions',
    // but from that point onwards, questions are located in 'SurveyQuestion.followUps'.
    questions: mapFollowUps(survey.questions),
  };
}
