// eslint-disable-next-line motivato/mui-should-not-be-imported-outside-of-core-ui
import { PickersDayProps } from '@mui/lab/PickersDay';
import moment from 'moment';

interface ReturnType {
  pickersDayProps: PickersDayProps<moment.Moment>;
  meta?: {
    isSelected: boolean;
    isFirstDay: boolean;
    isLastDay: boolean;
    dayIsBetweenSelection: boolean;
  };
}

export default function getMetaOnSelectedCalendarDay(
  dateReceived: moment.Moment,
  daysSelected: Record<string, boolean>,
  pickersDayProps: PickersDayProps<moment.Moment>,
): ReturnType {
  const date = moment(dateReceived);
  const isSelected = daysSelected[date.date()];

  if (!isSelected || !date.isValid()) {
    const props = { pickersDayProps };

    // We need to make sure non-selected days don't appear selected on the UI
    props.pickersDayProps.selected = false;

    return props;
  }

  const dayBefore = moment(date).subtract(1, 'day');
  const dayAfter = moment(date).add(1, 'day');

  const isFirstDayOfMonth = moment(date).startOf('month');
  const isLastDayOfMonth = moment(date).endOf('month');

  const isFirstDay =
    !daysSelected[dayBefore.date()] || date.isSame(isFirstDayOfMonth, 'day');

  const isLastDay =
    !daysSelected[dayAfter.date()] || date.isSame(isLastDayOfMonth, 'day');

  const dayIsBetweenSelection = !isFirstDay || !isLastDay;

  return {
    pickersDayProps,
    meta: {
      isSelected,
      isFirstDay,
      isLastDay,
      dayIsBetweenSelection,
    },
  };
}
