import { ForwardedRef, MutableRefObject, useEffect, useRef } from 'react';

export default function useForwardedRef<RefType>(
  ref: ForwardedRef<RefType>,
): MutableRefObject<RefType | null> {
  const innerRef = useRef<RefType | null>(null);

  useEffect(() => {
    if (ref) {
      if (typeof ref === 'function') {
        ref(innerRef.current);
      } else {
        ref.current = innerRef.current;
      }
    }
  });

  return innerRef;
}
