import { Mapping } from 'classnames';

import textStyles from '../styles/core-ui/textStyles.module.scss';

export interface CommonTextProps {
  center?: boolean;
  noMargin?: boolean;
  uppercase?: boolean;
}

// Common text styles will be applied to all text components.
export function resolveReusableClassNamesForText({
  center,
  noMargin,
  uppercase,
  ...otherProps
}: CommonTextProps): Mapping {
  return {
    otherProps,
    reusableStyles: {
      [textStyles.center]: center,
      [textStyles.noMargin]: noMargin,
      [textStyles.uppercase]: uppercase,
    },
  };
}
