import { useMemo } from 'react';

import styles from './styles.module.scss';

import getReportDataMeta from '../../../helpers/surveys/reports/getReportDataMeta';
import SurveyQuestion from '../../../types/surveys/question';
import SurveyQuestionReport from '../../../types/surveys/reporting/reporting';
import Spacing from '../../core-ui/Spacing';
import LineChart from '../../core-ui/charts/LineChart';
import P from '../../core-ui/text/P';
import Title from '../../core-ui/text/Title';
import DifferenceTag from '../DifferenceTag';
import TipCard from '../TipCard';

interface Props {
  name: string;
  report: SurveyQuestionReport[];
  question: SurveyQuestion;
  description: string;
}

export default function DetailedReportChart({
  name,
  report,
  question,
  description,
}: Props): React.ReactElement {
  const data = useMemo(() => {
    return report.map(({ result: reportData }) =>
      reportData.map(({ value: y }) => ({ y })),
    );
  }, [report]);

  const { percentage, difference } = useMemo(
    () => getReportDataMeta(data, question),
    [data, question],
  );

  return (
    <div className={styles.container}>
      <div className={styles.chartContainer}>
        <LineChart lines={data} />
      </div>

      <div className={styles.content}>
        <Title>{name}</Title>

        <div className={styles.numberContainer}>
          <Title>{percentage}%</Title>

          <Spacing total={1} />
          <DifferenceTag difference={difference} />
        </div>

        <P>{description}</P>

        <div className={styles.tipContainer}>
          <TipCard title="Connect kudos to roadmap plans" onClick={() => {}} />
        </div>
      </div>
    </div>
  );
}
