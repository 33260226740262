import React from 'react';

import styles from './styles.module.scss';

import Spacing from '../Spacing';

interface Props {
  spacing?: number;
}

function Separator(
  { spacing = 1, ...otherProps }: Props,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <>
      <Spacing total={spacing} />
      <div ref={ref} className={styles.container} {...otherProps} />
      <Spacing total={spacing} />
    </>
  );
}

export default React.forwardRef(Separator);
