import styles from './styles.module.scss';

import Select, {
  SelectOption,
  SelectSize,
} from '../../../../shared/components/core-ui/Select';
import Separator from '../../../../shared/components/core-ui/Separator';
import Spacing from '../../../../shared/components/core-ui/Spacing';
import Toggle from '../../../../shared/components/core-ui/Toggle';
import P from '../../../../shared/components/core-ui/text/P';
import {
  PredefinedFrequency,
  CustomFrequencyEvery,
  CustomFrequencyDay,
} from '../../../../shared/enums/schedulingEvents/frequency';
import SchedulingEventMonthlyConfig from './components/SchedulingEventMonthlyConfig';
import SchedulingEventWeeklyConfig from './components/SchedulingEventWeeklyConfig';

const FREQUENCY_TEXT: { [key in PredefinedFrequency]: string } = {
  [PredefinedFrequency.Custom]: 'Custom...',
  [PredefinedFrequency.EveryMonday]: 'Every Monday',
  [PredefinedFrequency.EveryFriday]: 'Every Friday',
  [PredefinedFrequency.EveryWeekday]: 'Every Weekday',
  [PredefinedFrequency.EveryTwoWeeks]: 'Every 1st and 15th of the month',
  [PredefinedFrequency.Every1stOfMonth]: 'Every 1st of the month',
};

interface Props {
  // Values
  repeats: boolean;
  frequency: PredefinedFrequency;
  daysOfMonth: number[];
  weekFrequency: CustomFrequencyDay[];
  customFrequency: CustomFrequencyEvery;

  // onChange
  onRepeatsChange: (value: boolean) => void;
  onFrequencyChange: (value: PredefinedFrequency) => void;
  onDaysOfMonthChange: (days: number[]) => void;
  onWeekFrequencyChange: (value: CustomFrequencyDay[]) => void;
  onCustomFrequencyChange: (value: CustomFrequencyEvery) => void;
}

export default function SurveySchedulingEventRepeatsSection({
  repeats,
  frequency,
  daysOfMonth,
  weekFrequency,
  customFrequency,
  onRepeatsChange,
  onFrequencyChange,
  onDaysOfMonthChange,
  onWeekFrequencyChange,
  onCustomFrequencyChange,
}: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      <div className={styles.repeatsContainer}>
        <P>Repeats</P>

        <Toggle checked={repeats} onChange={onRepeatsChange} />
      </div>

      {repeats && (
        <>
          <Spacing vertical={1} />

          <Select
            size={SelectSize.small}
            label="Frequency"
            value={frequency}
            onChange={(newFrequency: string | number) =>
              onFrequencyChange(newFrequency as PredefinedFrequency)
            }
          >
            {Object.entries(FREQUENCY_TEXT).map(([value, text]) => (
              <SelectOption key={value} value={value}>
                {text}
              </SelectOption>
            ))}
          </Select>

          {frequency === PredefinedFrequency.Custom && (
            <>
              <Separator spacing={2} />

              <Select
                size={SelectSize.small}
                label="Every"
                value={customFrequency}
                onChange={(newFrequency: string | number) =>
                  onCustomFrequencyChange(newFrequency as CustomFrequencyEvery)
                }
              >
                <SelectOption value={CustomFrequencyEvery.Week}>
                  Week
                </SelectOption>

                <SelectOption value={CustomFrequencyEvery.Month}>
                  Month
                </SelectOption>
              </Select>

              {customFrequency === CustomFrequencyEvery.Week && (
                <SchedulingEventWeeklyConfig
                  onChange={onWeekFrequencyChange}
                  selectedDays={weekFrequency}
                />
              )}

              {customFrequency === CustomFrequencyEvery.Month && (
                <SchedulingEventMonthlyConfig
                  onChange={onDaysOfMonthChange}
                  selectedDays={daysOfMonth}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
