import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import React, { ForwardedRef } from 'react';

export const Tab = MuiTab;

type TabType = React.ReactElement<typeof Tab>;

interface Props {
  value: string | number;
  children: TabType | TabType[];
  onChangeTab: (value: string | number) => void;
}

function Tabs(
  { value, children, onChangeTab, ...otherProps }: Props,
  ref: ForwardedRef<HTMLButtonElement>,
): React.ReactElement {
  return (
    <MuiTabs
      ref={ref}
      value={value}
      {...otherProps}
      onChange={(__, newTab) => onChangeTab(newTab)}
    >
      {children}
    </MuiTabs>
  );
}

export default React.forwardRef(Tabs);
