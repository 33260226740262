/* eslint-disable motivato/all-components-should-have-storybook-stories */
import React from 'react';

import { resolveCommonCustomIconProps } from '../../../../../helpers/icons/custom';
import { CommonIconProps } from '../../../../../types/iconsCommon';

function SurveyListIcon(
  props: CommonIconProps,
  ref: React.ForwardedRef<SVGSVGElement>,
): React.ReactElement {
  const { color, width, height } = resolveCommonCustomIconProps(props);

  return (
    <svg ref={ref} width={width} height={height} viewBox="0 0 19.717 16.36">
      <g
        id="Grupo_15160"
        data-name="Grupo 15160"
        transform="translate(2022.298 3138.18)"
      >
        <g id="Grupo_15156" data-name="Grupo 15156">
          <g id="Grupo_15155" data-name="Grupo 15155">
            <path
              id="Trazado_11202"
              data-name="Trazado 11202"
              d="M-2021.21-3137.525l-.366.116a.46.46,0,0,1-.112.015.279.279,0,0,1-.274-.272.28.28,0,0,1,.208-.278l.539-.178a1.046,1.046,0,0,1,.3-.058h.011a.311.311,0,0,1,.31.32v3.1a.312.312,0,0,1-.31.319.316.316,0,0,1-.31-.319Z"
              fill={color}
            />
            <path
              id="Trazado_11203"
              data-name="Trazado 11203"
              d="M-2022.136-3128.8l1.092-.958c.488-.425.666-.661.666-.991a.541.541,0,0,0-.574-.571.863.863,0,0,0-.7.409.284.284,0,0,1-.219.1.288.288,0,0,1-.284-.293.328.328,0,0,1,.071-.2,1.363,1.363,0,0,1,1.179-.592,1.078,1.078,0,0,1,1.173,1.095v.01c0,.577-.294.891-.924,1.42l-.732.629h1.443a.278.278,0,0,1,.274.283.278.278,0,0,1-.274.283h-2.017a.3.3,0,0,1-.335-.3A.4.4,0,0,1-2022.136-3128.8Z"
              fill={color}
            />
            <path
              id="Trazado_11204"
              data-name="Trazado 11204"
              d="M-2022.141-3122.3a.313.313,0,0,1-.1-.231.309.309,0,0,1,.3-.309.3.3,0,0,1,.209.084,1.066,1.066,0,0,0,.807.351.563.563,0,0,0,.615-.555v-.011c0-.361-.32-.566-.818-.566h-.127a.275.275,0,0,1-.269-.277.371.371,0,0,1,.132-.262l.833-.917h-1.29a.277.277,0,0,1-.275-.278.278.278,0,0,1,.275-.283h1.819a.283.283,0,0,1,.309.283.475.475,0,0,1-.177.351l-.839.9c.539.068,1.047.351,1.047,1.027v.011a1.154,1.154,0,0,1-1.245,1.163A1.641,1.641,0,0,1-2022.141-3122.3Z"
              fill={color}
            />
          </g>
        </g>
        <g id="Grupo_15157" data-name="Grupo 15157">
          <path
            id="Trazado_11205"
            data-name="Trazado 11205"
            d="M-2017.063-3130.245h13.195"
            fill={color}
          />
          <path
            id="Trazado_11206"
            data-name="Trazado 11206"
            d="M-2003.868-3129.245h-13.195a1.171,1.171,0,0,1-1.287-1,1.17,1.17,0,0,1,1.287-1h13.195a1.17,1.17,0,0,1,1.287,1A1.171,1.171,0,0,1-2003.868-3129.245Z"
            fill={color}
          />
        </g>
        <g id="Grupo_15158" data-name="Grupo 15158">
          <path
            id="Trazado_11207"
            data-name="Trazado 11207"
            d="M-2017.063-3124.245h13.195"
            fill={color}
          />
          <path
            id="Trazado_11208"
            data-name="Trazado 11208"
            d="M-2003.868-3123.245h-13.195a1.171,1.171,0,0,1-1.287-1,1.17,1.17,0,0,1,1.287-1h13.195a1.17,1.17,0,0,1,1.287,1A1.171,1.171,0,0,1-2003.868-3123.245Z"
            fill={color}
          />
        </g>
        <g id="Grupo_15159" data-name="Grupo 15159">
          <path
            id="Trazado_11209"
            data-name="Trazado 11209"
            d="M-2017.063-3136.245h13.195"
            fill={color}
          />
          <path
            id="Trazado_11210"
            data-name="Trazado 11210"
            d="M-2003.868-3135.245h-13.195a1.171,1.171,0,0,1-1.287-1,1.17,1.17,0,0,1,1.287-1h13.195a1.17,1.17,0,0,1,1.287,1A1.171,1.171,0,0,1-2003.868-3135.245Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

export default React.forwardRef(SurveyListIcon);
