import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import moment from 'moment';
import React, { useState } from 'react';

import { IconSize } from '../../../enums/icons';
import TextField, { TextFieldSize } from '../TextField';
import CalendarIcon from '../icons/CalendarIcon';

type TextFieldProps = Parameters<typeof TextField>[0];

interface Props {
  size?: TextFieldSize;
  label: string;
  value: moment.Moment;
  onChange: (newValue: moment.Moment | null) => void;
  inputProps?: Partial<TextFieldProps>;
}

const ICON_SIZE: { [key in TextFieldSize]: IconSize } = {
  [TextFieldSize.small]: IconSize.small,
  [TextFieldSize.medium]: IconSize.medium,
};

function DatePickerTextField(
  { size = TextFieldSize.medium, label, value, onChange, ...otherProps }: Props,
  ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
  const [open, setOpen] = useState(false);

  const openDialogHandler = () => {
    setOpen(true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        ref={ref}
        {...otherProps}
        open={open}
        label={label}
        value={value}
        minDate={moment()}
        onClose={() => setOpen(false)}
        onChange={(newValue: moment.Moment | null) => {
          onChange(moment(newValue));
        }}
        renderInput={(params: unknown) => (
          <TextField
            {...({
              ...((params as TextFieldProps) || {}),
              rightButtonIcon: <CalendarIcon size={ICON_SIZE[size]} />,
              onRightButtonIconClick: openDialogHandler,
            } as TextFieldProps)}
            size={size}
            onClick={openDialogHandler}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default React.forwardRef(DatePickerTextField);
