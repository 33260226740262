import Audience from '../../../types/surveys/schedulingEvents/audience';
import PossibleAudience from '../../../types/surveys/schedulingEvents/possibleAudience';
import {
  SchedulingEventTargetAudience,
  SchedulingEventTargetAudienceToCreate,
} from '../../../types/surveys/schedulingEvents/schedulingEvent';

export default function transformAudienceToSchedulingEventType(
  audience: PossibleAudience,
): SchedulingEventTargetAudienceToCreate[] {
  return [
    ...audience.users.map(({ id: userId }) => ({ userId })),
    ...audience.slackChannels.map(({ id: slackChannelId }) => ({
      slackChannelId,
    })),
  ];
}

export function transformSchedulingEventTypeToAudience(
  schedulingEventAudience: SchedulingEventTargetAudience[],
): Audience {
  const newAudience: Audience = {
    users: [],
    teams: [],
    slackChannels: [],
  };

  schedulingEventAudience.forEach((option) => {
    if (option.user?.id) {
      newAudience.users.push(option.user);
    }

    if (option.team?.id) {
      newAudience.teams.push(option.team);
    }

    if (option.slackChannel?.id) {
      newAudience.slackChannels.push(option.slackChannel);
    }
  });

  return newAudience;
}
