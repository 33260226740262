import { SurveyQuestionType } from '../../enums/surveys/questionType';
import generateMockId from '../../helpers/mocks/generateMockId';
import Survey from '../../types/surveys/survey';

export const NEW_SURVEY_FROM_SCRATCH: Survey = {
  name: 'New survey',
  isValid: false,
  isEnabled: false,
  questions: [
    {
      id: generateMockId(),
      name: 'Example question',
      parentId: null,
      sortOrder: 0,
      isEnabled: true,
      sectionName: '',
      isAnonymous: false,
      globalQuestionId: null,
      question: {
        type: SurveyQuestionType.Boolean,
        config: {},
      },
    },
  ],
};
