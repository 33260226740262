import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { PickersDayProps } from '@mui/lab/PickersDay';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import classnames from 'classnames';
import moment from 'moment';
import React from 'react';

import styles from './styles.module.scss';

import TextField, { TextFieldSize } from '../TextField';

type TextFieldProps = Parameters<typeof TextField>[0];

interface Props {
  size?: TextFieldSize;
  value: moment.Moment | null;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  onChange: (newValue: moment.Moment | null) => void;
  hideNavigation?: boolean;
  onCustomDayRender?: (
    date: moment.Moment,
    selectedDates: Array<moment.Moment | null>,
    pickersDayProps: PickersDayProps<moment.Moment>,
  ) => React.ReactElement;
}

function CalendarDatePicker(
  {
    value,
    onChange,
    minDate,
    maxDate,
    hideNavigation,
    onCustomDayRender,
    ...otherProps
  }: Props,
  ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
  return (
    <span
      className={classnames(styles.datePicker, {
        [styles.hideNavigation]: hideNavigation,
      })}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDatePicker
          ref={ref}
          {...otherProps}
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          renderDay={onCustomDayRender}
          displayStaticWrapperAs="desktop"
          allowSameDateSelection
          onChange={(newValue: moment.Moment | null) => {
            onChange(moment(newValue));
          }}
          renderInput={(params: unknown) => (
            <TextField
              {...({
                ...((params as TextFieldProps) || {}),
              } as TextFieldProps)}
            />
          )}
        />
      </LocalizationProvider>
    </span>
  );
}

export default React.forwardRef(CalendarDatePicker);
