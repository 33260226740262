import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MuiTextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material/styles';
import classnames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

import generateTheme from '../../../helpers/mui/theme';
import { XOR } from '../../../helpers/typescript/xor';
import Tooltip from '../Tooltip';

const theme = generateTheme();

export enum TextFieldSize {
  small = 'small',
  medium = 'medium',
}

export enum TextFieldVariant {
  outlined = 'outlined',
  standard = 'standard',
}

interface BaseProps {
  size?: TextFieldSize;
  type?: string;
  value?: string | null;
  label?: string;
  error?: boolean;
  onBlur?: () => void;
  onClick?: () => void;
  onFocus?: () => void;
  maxRows?: number;
  variant?: TextFieldVariant;
  inputRef?: React.RefObject<HTMLInputElement>;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent) => void;
  multiline?: boolean;
  autoFocus?: boolean;
  helperText?: string;
  placeholder?: string;
  onChangeText?: (text: string) => void;
  allowBrowserAutoComplete?: boolean;
  name?: string;
}

type WithRightButtonIconProps = BaseProps & {
  rightButtonIcon?: React.ReactElement;
  rightButtonTooltip?: string;
  hideRightButtonIcon?: boolean;
  onRightButtonIconClick?: () => void;
};

interface WithRightIconProps extends BaseProps {
  rightIcon?: React.ReactElement;
  rightIconTooltip?: string;
  hideRightIcon?: boolean;
}

type Props = XOR<WithRightIconProps, WithRightButtonIconProps>;

function TextField(
  {
    size = TextFieldSize.medium,
    onBlur,
    variant = TextFieldVariant.outlined,
    maxRows = 4,
    onClick,
    onFocus,
    onChange,
    multiline,
    onChangeText,
    // Icon without button
    rightIcon,
    hideRightIcon,
    rightIconTooltip,
    // Icon as a button
    rightButtonIcon,
    rightButtonTooltip,
    hideRightButtonIcon,
    onRightButtonIconClick,
    allowBrowserAutoComplete,
    name,
    ...otherProps
  }: Props,
  ref: React.ForwardedRef<HTMLInputElement>,
): React.ReactElement {
  let autoComplete: string | undefined;
  let endAdornment: React.ReactElement | undefined;

  if (!allowBrowserAutoComplete) {
    autoComplete = 'off';
  }

  const style: React.CSSProperties = {};

  const MUIInputProps =
    (otherProps as Parameters<typeof MuiTextField>[0] | null)?.InputProps || {};

  if (size === TextFieldSize.medium && variant === TextFieldVariant.standard) {
    style.paddingBottom = 10;
  }

  if (rightButtonIcon || rightIcon) {
    const rightAdornment = (
      <InputAdornment position="end">
        {rightButtonIcon ? (
          <IconButton edge="end" onClick={onRightButtonIconClick}>
            {rightButtonIcon}
          </IconButton>
        ) : (
          rightIcon
        )}
      </InputAdornment>
    );

    endAdornment = (
      <div
        className={classnames(styles.iconButton, {
          [styles.hiddenIconButton]: hideRightIcon || hideRightButtonIcon,
        })}
      >
        <Tooltip title={rightButtonTooltip || rightIconTooltip}>
          {rightAdornment}
        </Tooltip>
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.container}>
        <MuiTextField
          {...otherProps}
          name={name}
          ref={ref}
          size={size}
          onBlur={onBlur}
          onClick={onClick}
          onFocus={onFocus}
          maxRows={maxRows}
          variant={variant}
          multiline={multiline}
          InputProps={{
            style,
            autoComplete,
            ...MUIInputProps,
            endAdornment: endAdornment || MUIInputProps.endAdornment,
          }}
          onChange={(e) =>
            onChange
              ? onChange(e)
              : onChangeText && onChangeText(e.target.value)
          }
        />
      </div>
    </ThemeProvider>
  );
}

export default React.forwardRef(TextField);
