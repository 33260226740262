import {
  ChartColorsConfig,
  DEFAULT_BAR_CHART_COLORS,
} from '../../../../constants/chartTheme/theme';

interface Dot {
  x: number;
  y: number;
}

interface ProcessedLine {
  color: ChartColorsConfig;
  dots: Dot[];
}

export default function resolveChartData(
  unresolvedLines: { y: number | null }[][],
): ProcessedLine[] {
  const lines: ProcessedLine[] = [];

  unresolvedLines.forEach((unresolvedLine, lineIndex) => {
    const lineColor =
      DEFAULT_BAR_CHART_COLORS[lineIndex % DEFAULT_BAR_CHART_COLORS.length];

    const resolveCuts = (line: { y: number | null }[], xOffset: number) => {
      const getFragment = (indexToSlice: number): Dot[] =>
        line.slice(0, indexToSlice).map(({ y }, index) => ({
          y: y as number,
          x: index + xOffset,
        }));

      for (
        let lineFragmentIndex = 0;
        lineFragmentIndex < line.length;
        lineFragmentIndex++
      ) {
        const dot = line[lineFragmentIndex];

        const isLastDot = line.length - 1 === lineFragmentIndex;
        const doesDotHaveY = dot.y || dot.y === 0;

        if (isLastDot && doesDotHaveY) {
          lines.push({
            color: lineColor,
            dots: getFragment(lineFragmentIndex + 1),
          });
        }

        if (!doesDotHaveY) {
          lines.push({
            color: lineColor,
            dots: getFragment(lineFragmentIndex),
          });

          if (!isLastDot) {
            resolveCuts(
              line.slice(lineFragmentIndex + 1),
              lineFragmentIndex + xOffset + 1,
            );
            break;
          }
        }
      }
    };

    resolveCuts(unresolvedLine, 0);
  });

  return lines;
}
