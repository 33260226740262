import styles from './styles.module.scss';

import Button, {
  ButtonVariant,
} from '../../../../shared/components/core-ui/Button';
import Spacing from '../../../../shared/components/core-ui/Spacing';
import P from '../../../../shared/components/core-ui/text/P';
import Title from '../../../../shared/components/core-ui/text/Title';

export default function BaselineSurveyCard(): React.ReactElement {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <P>We introduce you</P>
        <Title>The one survey to rule them all</Title>

        <P>
          Know the actual state of your company based on this one time survey
        </P>

        <Spacing total={1} />
        <Button variant={ButtonVariant.secondary} onClick={() => {}}>
          Send
        </Button>
      </div>

      <div className={styles.design}>
        <svg viewBox="0 0 341 240" fill="none" preserveAspectRatio="none">
          <g clipPath="url(#clip0_387_12392)">
            <rect
              opacity="0.5"
              y="-29"
              width="341"
              height="239"
              rx="32"
              fill="#4888FA"
            />
            <rect
              opacity="0.5"
              x="170"
              y="108"
              width="341"
              height="239"
              rx="32"
              fill="#4888FA"
            />
          </g>
          <defs>
            <clipPath id="clip0_387_12392">
              <path
                d="M0 0H293C315.627 0 326.941 0 333.971 7.02944C341 14.0589 341 25.3726 341 48V192C341 214.627 341 225.941 333.971 232.971C326.941 240 315.627 240 293 240H0V0Z"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
}
