import SurveyQuestion from '../../../../types/surveys/question';

/**
 * It will assign a sortOrder prop to all questions, respecting only their position in the array,
 * and not their original sortOrder.
 * @param questions To set the correct sortOrder for.
 * @returns An array of questions with the same order but with the correct sortOrder prop.
 */
export default function getSortedQuestions(
  questions?: SurveyQuestion[],
): SurveyQuestion[] {
  const formatFollowUps = (followUps?: SurveyQuestion[]): SurveyQuestion[] => {
    return (
      followUps?.map((followUp, followUpIndex) => ({
        ...followUp,
        sortOrder: followUpIndex,
        followUps: followUp.followUps
          ? formatFollowUps(followUp.followUps)
          : [],
      })) || []
    );
  };

  return questions?.length ? formatFollowUps(questions) : [];
}
