const surveyTemplateTag: Record<string, string> = {
  Agile: 'Agile',
  Blockers: 'Blockers',
  TaskAchievability: 'Task Achievability',
  OutcomeSatisfaction: 'Outcome Satisfaction',
  Morale: 'Morale',
  CodingTime: 'Coding Time',
  CodeQuality: 'Code Quality',
  DevelopmentSpeed: 'Development Speed',
  LearningGoals: 'Learning Goals',
  OneOnOneMeetings: '1:1 Meetings',
  Retention: 'Retention',
  Workflow: 'Workflow',
  PRs: 'PRs',
  Communication: 'Communication',
  Roadmap: 'Roadmap',
  Productivity: 'Productivity',
  TestingTime: 'Testing Time',
  Trust: 'Trust',
  GrowthOpportunities: 'Growth Opportunities',
};

export default surveyTemplateTag;
