import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from '@apollo/client';

import getSession from '../helpers/auth/getSession';

export interface GenericResponseType {
  ok: boolean;
  error?: {
    code: string;
    message: string;
  };
}

const httpLink = new HttpLink({ uri: process.env.REACT_APP_BACKEND_URL });

const authLink = new ApolloLink((operation, forward) => {
  const session = getSession();

  let token;

  if (session) {
    const { idToken } = session;

    token = idToken;
  }

  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  });

  return forward(operation);
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
