import isNumber from 'lodash/isNumber';
import { AnimationItem, AnimationSegment } from 'lottie-web';

interface Args {
  lottiePlayerRef: React.MutableRefObject<AnimationItem | null>;
  playToPercentage?: number | null;
  lastPlayedToRef: React.MutableRefObject<number | undefined>;
  durationInFrames: number;
}

/**
 * Plays segments for the lottiePlayerRef
 * @param lottiePlayerRef - Lottie player ref. Example: { current: AnimationItem }
 * @param playToPercentage - Percentage of the animation to play to
 * @param lastPlayedToRef - Ref that brings the last frame that it played to, { current: number }
 * @param durationInFrames - Animation duration in frames
 */
export default function playSegmentsBasedOnPercentage({
  lottiePlayerRef,
  playToPercentage,
  lastPlayedToRef,
  durationInFrames,
}: Args): void {
  const lottiePlayer = lottiePlayerRef.current;

  if (isNumber(playToPercentage) && lottiePlayer) {
    lottiePlayer.loop = false;

    const change: AnimationSegment = [
      lastPlayedToRef.current ||
        Math.max(lottiePlayer.firstFrame, 0) + lottiePlayer.currentFrame,
      durationInFrames * (playToPercentage / 100),
    ];

    lastPlayedToRef.current = change[1];
    lottiePlayer.playSegments(change, true);
  } else if (!playToPercentage && !isNumber(playToPercentage)) {
    if (lottiePlayer) {
      lottiePlayer.loop = true;
      lottiePlayer.play();
    }
  }
}
