import styles from './styles.module.scss';

import P from '../../core-ui/text/P';
import Title from '../../core-ui/text/Title';

interface Props {
  title: string;
  onClick: () => void;
}

export default function TipCard({ title, onClick }: Props): React.ReactElement {
  return (
    <div className={styles.container} onClick={onClick} role="button">
      <div className={styles.content}>
        <P>Motivato recommends:</P>
        <Title>{title}</Title>
      </div>
    </div>
  );
}
