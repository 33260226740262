/* eslint-disable motivato/all-components-should-have-storybook-stories */
import moment from 'moment';
import { useEffect } from 'react';
import { useMemo, useState } from 'react';

import { FULL_CALENDAR_MONTH } from '../../../../../../shared/constants/schedulingEvents/monthDays';
import { WEEKDAYS } from '../../../../../../shared/constants/schedulingEvents/weekdays';
import {
  CustomFrequencyDay,
  CustomFrequencyEvery,
  PredefinedFrequency,
} from '../../../../../../shared/enums/schedulingEvents/frequency';
import createCronString from '../../../../../../shared/helpers/cronString/createCronString';
import getCronBreakdown from '../../../../../../shared/helpers/cronString/getCronBreakdown';
import useMinDatetime from '../../../../../../shared/hooks/useMinDatetime';

interface Args {
  cronString: string | null;
  startingDate: moment.Moment | null;
  onChange: (args: {
    startingDate: moment.Moment;
    cron: string | null;
  }) => void;
}

interface ReturnType {
  datetime: moment.Moment;
  onDatetimeChange: (newDatetime: moment.Moment) => void;
  repeatsConfig: {
    repeats: boolean;
    onRepeatsChange: (shouldRepeat: boolean) => void;
    frequency: PredefinedFrequency;
    onFrequencyChange: (frequency: PredefinedFrequency) => void;
    daysOfMonth: number[];
    onDaysOfMonthChange: (days: number[]) => void;
    weekFrequency: CustomFrequencyDay[];
    onWeekFrequencyChange: (days: CustomFrequencyDay[]) => void;
    customFrequency: CustomFrequencyEvery;
    onCustomFrequencyChange: (value: CustomFrequencyEvery) => void;
  };
}

export default function useFrequencyConfig({
  onChange,
  cronString,
  startingDate,
}: Args): ReturnType {
  const initialFrequencyConfig = useMemo(
    () => getCronBreakdown(cronString || '* * * * MON,TUE,WED,THU,FRI'),
    [],
  );

  const getNewMoment = (): moment.Moment => {
    if (startingDate) {
      return moment(startingDate).seconds(0);
    }

    return moment().seconds(0);
  };

  const [datetime, setDatetime] = useState<moment.Moment>(
    cronString
      ? getNewMoment()
          .hour(+initialFrequencyConfig.hour)
          .minute(+initialFrequencyConfig.minute)
      : getNewMoment(),
  );

  const [repeats, setRepeats] = useState(!!cronString);
  const [daysOfMonth, setDaysOfMonth] = useState<number[]>(
    initialFrequencyConfig.monthDay,
  );
  const [frequency, setFrequency] = useState<PredefinedFrequency>(
    PredefinedFrequency.Custom,
  );
  const [weekFrequency, setWeekFrequency] = useState<CustomFrequencyDay[]>(
    initialFrequencyConfig.weekDays,
  );
  const [customFrequency, setCustomFrequency] = useState<CustomFrequencyEvery>(
    initialFrequencyConfig.selectedFrequency,
  );

  useEffect(() => {
    onChange({
      startingDate: datetime,
      cron: createCronString({
        repeats,
        datetime,
        daysOfMonth,
        weekFrequency,
        customFrequency,
      }),
    });
  }, [
    repeats,
    datetime,
    daysOfMonth,
    frequency,
    weekFrequency,
    customFrequency,
  ]);

  useMinDatetime({
    datetime,
    onDatetimeUpdate: setDatetime,
  });

  return {
    datetime,
    onDatetimeChange: setDatetime,
    repeatsConfig: {
      repeats,
      onRepeatsChange: setRepeats,
      frequency,
      onFrequencyChange: setFrequency,
      daysOfMonth,
      onDaysOfMonthChange: (selectedDays: number[]) => {
        if (selectedDays.length) {
          setDaysOfMonth(selectedDays);
        } else {
          setDaysOfMonth([1, 15]);
        }
      },
      weekFrequency,
      customFrequency,
      onCustomFrequencyChange: (newCustomFrequency: CustomFrequencyEvery) => {
        if (newCustomFrequency === CustomFrequencyEvery.Week) {
          setDaysOfMonth(FULL_CALENDAR_MONTH);
          setWeekFrequency(WEEKDAYS);
        } else {
          setDaysOfMonth([1, 15]);
          setWeekFrequency(Object.values(CustomFrequencyDay));
        }

        setCustomFrequency(newCustomFrequency);
      },
      onWeekFrequencyChange: (days) =>
        setWeekFrequency(days.length ? days : WEEKDAYS),
    },
  };
}
