import { useSelector } from 'react-redux';

import { RootState } from '../../../redux/setup/rootReducer';

// Export a hook that can be reused to resolve types
export function useAppSelector<TSelected = unknown>(
  selector: (state: RootState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean,
): TSelected {
  return useSelector<RootState, TSelected>(selector, equalityFn);
}
