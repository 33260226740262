import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import fetchSurveyTemplates from '../../../../shared/networking/surveyTemplates/fetchSurveyTemplates';
import SurveyTemplate from '../../../../shared/types/surveys/template';

export const loadTemplates = createAsyncThunk(
  'templates/LOAD_TEMPLATES',
  async (): Promise<SurveyTemplate[]> => {
    const result = await fetchSurveyTemplates();

    return result.data?.templates || [];
  },
);

interface State {
  templates: SurveyTemplate[];
  templatesLoaded: boolean;
}

export const initialState: State = {
  templates: [],
  templatesLoaded: false,
};

export default createReducer<State>(initialState, (builder) => {
  builder.addCase(loadTemplates.fulfilled, (state, action) => {
    state.templates = action.payload;
    state.templatesLoaded = true;
  });
});
