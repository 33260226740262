import groupBy from 'lodash/groupBy';

import SurveyQuestion from '../../../types/surveys/question';
import SurveySection from '../../../types/surveys/section';

export function groupQuestionsBySection(
  questions: SurveyQuestion[],
): SurveySection[] {
  const groupedQuestions: { [key: string]: SurveyQuestion[] } = groupBy(
    questions,
    (question: SurveyQuestion) => question.sectionName,
  );

  return Object.entries(groupedQuestions).map(
    ([sectionName, questionsForSection]) => ({
      name: sectionName,
      questions: questionsForSection,
    }),
  );
}
