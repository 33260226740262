import { FetchResult } from '@apollo/client';

import { Team } from '../../types/teams';
import { GenericResponseType } from '../apolloClient';

interface ResponseType extends GenericResponseType {
  groups: Team[];
}

const queryName = 'Groups';
type Response = Record<typeof queryName, ResponseType>;

export default async function fetchTeams(): Promise<FetchResult<Response>> {
  return Promise.resolve({
    data: {
      Groups: {
        ok: true,
        id: 1,
        groups: [],
      },
    },
  });

  // TODO: Implement this query from the new schema in the Monolith instead.
  // return apolloClient.query<Response>({
  //   query: gql`
  //     {
  //       ${queryName}() {
  //         groups {
  //           id
  //           name
  //           users {
  //             id
  //             name
  //             email
  //             slackUserID
  //           }
  //         }
  //       }
  //     }
  //   `,
  // });
}
