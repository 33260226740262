import styles from './styles.module.scss';

import StatSummaryCard from '../../../shared/components/StatSummaryCard';
import VerticalQuickStat from '../../../shared/components/VerticalQuickStat';
import MainButton, {
  MainButtonVariant,
} from '../../../shared/components/core-ui/MainButton';
import Separator from '../../../shared/components/core-ui/Separator';
import Spacing from '../../../shared/components/core-ui/Spacing';
import LineChart from '../../../shared/components/core-ui/charts/LineChart';
import Title from '../../../shared/components/core-ui/text/Title';

export default function CompanyTrustAndBelonging(): React.ReactElement {
  return (
    <div>
      <Title>1. Summary</Title>
      <Spacing total={1} />

      <StatSummaryCard
        className={styles.card}
        mainNumber={65}
        description={`Your team feels that feedback is pretty honest,
          and they feel heard most of the time. However, they don't always feel comfortable
          asking for help. This means the fear of failure and judgment could be preventing people
          from collaborating more.`}
      >
        <LineChart
          lines={[
            [
              { y: 1, z1: 3, name: 'Fugiat' },
              { y: 5, z1: 2, name: 'Nostrud' },
              { y: 3, z1: 3, name: 'Deserunt' },
            ],
          ]}
        />
      </StatSummaryCard>

      <Separator spacing={5} />

      <Title>2. The Breakdown</Title>
      <Spacing total={1} />

      <div className={styles.verticalStatsContainer}>
        <VerticalQuickStat
          text="Low"
          title="Asking for help"
          number={35}
          difference={-10}
          headerClassName={styles.verticalStatHeader}
        />

        <VerticalQuickStat
          text="Medium"
          title="Feels heard"
          number={75}
          difference={12}
          headerClassName={styles.verticalStatHeader}
        />

        <VerticalQuickStat
          text="High"
          title="Honest feedback"
          number={85}
          difference={29}
          headerClassName={styles.verticalStatHeader}
        />
      </div>

      <Spacing total={4} />

      <StatSummaryCard
        kicker="Motivato recommends"
        title="Got 2 mins?"
        className={styles.card}
        description={`Think of 2 people that asked you for help recently.
          Now, send each of them a quick thank you message for coming to
          you and trusting you with it.  It'll make them feel more
          positively about the situation, and they'll be more likely to ask
          for help again in the future.`}
      >
        <div className={styles.buttonContainer}>
          <MainButton variant={MainButtonVariant.secondary} onClick={() => {}}>
            Leading with gratitude
          </MainButton>
        </div>
      </StatSummaryCard>
    </div>
  );
}
