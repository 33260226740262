import React from 'react';

import styleVariables from '../../styles/_exports.module.scss';

import { IconColor, IconSize } from '../../enums/icons';
import { CommonIconProps } from '../../types/iconsCommon';

interface ResolvedCustomIconsProps {
  width: string;
  color: string;
  style?: React.CSSProperties;
  height: string;
}

const CUSTOM_ICON_SIZES: { [key in keyof typeof IconSize]: string } = {
  [IconSize.small]: styleVariables.smallIconFontSize,
  [IconSize.large]: styleVariables.largeIconFontSize,
  [IconSize.medium]: styleVariables.mediumIconFontSize,
};

const CUSTOM_ICON_COLORS: { [key in keyof typeof IconColor]: string } = {
  [IconColor.error]: styleVariables.errorColor,
  [IconColor.default]: styleVariables.defaultIconColor,
  [IconColor.primary]: styleVariables.primaryColorOld,
  [IconColor.disabled]: styleVariables.defaultDisabledColor,
  [IconColor.secondary]: styleVariables.primaryColor,
};

export function resolveCommonCustomIconProps({
  size = IconSize.medium,
  color = IconColor.default,
}: CommonIconProps): ResolvedCustomIconsProps {
  const realSize = CUSTOM_ICON_SIZES[size];

  return {
    color: CUSTOM_ICON_COLORS[color],
    width: realSize,
    height: realSize,
  };
}
