import React from 'react';
import { useMemo } from 'react';

import styles from './styles.module.scss';

import Slider from '../../../../../../../shared/components/core-ui/Slider';
import getSliderMarkFromSegment, {
  SurveySliderMark,
} from '../../../../../../../shared/helpers/surveys/questions/slider/getSliderMarkFromSegment';
import LiveSurveyResponseProps from '../../../../../../../shared/types/surveys/liveSurveys/liveSurveys';
import {
  SurveySliderResponse,
  SliderSegmentConfig,
} from '../../../../../../../shared/types/surveys/questions/sliderResponse';

interface Props extends LiveSurveyResponseProps {
  questionConfig: SurveySliderResponse;
}

export default function LiveSurveySliderAnswer({
  value,
  onChange,
  renderFooter,
  questionConfig,
}: Props): React.ReactElement {
  const segments: SliderSegmentConfig[] = questionConfig.config?.segments || [];

  const marks: SurveySliderMark[] = useMemo(
    () => getSliderMarkFromSegment(segments),
    [segments],
  );

  const getSliderLabelFromMarkValue = (valueToFormat: number): string => {
    const foundMark = marks.find((mark) => mark.value === valueToFormat);

    return foundMark?.hint || '';
  };

  return (
    <>
      <div className={styles.container}>
        <Slider
          step={100 / (segments.length - 1)}
          marks={marks}
          value={value as number}
          valueLabelFormat={getSliderLabelFromMarkValue}
          onChangeCommitted={onChange}
          forceLabelToBeDisplayed
        />
      </div>

      {renderFooter({ buttonDisabled: true })}
    </>
  );
}
