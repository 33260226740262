import { useMediaQueries } from '@react-hook/media-query';

import styleVariables from '../../styles/_exports.module.scss';

interface ReturnValue {
  isMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
}

export default function useResponsive(): ReturnValue {
  const {
    matches: { isMobile, isTablet, isLaptop },
  } = useMediaQueries({
    isMobile: `screen and (max-width: ${styleVariables.mobileBreakpoint})`,
    isTablet: `screen and (max-width: ${styleVariables.tabletBreakpoint})`,
    isLaptop: `screen and (max-width: ${styleVariables.laptopBreakpoint})`,
  });

  return { isMobile, isTablet, isLaptop };
}
