import SurveyQuestion from '../../types/surveys/question';
import Survey from '../../types/surveys/survey';
import SurveyTemplate from '../../types/surveys/template';

// TODO: Add analytics event to log the template tags from which this survey is being
// created
export default function createSurveyFromTemplate(
  template: SurveyTemplate,
): Survey {
  const formatFollowUps = (followUps?: SurveyQuestion[]): SurveyQuestion[] => {
    return (
      followUps?.map((followUp, followUpIndex) => ({
        id: Math.random().toString(),
        name: followUp.name,
        parentId: followUp.parentId,
        question: followUp.question,
        isEnabled: true,
        sortOrder: followUp.sortOrder || followUpIndex,
        followUps: formatFollowUps(followUp.followUps),
        sectionName: followUp.sectionName,
        isAnonymous: false,
        globalQuestionId: followUp.globalQuestionId,
        animation: followUp.animation,
      })) || []
    );
  };

  return {
    name: template.name,
    isValid: true,
    isEnabled: true,
    questions: formatFollowUps(template.questions),
    templateId: template.id,
  };
}
