import classnames from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

import SurveyIconContainer from '../../../modules/SurveyBuilder/components/QuestionsBrowser/components/SurveyIconContainer';
import { ROUTES } from '../../../router';
import { IconColor, IconSize } from '../../enums/icons';
import surveyTemplateTag from '../../enums/surveys/surveyTemplateTag';
import SurveyTemplate from '../../types/surveys/template';
import IconButton from '../core-ui/IconButton';
import MainButton, { MainButtonVariant } from '../core-ui/MainButton';
import Spacing from '../core-ui/Spacing';
import CloseIcon from '../core-ui/icons/CloseIcon';
import P from '../core-ui/text/P';
import Title from '../core-ui/text/Title';

export enum SlackButtonSizeVariant {
  small = 'small',
  default = 'default',
  large = 'large',
}

interface Props {
  onClose: () => void;
  template: SurveyTemplate;
}

enum TemplateDialogTab {
  about,
  breakdown,
}

export default function TemplateDialog({
  onClose,
  template,
}: Props): React.ReactElement {
  const navigate = useNavigate();
  const [tabSelected, setTabSelected] = useState<TemplateDialogTab>(
    TemplateDialogTab.about,
  );

  const useTemplateHandler = () => {
    onClose();
    navigate(
      ROUTES.surveyBuilder.routes.fromTemplate.getRoute(template.id as string),
    );
  };

  return (
    <div className={styles.overlay} onClick={onClose}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <div className={styles.closeIconContainer}>
          <IconButton onClick={onClose}>
            <CloseIcon size={IconSize.medium} color={IconColor.disabled} />
          </IconButton>
        </div>

        <header className={styles.header}>
          <div className={styles.nameAndActions}>
            <Title>{template.name} template</Title>
            <Spacing total={2} />

            {template.tags?.length && (
              <div className={styles.tagsContainer}>
                {template.tags?.map((tag) => (
                  <div key={tag} className={styles.tag}>
                    <P noMargin>{surveyTemplateTag[tag] || tag}</P>
                  </div>
                ))}
              </div>
            )}

            <Spacing total={2} />
            <MainButton onClick={useTemplateHandler}>Use template</MainButton>
            <Spacing total={1} />

            <MainButton onClick={() => {}} variant={MainButtonVariant.tertiary}>
              Preview template
            </MainButton>
          </div>

          <div className={styles.thumbnailContainer}>
            <div
              style={{ backgroundImage: `url(${template.thumbnailUrl})` }}
              className={styles.thumbnail}
            />
          </div>
        </header>

        <div className={styles.tabs}>
          <div
            role="button"
            onClick={() => setTabSelected(TemplateDialogTab.about)}
            className={classnames(styles.tab, {
              [styles.selectedTab]: tabSelected === TemplateDialogTab.about,
            })}
          >
            <P>About</P>
          </div>

          <div
            role="button"
            onClick={() => setTabSelected(TemplateDialogTab.breakdown)}
            className={classnames(styles.tab, {
              [styles.selectedTab]: tabSelected === TemplateDialogTab.breakdown,
            })}
          >
            <P>Question lineup</P>
          </div>
        </div>

        <Spacing total={2} />

        <main>
          {tabSelected === TemplateDialogTab.about && (
            <>
              <Title>Description</Title>
              <P>{template.description}</P>

              <Spacing vertical={2} />

              <Title>Why use this?</Title>
              <P>{template.whyUseThis.text}</P>

              <Spacing vertical={2} />

              <Title>Source</Title>

              <P>
                See:
                <a
                  href={template.whyUseThis.sourceUrl}
                  style={{ marginLeft: 5 }}
                >
                  {template.whyUseThis.sourceName}
                </a>
              </P>
            </>
          )}

          {tabSelected === TemplateDialogTab.breakdown && (
            <>
              <Title>Question lineup</Title>

              {template.questions?.map((question) => (
                <div key={question.id} className={styles.question}>
                  <SurveyIconContainer questionType={question.question.type} />

                  <Spacing horizontal={1} />

                  <P>{question.name}</P>
                </div>
              ))}
            </>
          )}
        </main>
      </div>
    </div>
  );
}
