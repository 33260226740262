export default function ComplexTabAsset(): React.ReactElement {
  return (
    <svg
      viewBox="0 0 59 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <g filter="url(#filter0_d_53_1972)">
        <g clipPath="url(#clip0_53_1972)">
          <path
            d="M4 -60.2332L55.5967 -60.2332C77.4049 -60.2332 88.3089 -60.2332 95.8729 -54.5511C98.0087 -52.9466 99.907 -51.0484 101.511 -48.9126C107.193 -41.3486 107.193 -30.4446 107.193 -8.63642V-8.63642C107.193 13.1717 107.193 24.0758 101.511 31.6398C99.907 33.7756 98.0087 35.6738 95.8729 37.2782C88.3089 42.9603 77.4049 42.9603 55.5967 42.9603V42.9603C33.7886 42.9603 22.8845 42.9603 15.3205 37.2782C13.1848 35.6738 11.2865 33.7756 9.6821 31.6398C4 24.0758 4 13.1717 4 -8.6364L4 -60.2332Z"
            fill="white"
            fillOpacity="0.1"
          />
          <rect
            opacity="0.2"
            x="4"
            y="42.9603"
            width="103.193"
            height="103.193"
            rx="28.345"
            fill="black"
          />
        </g>
        <path
          d="M4.44289 0.44289H33.7413C38.7645 0.44289 42.4874 0.443831 45.3456 0.8281C48.191 1.21066 50.1129 1.96581 51.5736 3.42644C53.0342 4.88707 53.7893 6.80903 54.1719 9.65445C54.5562 12.5126 54.5571 16.2355 54.5571 21.2587V66.7413C54.5571 71.7645 54.5562 75.4874 54.1719 78.3456C53.7893 81.191 53.0342 83.1129 51.5736 84.5736C50.1129 86.0342 48.191 86.7893 45.3456 87.1719C42.4874 87.5562 38.7645 87.5571 33.7413 87.5571H4.44289V0.44289Z"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_53_1972"
          x="0.456876"
          y="0"
          width="58.0862"
          height="95.0862"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.54312" />
          <feGaussianBlur stdDeviation="1.77156" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_53_1972"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_53_1972"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_53_1972">
          <path
            d="M4 0H33.7413C43.7627 0 48.7735 0 51.8867 3.11327C55 6.22654 55 11.2373 55 21.2587V66.7413C55 76.7627 55 81.7735 51.8867 84.8867C48.7735 88 43.7627 88 33.7413 88H4V0Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
