import {
  createAction,
  createAsyncThunk,
  createReducer,
} from '@reduxjs/toolkit';

import { GlobalSearchItem } from '../../components/GlobalSearch';
import fetchGlobalSearch from '../../networking/search/searchGlobally';

export const searchGlobally = createAsyncThunk(
  'globalSearch/SEARCH_GLOBALLY',
  async ({
    searchTerms,
  }: {
    searchTerms: string;
  }): Promise<GlobalSearchItem[]> => {
    const result = fetchGlobalSearch({ searchTerms });

    return result;
  },
);

export const cleanGlobalSearch = createAction(
  'globalSearch/CLEAN_GLOBAL_SEARCH',
);

interface State {
  globalSearchLoading: boolean;
  globalSearchResults: GlobalSearchItem[];
}

export const initialState: State = {
  globalSearchResults: [],
  globalSearchLoading: false,
};

export default createReducer<State>(initialState, (builder) => {
  builder
    .addCase(cleanGlobalSearch, (state) => {
      state.globalSearchResults = [];
    })
    .addCase(searchGlobally.pending, (state) => {
      state.globalSearchLoading = true;
    })
    .addCase(searchGlobally.fulfilled, (state, action) => {
      state.globalSearchLoading = false;
      state.globalSearchResults = action.payload;
    });
});
