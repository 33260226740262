/* eslint-disable motivato/all-components-should-have-storybook-stories */
import ID from '../../../../../../shared/types/id';
import PossibleAudience from '../../../../../../shared/types/surveys/schedulingEvents/possibleAudience';

export default function getPossibleAudienceFlatIds({
  users,
  slackUsers,
  slackChannels,
}: PossibleAudience): ID[] {
  const ids: ID[] = [];

  const forEachCb = ({ id }: { id: ID }) => {
    ids.push(id);
  };

  users.forEach(forEachCb);
  slackUsers.forEach(forEachCb);
  slackChannels.forEach(forEachCb);

  return ids;
}
