import { gql, FetchResult } from '@apollo/client';

import ID from '../../types/id';
import LiveSurvey from '../../types/surveys/liveSurvey';
import { apolloClient } from '../apolloClient';

type ResponseType = LiveSurvey;

const queryName = 'liveSurvey';
type Response = Record<typeof queryName, ResponseType | null>;

interface Args {
  surveyCompletionId: ID;
}

export default function fetchLiveSurvey({
  surveyCompletionId,
}: Args): Promise<FetchResult<Response>> {
  return apolloClient.query<Response>({
    query: gql`
      query LiveSurvey($liveSurveyInput: GetLiveSurveyInput!) {
        liveSurvey(liveSurveyInput: $liveSurveyInput) {
          id
          questions {
            id
            name
            description
            sortOrder
            parentId
            question
            answer {
              id
            }
            animation {
              url
              shouldLoop
              animationPointScaleConfig
            }
          }
        }
      }
    `,
    variables: {
      liveSurveyInput: {
        surveyCompletionId,
      },
    },
  });
}
