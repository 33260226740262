import classnames from 'classnames';
import moment from 'moment';
import { useRef } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

import { ROUTES } from '../../../../router';
import Button, {
  ButtonVariant,
} from '../../../../shared/components/core-ui/Button';
import DatePickerTextField from '../../../../shared/components/core-ui/DatePickerTextField';
import Separator from '../../../../shared/components/core-ui/Separator';
import Spacing from '../../../../shared/components/core-ui/Spacing';
import { TextFieldSize } from '../../../../shared/components/core-ui/TextField';
import TimePickerTextField from '../../../../shared/components/core-ui/TimePickerTextField';
import Title from '../../../../shared/components/core-ui/text/Title';
import FrequencyConfig from '../../../../shared/types/surveys/schedulingEvents/frequencyConfig';
import SchedulingEventFrequencyPreview from '../SchedulingEventFrequencyPreview';
import SurveySchedulingEventRepeatsSection from '../SurveySchedulingEventRepeatsSection';
import useFrequencyConfig from './hooks/useFrequencyConfig';

interface Props {
  onSave: (args: FrequencyConfig) => void;
  cronString: string | null;
  saveDisabled: boolean;
  startingDate: moment.Moment | null;
}

export default function SurveySchedulingEventFrequencyConfig({
  onSave,
  cronString,
  saveDisabled,
  startingDate,
}: Props): React.ReactElement {
  const navigate = useNavigate();
  const scrollableMainRef = useRef<HTMLDivElement>(null);

  const [config, setConfig] = useState<FrequencyConfig>({
    cronString,
    startingDate,
  });

  const cancelHandler = () => {
    navigate(ROUTES.home.route);
  };

  const { datetime, repeatsConfig, onDatetimeChange } = useFrequencyConfig({
    cronString,
    startingDate,
    onChange: ({ cron, startingDate: newStartingDate }) => {
      setConfig({ cronString: cron, startingDate: newStartingDate });
    },
  });

  return (
    <div className={styles.container}>
      <main ref={scrollableMainRef} className={styles.content}>
        <Title>When to send</Title>
        <Separator spacing={2} />

        <div style={{ display: 'flex' }}>
          <DatePickerTextField
            label={repeatsConfig.repeats ? 'Start date' : 'Date'}
            size={TextFieldSize.small}
            value={datetime}
            onChange={(newTime) => onDatetimeChange(newTime || moment())}
          />

          <Spacing total={2} />

          <TimePickerTextField
            label="Time"
            size={TextFieldSize.small}
            value={datetime}
            onChange={(newTime) => {
              return onDatetimeChange(newTime || moment());
            }}
          />
        </div>

        <Separator spacing={2} />

        <SurveySchedulingEventRepeatsSection {...repeatsConfig} />
        <SchedulingEventFrequencyPreview cron={config.cronString} />
      </main>

      <footer className={classnames(styles.footer)}>
        <Button flex onClick={cancelHandler}>
          Cancel
        </Button>

        <Spacing horizontal={2} />

        <Button
          flex
          variant={ButtonVariant.primary}
          onClick={() => onSave(config)}
          disabled={saveDisabled}
        >
          Save
        </Button>
      </footer>
    </div>
  );
}
