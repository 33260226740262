import moment from 'moment';
import React from 'react';

import styles from './styles.module.scss';

import NoteFromUser from '../../../../shared/components/core-ui/NoteFromUser';
import BarChart from '../../../../shared/components/core-ui/charts/BarChart';
import PieChart from '../../../../shared/components/core-ui/charts/PieChart';
import { SurveyQuestionType } from '../../../../shared/enums/surveys/questionType';
import QuestionWithResponse from '../../../../shared/types/surveys/questionResponse';
import { SurveyBooleanResponse } from '../../../../shared/types/surveys/questions/booleanResponse';
import RoundOfResponsesCardSummary from '../RoundOfResponsesCardSummary';

interface Props {
  questionIndex: number;
  questionResponses: QuestionWithResponse;
}

export default function QuestionResponsesCard({
  questionIndex,
  questionResponses,
}: Props): React.ReactElement {
  const getBooleanText = (bool: boolean): string | null => {
    const booleanConfig = (questionResponses.config as SurveyBooleanResponse)
      .config;

    if (bool) {
      return booleanConfig.yes?.text || 'Yes';
    }

    return booleanConfig.no?.text || 'No';
  };

  return (
    <div className={styles.container}>
      <div className={styles.summaryContainer}>
        <RoundOfResponsesCardSummary
          question={questionResponses}
          questionIndex={questionIndex}
          audienceReached={{
            amountWhoResponded: questionResponses.totalUsers,
            amountWhoReceivedIt: questionResponses.totalResponses,
          }}
        />
      </div>

      <div className={styles.chartContainer}>
        {questionResponses.type === SurveyQuestionType.Slider && (
          <BarChart
            data={questionResponses.responses.map(
              ({ amountOfAnswers, sliderSegmentName }) => ({
                y: amountOfAnswers,
                x: sliderSegmentName as string,
              }),
            )}
          />
        )}

        {questionResponses.type === SurveyQuestionType.Boolean && (
          <PieChart
            slices={questionResponses.responses.map(
              ({ amountOfAnswers, booleanResponse }) => ({
                value: amountOfAnswers,
                label: getBooleanText(booleanResponse as boolean) || '',
              }),
            )}
          />
        )}

        {questionResponses.type === SurveyQuestionType.Text && (
          <div className={styles.notesContainer}>
            {questionResponses.responses.map(
              (note) =>
                note.textResponse && (
                  <NoteFromUser
                    key={`${note.textResponse}-${note.user?.id}`}
                    text={note.textResponse}
                    date={moment()}
                  />
                ),
            )}
          </div>
        )}

        {questionResponses.type === SurveyQuestionType.List && (
          <div className={styles.notesContainer}>
            {questionResponses.responses.map(
              (note) =>
                note.listResponses && (
                  <NoteFromUser
                    key={`${note.listResponses}-${note.user?.id}`}
                    text={note.listResponses}
                    date={moment()}
                  />
                ),
            )}
          </div>
        )}
      </div>
    </div>
  );
}
