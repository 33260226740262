/* eslint-disable motivato/all-components-should-have-storybook-stories */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useAppSelector } from '../../../../shared/hooks/redux/useAppSelector';
import { loadRoundOfResponses } from '../../redux/roundOfResponses';
import RoundOfResponsesLayout from '../RoundOfResponsesLayout';

export default function RoundOfResponses(): React.ReactElement {
  const { surveyId } = useParams();

  const dispatch = useDispatch();

  const { roundOfResponsesData } = useAppSelector(({ roundOfResponses }) => ({
    roundOfResponsesData: roundOfResponses.data,
  }));

  useEffect(() => {
    if (surveyId) {
      dispatch(loadRoundOfResponses({ surveyId }));
    }
  }, [surveyId]);

  return <RoundOfResponsesLayout roundOfResponses={roundOfResponsesData} />;
}
