import styles from './styles.module.scss';

import Spacing from '../../../../../../shared/components/core-ui/Spacing';
import TextField, {
  TextFieldSize,
} from '../../../../../../shared/components/core-ui/TextField';
import {
  SurveyBooleanResponse,
  BooleanConfig,
} from '../../../../../../shared/types/surveys/questions/booleanResponse';

interface Props {
  questionConfig: SurveyBooleanResponse;
  onConfigChange: (questionConfig: SurveyBooleanResponse) => void;
}

export default function SurveyBooleanConfig({
  questionConfig,
  onConfigChange,
}: Props): React.ReactElement {
  const changeHandler = (newConfig: SurveyBooleanResponse['config']) => {
    onConfigChange({
      ...questionConfig,
      config: {
        ...questionConfig.config,
        ...newConfig,
      },
    });
  };

  const renderSetting = ({
    config,
    onChange,
    nameTextFieldLabel,
  }: {
    config?: BooleanConfig;
    onChange: (newConfig: BooleanConfig) => void;
    nameTextFieldLabel: string;
  }): React.ReactElement => (
    <>
      <TextField
        size={TextFieldSize.small}
        label={nameTextFieldLabel}
        value={config?.text || ''}
        onChangeText={(text) =>
          onChange({
            ...config,
            text,
          })
        }
      />

      <Spacing vertical={2} />
    </>
  );

  return (
    <div className={styles.container}>
      {renderSetting({
        config: questionConfig.config.yes,
        nameTextFieldLabel: 'Positive answer text',
        onChange: (newConfig: BooleanConfig) =>
          changeHandler({
            yes: newConfig,
          }),
      })}

      {renderSetting({
        config: questionConfig.config.no,
        nameTextFieldLabel: 'Negative answer text',
        onChange: (newConfig: BooleanConfig) =>
          changeHandler({
            no: newConfig,
          }),
      })}
    </div>
  );
}
