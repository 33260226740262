import styles from './styles.module.scss';

import Spacing from '../../../../../../shared/components/core-ui/Spacing';
import Label from '../../../../../../shared/components/core-ui/text/Label';
import P from '../../../../../../shared/components/core-ui/text/P';

interface Props {
  icon?: React.ReactElement;
  label: string;
  textFirstLine: string;
  textSecondLine: string;
}

export default function SurveyCardInfoArticle({
  icon,
  label,
  textFirstLine,
  textSecondLine,
}: Props): React.ReactElement {
  return (
    <article className={styles.info}>
      {icon && <div className={styles.iconContainer}>{icon}</div>}

      <div className={styles.infoMainText}>
        <P center noMargin>
          {textFirstLine}
        </P>

        <Spacing total={0.5} />

        <P center noMargin>
          {textSecondLine}
        </P>
      </div>

      <Label center>{label}</Label>
    </article>
  );
}
