import MuiCircularProgress from '@mui/material/CircularProgress';
import React from 'react';

function Spinner(
  __: Record<string, never>,
  ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
  return <MuiCircularProgress ref={ref} />;
}

export default React.forwardRef(Spinner);
