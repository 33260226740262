import cloneDeep from 'lodash/cloneDeep';
import { useState, createRef, useMemo } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import styles from './styles.module.scss';

import ContainerWithAnimation from '../../../../shared/components/ContainerWithAnimation';
import LottieAnimation from '../../../../shared/components/animations/LottieAnimation';
import getAnimationPercentageFromResponse from '../../../../shared/helpers/surveys/questions/getAnimationPercentageFromResponse';
import AnimationJSON from '../../../../shared/types/animationJSON';
import ID from '../../../../shared/types/id';
import { LiveSurveyQuestion } from '../../../../shared/types/surveys/liveSurvey';
import { LiveSurveyResponseValue } from '../../../../shared/types/surveys/liveSurveys/liveSurveys';
import SurveyQuestion from '../../../../shared/types/surveys/question';
import LiveSurveyScreenMain from './components/LiveSurveyScreenMain';

interface Props {
  question: LiveSurveyQuestion | SurveyQuestion;
  isEditing?: boolean;
  onContinue: () => void;
  animationJSON?: AnimationJSON;
}

export default function LiveSurveyScreen({
  question,
  isEditing,
  onContinue,
  animationJSON,
}: Props): React.ReactElement {
  // We need to pass a ref to nodeRef in order to suppress
  // a findDOMNode warning from CSSTransition:
  //   https://github.com/reactjs/react-transition-group/issues/668#issuecomment-695162879
  const nodeRef = createRef<HTMLDivElement>();
  const [responses, setResponses] = useState<
    Record<ID, LiveSurveyResponseValue | null>
  >({});
  const memoizedAnimation = useMemo(
    () => cloneDeep(animationJSON),
    [animationJSON],
  );

  const response = responses[question.id];

  return (
    <ContainerWithAnimation
      addLogo={false}
      animation={
        animationJSON ? (
          <LottieAnimation
            key={question.id}
            animationData={memoizedAnimation}
            playToPercentage={getAnimationPercentageFromResponse(
              question,
              response,
            )}
          />
        ) : null
      }
      animationContainerClassName={styles.animationContainer}
    >
      <TransitionGroup className={styles.contentContainer}>
        <CSSTransition
          key={question.id}
          appear
          timeout={300}
          nodeRef={nodeRef}
          classNames={{
            appear: styles.appear,
            appearDone: styles.appearDone,
            appearActive: styles.appearActive,
            enterActive: styles.contentEnter,
            enterDone: styles.contentEnterDone,
            exitActive: styles.contentExit,
            exitDone: styles.contentExitActive,
          }}
        >
          <div ref={nodeRef} key={question.id} className={styles.content}>
            <LiveSurveyScreenMain
              value={response}
              onChange={(val) =>
                setResponses({ ...responses, [question.id]: val })
              }
              question={question}
              isEditing={isEditing}
              onContinue={onContinue}
            />
          </div>
        </CSSTransition>
      </TransitionGroup>
    </ContainerWithAnimation>
  );
}
