/* eslint-disable motivato/default-export-name-and-path-should-match */

/* eslint-disable motivato/all-components-should-have-storybook-stories */
import React from 'react';

export interface State {
  isLoggedIn: boolean;
}

const defaultState = {
  isLoggedIn: false,
};

const AuthContext = React.createContext<State>(defaultState);

export default AuthContext;
