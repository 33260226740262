import classnames from 'classnames';

import styles from './styles.module.scss';

import Highlight from '../core-ui/text/Highlight';
import P from '../core-ui/text/P';

// Must be kept in the right order, as we assume these are numbers.
export enum LogoSize {
  small,
  medium,
  large,
}

interface Props {
  size?: LogoSize;
  hideName?: boolean;
}

export default function Logo({
  size = LogoSize.small,
  hideName,
}: Props): React.ReactElement {
  return (
    <div
      className={classnames(styles.container, {
        [styles.smallLogo]: size === LogoSize.small,
        [styles.mediumLogo]: size === LogoSize.medium,
        [styles.largeLogo]: size === LogoSize.large,
      })}
    >
      <svg
        width="189"
        height="55"
        viewBox="0 0 189 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28.2633 10.8287C25.0805 10.8287 22.9586 12.3993 21.2187 15.1159C20.3699 12.4417 18.0783 10.8287 14.7682 10.8287C11.7552 10.8287 9.71816 12.187 8.0631 14.5641V11.2532H0V32.9013H8.0631V19.87C8.99672 18.639 9.88791 18.0448 11.1186 18.0448C12.6888 18.0448 13.4951 18.9786 13.4951 20.7189V32.9013H21.5582V19.9125C22.5342 18.639 23.4254 18.0448 24.6137 18.0448C26.1839 18.0448 26.9902 18.9786 26.9902 20.7189V32.9013H35.0533V17.5354C35.0533 13.418 32.4646 10.8287 28.2633 10.8287Z"
          fill="#4888FA"
        />
        <path
          d="M83.5631 17.4474V11.2495H78.0875V5.47618H70.0226V11.2495H67.1787V17.4474H70.0226V25.0886C70.0226 30.1827 72.9515 33.3241 78.7242 33.3241C80.5494 33.3241 81.5257 33.2391 83.0538 32.7722V25.9376C81.6106 26.3621 80.6767 26.447 80.2098 26.447C78.5544 26.447 78.0875 25.6404 78.0875 23.5603V17.4474H83.5631Z"
          fill="#FBFCFC"
        />
        <path
          d="M90.907 8.95717C93.4963 8.95717 95.4488 7.00442 95.4488 4.45736C95.4488 1.9103 93.4963 0 90.907 0C88.3178 0 86.3653 1.9103 86.3653 4.45736C86.3653 7.00442 88.3178 8.95717 90.907 8.95717ZM86.8746 32.8995H94.9395V11.2495H86.8746V32.8995Z"
          fill="#FBFCFC"
        />
        <path
          d="M113.401 11.2495L110.006 25.5555L106.78 11.2495H97.8235L105.379 32.8995H114.802L122.358 11.2495H113.401Z"
          fill="#FBFCFC"
        />
        <path
          d="M134.468 10.825C133.407 10.825 129.757 10.7401 125.3 12.0561V18.8907C129.035 16.6408 131.497 16.5984 132.219 16.5984C135.275 16.5984 136.209 18.2115 136.209 19.9944V21.268C134.808 19.7397 132.898 19.1879 130.563 19.1879C126.106 19.1879 122.838 22.2019 122.838 26.2347C122.838 30.2251 126.064 33.3241 130.393 33.3241C132.94 33.3241 134.893 32.3477 136.209 30.7345V32.8995H144.273V19.6548C144.273 14.0513 140.326 10.825 134.468 10.825ZM133.195 28.612C131.709 28.612 130.606 27.5932 130.606 26.2772C130.606 24.9187 131.752 23.8575 133.237 23.8575C134.341 23.8575 135.232 24.282 136.209 25.4706V26.9988C135.147 28.1875 134.341 28.612 133.195 28.612Z"
          fill="#FBFCFC"
        />
        <path
          d="M163.107 17.4474V11.2495H157.632V5.47618H149.567V11.2495H146.723V17.4474H149.567V25.0886C149.567 30.1827 152.496 33.3241 158.269 33.3241C160.094 33.3241 161.07 33.2391 162.598 32.7722V25.9376C161.155 26.3621 160.221 26.447 159.754 26.447C158.099 26.447 157.632 25.6404 157.632 23.5603V17.4474H163.107Z"
          fill="#FBFCFC"
        />
        <path
          d="M177.073 33.3241C183.865 33.3241 189.001 28.3997 189.001 21.8198C189.001 15.5371 183.865 10.8675 177.073 10.8675C170.282 10.8675 165.146 15.5371 165.146 21.8198C165.146 28.3997 170.282 33.3241 177.073 33.3241ZM177.073 26.9139C174.738 26.9139 173.041 24.9612 173.041 21.9896C173.041 19.1454 174.738 17.2776 177.073 17.2776C179.408 17.2776 181.105 19.1454 181.105 21.9896C181.105 24.9612 179.408 26.9139 177.073 26.9139Z"
          fill="#FBFCFC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.2154 39.1651C51.215 39.1655 51.2145 39.1661 53.0717 40.9514C54.9289 42.7367 54.9283 42.7373 54.9277 42.738L54.9263 42.7395L54.9227 42.7432C54.9212 42.7447 54.9196 42.7464 54.9179 42.7482C54.9162 42.7499 54.9144 42.7517 54.9125 42.7537C54.9044 42.7621 54.8937 42.773 54.8805 42.7865C54.854 42.8135 54.8173 42.8507 54.7706 42.8974C54.6771 42.9908 54.5436 43.1223 54.3719 43.2865C54.0286 43.6148 53.532 44.0745 52.8978 44.6219C51.6318 45.7146 49.805 47.1674 47.5449 48.622C43.0671 51.504 36.6664 54.5359 29.4428 54.5359C22.2193 54.5359 15.8186 51.504 11.3407 48.622C9.08067 47.1674 7.25384 45.7146 5.98784 44.6219C5.35367 44.0745 4.85702 43.6148 4.51378 43.2865C4.34208 43.1223 4.20851 42.9908 4.11507 42.8974C4.06834 42.8507 4.03162 42.8135 4.00515 42.7865C3.99193 42.773 3.98126 42.7621 3.97318 42.7537C3.96914 42.7496 3.96575 42.7461 3.96301 42.7432L3.95794 42.738C3.95732 42.7373 3.95675 42.7367 5.81396 40.9514C7.67117 39.1661 7.67067 39.1655 7.67022 39.1651L7.67018 39.165C7.67237 39.1673 7.67707 39.1721 7.68426 39.1794C7.69862 39.1941 7.72289 39.2187 7.75683 39.2526C7.82473 39.3205 7.93119 39.4255 8.07425 39.5623C8.36052 39.836 8.79231 40.2363 9.35383 40.7209C10.4792 41.6923 12.1127 42.9915 14.1287 44.2889C18.2028 46.911 23.6165 49.3831 29.4428 49.3831C35.2691 49.3831 40.6829 46.911 44.757 44.2889C46.7729 42.9915 48.4065 41.6923 49.5318 40.7209C50.0933 40.2363 50.5251 39.836 50.8114 39.5623C50.9545 39.4255 51.0609 39.3205 51.1288 39.2526C51.1628 39.2187 51.187 39.1941 51.2014 39.1794C51.2086 39.1721 51.2133 39.1673 51.2155 39.165L51.2154 39.1651Z"
          fill="#4888FA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.9498 33.8851C58.4546 33.8851 63.7277 28.6102 63.7277 22.1033C63.7277 15.5964 58.4546 10.3215 51.9498 10.3215C45.445 10.3215 40.1719 15.5964 40.1719 22.1033C40.1719 28.6102 45.445 33.8851 51.9498 33.8851ZM52.3107 30.5804C55.7084 30.5804 58.4629 27.8259 58.4629 24.4282C58.4629 21.0304 55.7084 18.276 52.3107 18.276C48.9129 18.276 46.1585 21.0304 46.1585 24.4282C46.1585 27.8259 48.9129 30.5804 52.3107 30.5804Z"
          fill="#4888FA"
        />
      </svg>

      {!hideName && (
        <div className={styles.textContainer}>
          <P uppercase>
            <Highlight bold>Motivato</Highlight>
          </P>
        </div>
      )}
    </div>
  );
}
