import classnames from 'classnames';
import debounce from 'lodash/debounce';
import { useState, createRef, useEffect, useCallback } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { Key } from 'ts-key-enum';

import styles from './styles.module.scss';

import notificationBellIcon from '../../../assets/images/icons/notification-bell.svg';
import navBarLogo from '../../../assets/images/logos/nav-bar-logo.svg';
import blueGuyMascot from '../../../assets/images/mascots/blue-guy.svg';
import { logoutUser } from '../../../modules/Auth/redux/auth';
import { ROUTES } from '../../../router';
import {
  DEBOUNCE_TIME_MS,
  GLOBAL_SEARCH_SHORTCUT_META_PLUS_KEY_CODE,
  MIN_AMOUNT_OF_CHARS_TO_SEARCH,
} from '../../constants/search/config';
import GlobalSearchItemType from '../../enums/globalSearch/globalSearchItemType';
import { useAppDispatch } from '../../hooks/redux/useAppDispatch';
import { useAppSelector } from '../../hooks/redux/useAppSelector';
import { cleanGlobalSearch, searchGlobally } from '../../redux/globalSearch';
import GlobalSearch, { GlobalSearchItem } from '../GlobalSearch';
import Menu from '../core-ui/Menu';
import MenuItem from '../core-ui/MenuItem';

export default function AppLayout(): React.ReactElement {
  const [isSearchOnFocus, setIsSearchOnFocus] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const globalSearchRef = createRef<HTMLInputElement>();
  const search = useCallback(
    debounce((searchTerms: string) => {
      const terms = searchTerms.trim();

      if (terms.length >= MIN_AMOUNT_OF_CHARS_TO_SEARCH) {
        dispatch(searchGlobally({ searchTerms: terms }));
      }
    }, DEBOUNCE_TIME_MS),
    [],
  );

  const { globalSearchResults, globalSearchLoading } = useAppSelector(
    ({ globalSearch }) => ({
      globalSearchResults: globalSearch.globalSearchResults,
      globalSearchLoading: globalSearch.globalSearchLoading,
    }),
  );

  const resultFoundHandler = (item: GlobalSearchItem | null) => {
    if (item?.item.id) {
      if (item.type === GlobalSearchItemType.Survey) {
        navigate(ROUTES.surveyBuilder.getRoute(item.item.id));
      } else if (item.type === GlobalSearchItemType.Template) {
        navigate(
          ROUTES.surveyBuilder.routes.fromTemplate.getRoute(item.item.id),
        );
      } else if (item.type === GlobalSearchItemType.User) {
        navigate(ROUTES.teammateProfile.getRoute(item.item.id));
      }
    }

    dispatch(cleanGlobalSearch());
  };

  const searchBlurHandler = () => {
    setIsSearchOnFocus(false);
    dispatch(cleanGlobalSearch());
  };

  const closeHandler = () => setAnchorEl(null);
  const openHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    function keyDownHandler(e: KeyboardEvent) {
      const searchInput =
        globalSearchRef.current?.getElementsByTagName('input')[0];

      if (searchInput) {
        if (
          e.metaKey &&
          e.code === GLOBAL_SEARCH_SHORTCUT_META_PLUS_KEY_CODE &&
          globalSearchRef.current
        ) {
          e?.preventDefault();
          searchInput.focus();
        } else if (e.code === Key.Escape) {
          e?.preventDefault();
          searchInput.blur();
        }
      }
    }

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [globalSearchRef]);

  const onLogoutHandler = () => {
    dispatch(logoutUser());
    closeHandler();
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.navContent}>
          <div className={styles.logoContainer}>
            <img src={navBarLogo} />
          </div>

          <div className={styles.navigationLinks}>
            <NavLink
              to="/"
              className={({ isActive }) =>
                `${styles.navigationLink} ${isActive && styles.isSelected}`
              }
            >
              Home
            </NavLink>
            <NavLink
              to="/teammates"
              className={({ isActive }) =>
                `${styles.navigationLink} ${isActive && styles.isSelected}`
              }
            >
              Teammates
            </NavLink>
            <NavLink
              to="/company-insights"
              className={({ isActive }) =>
                `${styles.navigationLink} ${isActive && styles.isSelected}`
              }
            >
              Company Insights
            </NavLink>
          </div>

          <div className={styles.searchContainer}>
            <GlobalSearch
              ref={globalSearchRef}
              onFind={resultFoundHandler}
              onBlur={searchBlurHandler}
              loading={globalSearchLoading}
              options={globalSearchResults}
              onFocus={() => setIsSearchOnFocus(true)}
              onSearchTermsChange={search}
            />
          </div>

          <div className={styles.notificationBellContainer}>
            <img src={notificationBellIcon} />
          </div>

          <div className={styles.profileContainer}>
            <img src={blueGuyMascot} onClick={openHandler} />
          </div>

          <Menu element={anchorEl} onCloseHandler={closeHandler}>
            <MenuItem option="Logout" onOptionSelected={onLogoutHandler} />
          </Menu>
        </div>
      </header>

      <div
        className={classnames(styles.outletContainer, {
          [styles.blurredContainer]: isSearchOnFocus,
        })}
      >
        <Outlet />
      </div>
    </div>
  );
}
