import React from 'react';

import styles from './styles.module.scss';

import Spacing from '../../../../../../shared/components/core-ui/Spacing';
import TextField, {
  TextFieldSize,
} from '../../../../../../shared/components/core-ui/TextField';
import {
  SurveySliderResponse,
  SliderSegmentConfig,
} from '../../../../../../shared/types/surveys/questions/sliderResponse';

interface Props {
  questionConfig: SurveySliderResponse;
  onConfigChange: (questionConfig: SurveySliderResponse) => void;
}

export default function SurveySliderConfig({
  questionConfig,
  onConfigChange,
}: Props): React.ReactElement {
  const segments: SliderSegmentConfig[] = questionConfig.config?.segments || [
    {},
    {},
  ];

  const segmentChangeHandler = (
    segmentPatch: SliderSegmentConfig,
    index: number,
  ) => {
    const newSegments = [...segments];
    newSegments[index] = { ...segments[index], ...segmentPatch };

    onConfigChange({
      ...questionConfig,
      config: {
        ...questionConfig.config,
        segments: newSegments,
      },
    });
  };

  return (
    <div className={styles.container}>
      {segments.map((segment, index) => (
        <React.Fragment key={index}>
          <TextField
            key={index}
            size={TextFieldSize.small}
            label={`slider mark ${index + 1}`}
            value={segment.name || ''}
            onChangeText={(text) =>
              segmentChangeHandler({ ...segment, name: text }, index)
            }
          />

          <Spacing vertical={2} />
        </React.Fragment>
      ))}
    </div>
  );
}
