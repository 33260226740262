/* eslint-disable motivato/all-components-should-have-storybook-stories */
import createCache from '@emotion/cache';
import { CacheProvider, EmotionCache } from '@emotion/core';
// eslint-disable-next-line motivato/mui-should-not-be-imported-outside-of-core-ui
import { ThemeProvider } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import React, { useLayoutEffect, useMemo, useRef } from 'react';
import Frame from 'react-frame-component';

import generateBasicHtml from '../../helpers/codeGeneration/generateBasicHtml';
import generateTheme from '../../helpers/mui/theme';
import useForceUpdate from '../../hooks/useForceUpdate';

const defaultTheme = generateTheme();

interface Props {
  style: React.CSSProperties;
  children: React.ReactElement;
  className: string;
}

export default function IFrame({
  className,
  children,
  style,
  ...props
}: Props): React.ReactElement {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const forceUpdate = useForceUpdate();

  // We're caching the iframe's <head />.
  const memoizedCreateCacheWithContainer = useMemo(
    () => (container: HTMLElement) => {
      return createCache({ key: 'cache', container });
    },
    [],
  );

  useLayoutEffect(() => {
    let intervalHandle: NodeJS.Timer;

    if (iframeRef.current) {
      let timesRenderedWasForced = 0;

      // We must forceUpdate on the content of the iFrame several times so it will use
      // Emotion ThemeProvider as close to the onLoad as possible.
      // (Crazy hackish solution)
      intervalHandle = setInterval(() => {
        forceUpdate();
        timesRenderedWasForced += 1;

        // We forceUpdate several times so we ensure that the browser caught on the
        // correct theme provider.
        if (timesRenderedWasForced >= 5) {
          clearInterval(intervalHandle);
        }
      }, 1000);
    }

    return () => {
      clearInterval(intervalHandle);
    };
  }, []);

  return (
    <Frame
      {...props}
      ref={iframeRef}
      style={style}
      className={className}
      initialContent={generateBasicHtml({
        headInnerHtml: document.head.innerHTML,
      })}
    >
      {(
        <CacheProvider
          value={
            memoizedCreateCacheWithContainer(
              iframeRef.current?.contentDocument?.head || document.head,
            ) as unknown as EmotionCache
          }
        >
          <EmotionThemeProvider theme={defaultTheme}>
            <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
          </EmotionThemeProvider>
        </CacheProvider>
      ) || <div />}
    </Frame>
  );
}
