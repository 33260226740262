/* eslint-disable motivato/all-components-should-have-storybook-stories */
import { useState } from 'react';

import TemplateDialog from '../../shared/components/TemplateDialog';
import { useAppSelector } from '../../shared/hooks/redux/useAppSelector';
import SurveyTemplate from '../../shared/types/surveys/template';
import HomeForFirstTimeUserLayout from './components/HomeForFirstTimeUserLayout';

export default function HomeForFirstTimeUser(): React.ReactElement {
  const [selectedTemplate, setSelectedTemplate] =
    useState<SurveyTemplate | null>(null);

  const { surveyTemplates } = useAppSelector(({ home }) => ({
    surveyTemplates: home.surveyTemplates,
  }));

  return (
    <>
      <HomeForFirstTimeUserLayout
        surveyTemplates={surveyTemplates}
        onTemplateClick={(template) =>
          template.id && setSelectedTemplate(template)
        }
      />

      {selectedTemplate && (
        <TemplateDialog
          onClose={() => setSelectedTemplate(null)}
          template={selectedTemplate}
        />
      )}
    </>
  );
}
