import styles from './styles.module.scss';

import SurveyQuestion from '../../../../../../shared/types/surveys/question';
import QuestionRow from '../QuestionRow';

type QuestionRowProps = Parameters<typeof QuestionRow>[0];

interface Props extends Omit<QuestionRowProps, 'isSelected'> {
  selectedQuestion?: SurveyQuestion | null;
  onDelete: (question: SurveyQuestion) => void;
  question: QuestionRowProps['question'] & {
    followUps?: SurveyQuestion['followUps'];
  };
}

export default function NestableQuestionRow({
  onDelete,
  selectedQuestion,
  ...questionRowProps
}: Props): React.ReactElement {
  const { question } = questionRowProps;

  const getPropsForFollowUp = (
    followUpQuestion: SurveyQuestion,
  ): QuestionRowProps => ({
    ...questionRowProps,
    question: followUpQuestion,
  });

  return (
    <div>
      <QuestionRow
        {...questionRowProps}
        onDelete={onDelete}
        isSelected={selectedQuestion?.id === question.id}
        question={
          selectedQuestion?.id === question.id ? selectedQuestion : question
        }
      />

      <div className={styles.followUpsContainer}>
        {question?.followUps?.map((followUpQuestion, i) => (
          <NestableQuestionRow
            key={i}
            onDelete={onDelete}
            selectedQuestion={selectedQuestion}
            {...getPropsForFollowUp(followUpQuestion)}
          />
        ))}
      </div>
    </div>
  );
}
