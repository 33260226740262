/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-unused-vars */
import { FetchResult } from '@apollo/client';

import SlackChannel from '../../../types/slack/slackChannel';
import SlackUser from '../../../types/slack/slackUsers';
import { GenericResponseType } from '../../apolloClient';

interface ResponseType extends GenericResponseType {
  slackTeam: {
    users: SlackUser[];
    channels: SlackChannel[];
  };
}

// TODO: use the proper query "slackChannels"
const queryName = 'SlackTeam';
type Response = Record<typeof queryName, ResponseType>;
type ReturnType = FetchResult<Response>;

export default async function fetchUsersAndChannels(): Promise<ReturnType> {
  // TODO: Implement this query from the new schema in the Monolith instead.

  return Promise.resolve({
    data: {
      SlackTeam: {
        ok: true,
        slackTeam: {
          users: [],
          channels: [],
        },
      },
    },
  });
  // return apolloClient.mutate<Response>({
  //   mutation: gql`
  //     {
  //       ${queryName}() {
  //         ok
  //         error {
  //           message
  //         }
  //         slackTeam {
  //           users {
  //             id
  //             name
  //             email
  //             profile {
  //               Image24
  //               Image32
  //               Image48
  //               Image72
  //               Image192
  //               Image512
  //               Image1024
  //             }
  //           }
  //           channels {
  //             id
  //             name
  //             isOrgDefault
  //             users
  //           }
  //         }
  //       }
  //     }
  //   `,
  // });
}
