import { surveyBuilderSpinnerContainer } from '@packages/test-ids/surveyBuilder';
import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import styles from './styles.module.scss';

import { ROUTES } from '../../../../router';
import Spinner from '../../../../shared/components/core-ui/Spinner';
import { SurveyQuestionType } from '../../../../shared/enums/surveys/questionType';
import generateMockId from '../../../../shared/helpers/mocks/generateMockId';
import getISONow from '../../../../shared/helpers/mocks/getISONow';
import { useAppDispatch } from '../../../../shared/hooks/redux/useAppDispatch';
import { useAppSelector } from '../../../../shared/hooks/redux/useAppSelector';
import SurveyQuestion from '../../../../shared/types/surveys/question';
import Survey from '../../../../shared/types/surveys/survey';
import {
  saveSurveyToEdit,
  loadSurveyToEdit,
  changeSelectedQuestion,
  addQuestionToSurvey,
  removeQuestionFromSurvey,
  loadBaseSurveyToCreate,
  loadSurveyFromTemplate,
} from '../../redux/surveyBuilder';
import SurveyBuilderEditorLayout from '../SurveyBuilderEditorLayout';

export default function SurveyBuilderEditor(): React.ReactElement {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { surveyId, questionId } = useParams();
  const [searchParams] = useSearchParams();
  const templateId = searchParams.get('templateId');

  const { surveyToEdit, selectedQuestion } = useAppSelector(
    ({ surveyBuilder }) => ({
      surveyToEdit: surveyBuilder.surveyToEdit,
      selectedQuestion: surveyBuilder.selectedQuestion,
    }),
  );

  useEffect(() => {
    if (surveyId && !surveyToEdit) {
      /**
       * We fetch the Survey if the user loaded the page in this specific route, and we
       * don't already have the survey in memory.
       */
      dispatch(
        loadSurveyToEdit({ id: surveyId, preselectedQuestionId: questionId }),
      );
    } else if (!surveyId && !surveyToEdit) {
      if (templateId) {
        dispatch(loadSurveyFromTemplate({ templateId }));
      } else {
        /**
         * If we don't have the survey in memory, and we aren't getting a surveyId nor a templateId
         * from the router, that means the user is creating a brand new Survey.
         * In that case, we load the bare bones of a Survey:
         */
        dispatch(loadBaseSurveyToCreate());
      }
    }
  }, []);

  const saveHandler = async () => {
    const reduxSaveSurveyAction = (await dispatch(
      saveSurveyToEdit(),
    )) as null | {
      payload?: Partial<Survey>;
    };

    const createdSurveyId = reduxSaveSurveyAction?.payload?.id;

    if (surveyId || createdSurveyId) {
      navigate(
        ROUTES.schedulingEvents.new.getRoute(
          (surveyId || createdSurveyId) as string,
        ),
      );
    }
  };

  const cancelHandler = () => {
    navigate(ROUTES.home.route);
  };

  const deleteQuestionHandler = ({ id }: SurveyQuestion) => {
    dispatch(removeQuestionFromSurvey(id));
  };

  const createQuestionHandler = (sectionName: string) => {
    dispatch(
      addQuestionToSurvey({
        id: generateMockId(),
        name: 'Question name',
        parentId: null,
        sectionName,
        sortOrder: surveyToEdit?.questions?.length || 0,
        globalQuestionId: generateMockId(),
        customerId: generateMockId(),
        surveyId: generateMockId(),
        isEnabled: true,
        defaultAnimationId: generateMockId(),
        selectedAnimationId: generateMockId(),
        isAnonymous: false,
        lastModifiedBy: getISONow(),
        lastModifiedAt: getISONow(),
        question: {
          type: SurveyQuestionType.Boolean,
          config: {},
        },
      }),
    );
  };

  const editQuestionHandler = (question: SurveyQuestion) => {
    dispatch(changeSelectedQuestion(question));

    if (surveyId && question.id !== selectedQuestion?.id) {
      navigate(
        ROUTES.surveyBuilder.routes.withQuestion.getRoute(
          surveyId,
          question.id,
        ),
      );
    }
  };

  if (!surveyToEdit) {
    return (
      <div
        className={styles.spinnerContainer}
        data-test-id={surveyBuilderSpinnerContainer}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <SurveyBuilderEditorLayout
      survey={surveyToEdit}
      onSave={saveHandler}
      onCancel={cancelHandler}
      onQuestionDelete={deleteQuestionHandler}
      onQuestionCreate={createQuestionHandler}
      selectedQuestion={selectedQuestion}
      onQuestionBrowserChange={editQuestionHandler}
      onQuestionConfigPanelChange={editQuestionHandler}
    />
  );
}
