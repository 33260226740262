import moment from 'moment';

import styles from './styles.module.scss';

import OutlinedSection from '../../../../shared/components/OutlinedSection';
import Spacing from '../../../../shared/components/core-ui/Spacing';
import ArrowLeftIcon from '../../../../shared/components/core-ui/icons/ArrowLeftIcon';
import ArrowRightIcon from '../../../../shared/components/core-ui/icons/ArrowRightIcon';
import Label from '../../../../shared/components/core-ui/text/Label';
import P from '../../../../shared/components/core-ui/text/P';
import Title from '../../../../shared/components/core-ui/text/Title';

interface Props {
  day: moment.Moment | null;
  onDayChange: (newDay: moment.Moment) => void;
}

export default function TeammateAgile({
  day,
  onDayChange,
}: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div
          className={styles.buttonContainer}
          onClick={() => onDayChange(moment(day).subtract(1, 'day'))}
        >
          <ArrowLeftIcon />
          <Spacing total={1} />
          <Label>Previous day</Label>
        </div>

        <div className={styles.dateContainer}>
          <P>{moment(day).format('MMM Do, YYYY')}</P>
        </div>

        <div
          className={styles.buttonContainer}
          onClick={() => onDayChange(moment(day).add(1, 'day'))}
        >
          <Label>Next day</Label>
          <Spacing total={1} />
          <ArrowRightIcon />
        </div>
      </header>

      <main className={styles.content}>
        <div className={styles.mainSectionContainer}>
          <div className={styles.sectionRow}>
            <div className={styles.section}>
              <OutlinedSection
                icon={
                  <svg width="64" height="64" viewBox="0 0 54 55" fill="none">
                    <rect
                      opacity="0.1"
                      y="0.225586"
                      width="54"
                      height="54"
                      rx="16"
                      fill="#99EBE3"
                    />
                    <path
                      d="M26.664 39.2255C26.5548 39.2255 26.4456 39.2255 26.3364 39.2255C26.1839 39.0965 26.1244 38.9214 26.0946 38.7328C25.8907 37.4388 25.6939 36.1448 25.4819 34.8517C25.3907 34.2949 25.5126 33.7986 25.841 33.351C26.0432 33.0757 26.045 33.0063 25.795 32.7554C24.3611 31.3197 22.9254 29.8849 21.4906 28.451C21.2452 28.2055 21.1694 28.2064 20.8932 28.4068C20.479 28.7064 20.0197 28.8544 19.5063 28.7749C18.4432 28.6125 17.3802 28.4447 16.3181 28.2759C15.9797 28.2218 15.6377 28.1785 15.3065 28.0927C14.988 28.0106 14.9158 27.7679 15.099 27.4981C15.1378 27.4403 15.1856 27.3871 15.2343 27.3374C17.1212 25.4496 19.0054 23.5582 20.8995 21.6776C21.3029 21.2779 21.8119 21.1488 22.3686 21.2634C22.8117 21.3546 23.2503 21.4647 23.6897 21.573C24.0399 21.6596 24.3358 21.6082 24.6147 21.3474C25.2527 20.75 25.9232 20.1887 26.6171 19.6563C28.6312 18.1096 30.7564 16.7506 33.1495 15.8581C34.208 15.4638 35.2927 15.1741 36.4369 15.2346C37.4566 15.2887 38.2778 15.6821 38.7236 16.6667C38.8634 16.9762 38.914 17.3083 38.9817 17.6358C38.9817 17.9481 38.9817 18.2603 38.9817 18.5725C38.9104 19.113 38.8255 19.6518 38.6812 20.1788C38.2065 21.9086 37.4187 23.4996 36.4992 25.0282C35.4425 26.7861 34.199 28.4059 32.812 29.9147C32.655 30.0861 32.5972 30.2621 32.6568 30.4904C32.7615 30.8902 32.8418 31.2962 32.9465 31.6951C33.1297 32.3944 32.9519 32.9765 32.4411 33.4845C30.6815 35.2334 28.9308 36.9921 27.1757 38.7455C27.0105 38.9106 26.8346 39.0658 26.664 39.2255ZM35.5472 21.2517C35.5472 19.9108 34.513 18.7909 33.0809 18.7819C31.7246 18.7728 30.6246 19.8927 30.6282 21.2445C30.6327 22.6053 31.7192 23.6945 33.0845 23.7053C34.4309 23.7152 35.5426 22.608 35.5472 21.2517Z"
                      fill="#33DFD1"
                    />
                    <path
                      d="M17.3762 36.6114C17.3834 36.3957 17.4691 36.0781 17.5747 35.7694C18.182 33.9944 18.9942 32.3259 20.1745 30.8532C20.3153 30.6772 20.4714 30.5121 20.6257 30.347C20.7719 30.1908 20.937 30.1809 21.1049 30.3109C21.1906 30.3776 21.2709 30.4525 21.3485 30.5292C22.1652 31.3441 22.9818 32.1608 23.7967 32.9774C23.868 33.0487 23.9402 33.1218 24.0006 33.2021C24.1522 33.4025 24.1441 33.5414 23.9682 33.7183C23.1578 34.5368 22.2446 35.1991 21.1527 35.5998C20.0175 36.0158 18.9021 36.4787 17.8157 37.0129C17.4682 37.1853 17.3708 37.1176 17.3762 36.6114Z"
                      fill="#33DFD1"
                    />
                  </svg>
                }
              >
                <div className={styles.numberContainer}>
                  <P>Achievability</P>

                  <Title>80%</Title>
                </div>
              </OutlinedSection>
            </div>

            <div className={styles.section}>
              <OutlinedSection
                icon={
                  <svg width="64" height="64" viewBox="0 0 54 55" fill="none">
                    <rect
                      opacity="0.1"
                      y="0.225586"
                      width="54"
                      height="54"
                      rx="16"
                      fill="#D83838"
                    />
                    <path
                      d="M27 21.5C30.79 21.5 34.17 23.63 35.82 27C34.17 30.37 30.8 32.5 27 32.5C23.2 32.5 19.83 30.37 18.18 27C19.83 23.63 23.21 21.5 27 21.5ZM27 19.5C22 19.5 17.73 22.61 16 27C17.73 31.39 22 34.5 27 34.5C32 34.5 36.27 31.39 38 27C36.27 22.61 32 19.5 27 19.5ZM27 24.5C28.38 24.5 29.5 25.62 29.5 27C29.5 28.38 28.38 29.5 27 29.5C25.62 29.5 24.5 28.38 24.5 27C24.5 25.62 25.62 24.5 27 24.5ZM27 22.5C24.52 22.5 22.5 24.52 22.5 27C22.5 29.48 24.52 31.5 27 31.5C29.48 31.5 31.5 29.48 31.5 27C31.5 24.52 29.48 22.5 27 22.5Z"
                      fill="#F06161"
                    />
                  </svg>
                }
              >
                <div className={styles.numberContainer}>
                  <P>Scope clarity</P>

                  <Title>80%</Title>
                </div>
              </OutlinedSection>
            </div>
          </div>

          <div className={styles.sectionRow}>
            <div className={styles.section}>
              <OutlinedSection title="Last 24hrs">
                <P>Test 123</P>
              </OutlinedSection>
            </div>

            <div className={styles.section}>
              <OutlinedSection title="Next 24hrs">
                <P>Test 123</P>
              </OutlinedSection>
            </div>
          </div>
        </div>

        <div className={styles.sectionRow}>
          <div className={styles.section}>
            <OutlinedSection title="Blockers">
              <P>Test 123</P>
            </OutlinedSection>
          </div>

          <div className={styles.section}>
            <OutlinedSection highlight onClick={() => {}}>
              <P>View Team responses</P>
              <Spacing total={1} />
              <ArrowRightIcon />
            </OutlinedSection>
          </div>
        </div>
      </main>
    </div>
  );
}
