/* eslint-disable motivato/all-components-should-have-storybook-stories */
import React, { useEffect } from 'react';

import { loadUserSession } from '../../../modules/Auth/redux/auth';
import AuthContext from '../../contexts/AuthContext';
import { useAppDispatch } from '../../hooks/redux/useAppDispatch';
import { useAppSelector } from '../../hooks/redux/useAppSelector';

interface Props {
  children: React.ReactNode;
}

export default function AuthProvider({ children }: Props): React.ReactElement {
  const dispatch = useAppDispatch();

  const { isLoggedIn, wasSessionLoaded } = useAppSelector(({ auth }) => ({
    isLoggedIn: !!auth.session,
    wasSessionLoaded: auth.wasSessionLoaded,
  }));

  useEffect(() => {
    dispatch(loadUserSession());
  }, []);

  if (!wasSessionLoaded) {
    return <></>;
  }

  return (
    <AuthContext.Provider value={{ isLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
}
