import { CustomFrequencyDay } from '../../enums/schedulingEvents/frequency';

export const WEEKDAYS: CustomFrequencyDay[] = [
  // Must be ordered Mon -> Fri
  CustomFrequencyDay.Mon,
  CustomFrequencyDay.Tue,
  CustomFrequencyDay.Wed,
  CustomFrequencyDay.Thu,
  CustomFrequencyDay.Fri,
];

export const ALL_DAYS_OF_THE_WEEK: CustomFrequencyDay[] = [
  ...WEEKDAYS,
  CustomFrequencyDay.Sat,
  CustomFrequencyDay.Sun,
];
