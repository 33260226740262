// eslint-disable-next-line motivato/mui-should-not-be-imported-outside-of-core-ui
import PickersDay, { PickersDayProps } from '@mui/lab/PickersDay';
import classnames from 'classnames';
import moment from 'moment';
import { useMemo } from 'react';

import styles from './styles.module.scss';

import CalendarDatePicker from '../../../../../../shared/components/core-ui/CalendarDatePicker';
import Spacing from '../../../../../../shared/components/core-ui/Spacing';
import getMetaOnSelectedCalendarDay from '../../../../../../shared/helpers/schedulingEvent/getMetaOnSelectedCalendarDay';

interface Props {
  onChange: (daysOfWeek: number[]) => void;
  selectedDays: number[];
}

export default function SchedulingEventMonthlyConfig({
  onChange,
  selectedDays,
}: Props): React.ReactElement {
  const daysSelected: Record<string, boolean> = useMemo(() => {
    const selected: Record<string, boolean> = {};

    selectedDays.forEach((day: number) => {
      selected[day] = true;
    });

    return selected;
  }, [selectedDays]);

  const daySelectedHandler = (date: moment.Moment | null) => {
    if (date) {
      const key = date.date();

      onChange(
        Object.entries({
          ...daysSelected,
          [key]: !daysSelected[key],
        })
          .filter(([, isSelected]) => isSelected)
          .map(([dateToChange]) => +dateToChange)
          .sort((a, b) => a - b),
      );
    }
  };

  const renderWeekPickerDay = (
    date: moment.Moment,
    __: unknown,
    props: PickersDayProps<moment.Moment>,
  ): React.ReactElement => {
    const { meta, pickersDayProps } = getMetaOnSelectedCalendarDay(
      date,
      daysSelected,
      props,
    );

    if (meta?.isSelected) {
      return (
        <PickersDay
          {...pickersDayProps}
          disableMargin={!meta.isFirstDay || !meta.isLastDay}
          className={classnames(styles.selectedDay, {
            [styles.isLastDay]: meta.isLastDay,
            [styles.isFirstDay]: meta.isFirstDay,
            [styles.dayIsInBetweenSelection]: meta.dayIsBetweenSelection,
          })}
        />
      );
    }

    return <PickersDay {...pickersDayProps} />;
  };

  return (
    <>
      <Spacing vertical={2} />

      <CalendarDatePicker
        value={moment().month(1)}
        minDate={moment().month(1).date(1)}
        maxDate={moment().month(1).date(28)}
        onChange={daySelectedHandler}
        hideNavigation
        onCustomDayRender={renderWeekPickerDay}
      />
    </>
  );
}
