import { MenuItem } from '@mui/material';
import MuiSelect from '@mui/material/TextField';
import React from 'react';

import styles from './styles.module.scss';

export enum SelectSize {
  small = 'small',
  medium = 'medium',
}

export function SelectOption(
  props: Parameters<typeof MenuItem>[0],
): React.ReactElement {
  return <MenuItem {...props} />;
}

interface Props {
  size?: SelectSize;
  label?: string;
  value?: string | number | null;
  children: React.ReactNode;
  onChange?: (option: string | number) => void;
}

function Select(
  {
    label,
    value,
    size = SelectSize.medium,
    onChange,
    children,
    ...otherProps
  }: Props,
  ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);
  };

  return (
    <MuiSelect
      ref={ref}
      size={size}
      label={label}
      value={value || ''}
      select
      onChange={handleChange}
      className={styles.select}
      {...otherProps}
    >
      {children}
    </MuiSelect>
  );
}

export default React.forwardRef(Select);
