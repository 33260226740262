import classnames from 'classnames';
import isNumber from 'lodash/isNumber';
import React from 'react';

import styles from './styles.module.scss';

import Label from '../core-ui/text/Label';
import P from '../core-ui/text/P';
import Title from '../core-ui/text/Title';

interface Props {
  title?: string;
  kicker?: string;
  children: React.ReactNode;
  className?: string;
  mainNumber?: number;
  description: React.ReactNode;
}

export default function StatSummaryCard({
  title,
  kicker,
  children,
  className,
  mainNumber,
  description,
}: Props): React.ReactElement {
  return (
    <div className={classnames(styles.card, className)}>
      <div className={styles.content}>
        {kicker && <Label>{kicker}</Label>}

        {(isNumber(mainNumber) || title) && (
          <Title>{isNumber(mainNumber) ? `${mainNumber}%` : title}</Title>
        )}

        <P>{description}</P>
      </div>

      {children}
    </div>
  );
}
