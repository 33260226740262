import xor from 'lodash/xor';

import { WEEKDAYS } from '../../../constants/schedulingEvents/weekdays';

interface Args {
  cron: string | null;
}

export default function repeatsOnlyOnWeekdays({ cron }: Args): boolean {
  if (cron) {
    const terms = cron.split(' ');
    const weekDays = terms[terms.length - 1].split(',');

    if (weekDays) {
      return !xor(weekDays, WEEKDAYS).length;
    }
  }

  return false;
}
