import { combineReducers } from 'redux';

import auth from '../../../modules/Auth/redux/auth';
import home from '../../../modules/Home/redux/home';
import liveSurvey from '../../../modules/LiveSurvey/redux/liveSurvey';
import slack from '../../../modules/Onboarding/ConnectToSlackStep/redux/slack';
import editTeam from '../../../modules/Onboarding/CreateTeamsStep/redux/editTeam';
import membersAndChannelsFromSlack from '../../../modules/Onboarding/CreateTeamsStep/redux/membersAndChannelsFromSlack';
import teamsList from '../../../modules/Onboarding/CreateTeamsStep/redux/teamsList';
import onboardingContainer from '../../../modules/Onboarding/Onboarding/redux/onboardingContainer';
import roundOfResponses from '../../../modules/RoundOfResponses/redux/roundOfResponses';
import surveyBuilder from '../../../modules/SurveyBuilder/redux/surveyBuilder';
import schedulingEvents from '../../../modules/SurveySchedulingEvent/redux/schedulingEvents';
import teammates from '../../../modules/Teammates/redux/teammates';
import templates from '../../../modules/Templates/redux/templates';
import globalSearch from '../globalSearch';

const rootReducer = combineReducers({
  auth,
  home,
  slack,
  editTeam,
  templates,
  teamsList,
  liveSurvey,
  globalSearch,
  surveyBuilder,
  schedulingEvents,
  onboardingContainer,
  membersAndChannelsFromSlack,
  teammates,
  roundOfResponses,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
