import styles from './styles.module.scss';

import P from '../core-ui/text/P';
import getPropsForFixOrCelebrateType from './helpers/getPropsForFixOrCelebrateType';

export enum FixOrCelebrateType {
  fix,
  celebrate,
}

interface FixOrCelebrateSection {
  items: { name: string }[];
  title: string;
}

interface Props {
  type: FixOrCelebrateType;
  sections: FixOrCelebrateSection[];
}

export default function FixAndCelebrateCard({
  type,
  sections,
}: Props): React.ReactElement {
  const { title, primaryHeaderColor, secondaryHeaderColor } =
    getPropsForFixOrCelebrateType(type);

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.headerTitle}>
          <div className={styles.backgroundContainer}>
            <svg
              fill="none"
              viewBox="0 0 326 64"
              className={styles.titleBackground}
              preserveAspectRatio="none"
            >
              <path
                d="M326 16C326 38.6274 326 49.9411 318.971 56.9706C311.941 64 300.627 64 278 64L1.19209e-05 64L3.36133e-06 1.88676e-05L326 2.38419e-07L326 16Z"
                fill={primaryHeaderColor}
              />
            </svg>
          </div>

          <div className={styles.titleContainer}>
            <P>{title}</P>
          </div>
        </div>

        <div className={styles.remainingBackgroundContainer}>
          <svg
            fill="none"
            viewBox="0 0 84 48"
            className={styles.remainingBackground}
            preserveAspectRatio="none"
          >
            <path
              d="M0 0H84V48H48C25.3726 48 14.0589 48 7.02944 40.9706C0 33.9411 0 22.6274 0 0Z"
              fill={secondaryHeaderColor}
            />
          </svg>
        </div>
      </header>

      <main className={styles.content}>
        {sections.map((section) => (
          <div key={section.title}>
            <P uppercase>{section.title}</P>

            <div className={styles.sectionContent}>
              {section.items.map((item) => (
                <P key={item.name}> - {item.name}</P>
              ))}
            </div>
          </div>
        ))}
      </main>
    </div>
  );
}
