import React, { useRef, useMemo } from 'react';

import styles from './styles.module.scss';

import IconButton from '../../../../shared/components/core-ui/IconButton';
import AddIcon from '../../../../shared/components/core-ui/icons/AddIcon';
import P from '../../../../shared/components/core-ui/text/P';
import Title from '../../../../shared/components/core-ui/text/Title';
import { IconSize } from '../../../../shared/enums/icons';
import { groupQuestionsBySection } from '../../../../shared/helpers/surveys/sections';
import SurveyQuestion from '../../../../shared/types/surveys/question';
import SurveySection from '../../../../shared/types/surveys/section';
import Survey from '../../../../shared/types/surveys/survey';
import NestableQuestionRow from './components/NestableQuestionRow';

interface Props {
  survey: Survey;
  onDelete: (question: SurveyQuestion) => void;
  onCreate: (sectionName: string) => void;
  selectedQuestion?: SurveyQuestion | null;
  onQuestionChange?: (question: SurveyQuestion) => void;
}

export default function QuestionsBrowser({
  survey,
  onDelete,
  onCreate,
  selectedQuestion,
  onQuestionChange,
}: Props): React.ReactElement {
  const containerRef = useRef<HTMLDivElement>(null);

  const sections: SurveySection[] = useMemo(
    () => groupQuestionsBySection(survey.questions || []),
    [survey.questions],
  );

  const handleChange = (question: SurveyQuestion) => {
    if (question.id !== selectedQuestion?.id) {
      onQuestionChange?.(question);
    }
  };

  const createHandler = () => {
    onCreate(sections[sections.length - 1]?.name);

    setTimeout(() => {
      containerRef.current?.scroll({
        top: containerRef.current?.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    }, 300);
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Title>Browser</Title>

        <IconButton onClick={createHandler}>
          <AddIcon size={IconSize.medium} />
        </IconButton>
      </header>

      <main ref={containerRef} className={styles.content}>
        {sections.map((section) => (
          <React.Fragment key={section.name}>
            <div className={styles.sectionNameContainer}>
              {section.name !== 'undefined' && <P>{section.name}</P>}
            </div>

            {section.questions.map((question) => (
              <NestableQuestionRow
                key={question.id}
                onClick={handleChange}
                question={question}
                onDelete={onDelete}
                selectedQuestion={selectedQuestion}
              />
            ))}
          </React.Fragment>
        ))}
      </main>
    </div>
  );
}
