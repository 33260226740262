import { Auth } from 'aws-amplify';

import signUpExceptions from '../../errorHandling/auth/signUp';
import UsernameExistsException from '../../exceptions/auth/signUp/usernameExistsException';

export default async function signUp(
  username: string,
  password: string,
): Promise<{
  isSignUp: boolean;
  isAlreadyRegistered: boolean;
}> {
  try {
    await Auth.signUp({
      username,
      password,
    });

    return {
      isSignUp: true,
      isAlreadyRegistered: false,
    };
  } catch (error) {
    const exception =
      signUpExceptions[(error as { code: keyof typeof signUpExceptions }).code];

    if (exception instanceof UsernameExistsException) {
      return { isSignUp: true, isAlreadyRegistered: true };
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw exception;
  }
}
