import styles from './styles.module.scss';

import Spacing from '../../../../shared/components/core-ui/Spacing';
import Title from '../../../../shared/components/core-ui/text/Title';
import FrequencyConfig from '../../../../shared/types/surveys/schedulingEvents/frequencyConfig';
import PossibleAudience from '../../../../shared/types/surveys/schedulingEvents/possibleAudience';
import SchedulingEvent from '../../../../shared/types/surveys/schedulingEvents/schedulingEvent';
import SchedulingEventAudience from '../SchedulingEventAudience';
import SurveySchedulingEventFrequencyConfig from '../SurveySchedulingEventFrequencyConfig';

interface Props {
  onSave: (config: FrequencyConfig) => void;
  possibleAudience?: PossibleAudience;
  saveDisabled: boolean;
  loadingAudience: boolean;
  selectedAudience: PossibleAudience;
  onAudienceChange: (audience: PossibleAudience) => void;
  schedulingEventToEdit?: SchedulingEvent | null;
}

export default function SurveySchedulingEventLayout({
  onSave,
  possibleAudience,
  saveDisabled,
  loadingAudience,
  selectedAudience,
  onAudienceChange,
  schedulingEventToEdit,
}: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      <main className={styles.content}>
        <Title>Audience</Title>

        <Spacing vertical={1} />

        <SchedulingEventAudience
          loading={loadingAudience}
          onChange={onAudienceChange}
          selectedAudience={selectedAudience}
          possibleAudience={possibleAudience}
        />
      </main>

      <SurveySchedulingEventFrequencyConfig
        onSave={onSave}
        cronString={schedulingEventToEdit?.frequency || '31 2 * * MON,WED'}
        saveDisabled={saveDisabled}
        startingDate={schedulingEventToEdit?.startAt || null}
      />
    </div>
  );
}
