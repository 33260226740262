import classnames from 'classnames';
import lottie, { AnimationItem } from 'lottie-web';
import React, { useRef, createRef, useEffect, useMemo } from 'react';

// import { useMemo } from 'react';
import styles from './styles.module.scss';

import playSegmentsBasedOnPercentage from '../../../helpers/lottie/playSegmentsBasedOnPercentage';
import useForwardedRef from '../../../hooks/useForwardedRef';

interface Props {
  // We need to use 'any' here, as this is the object we get from the lottie plugin
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  animationData: any;
  className?: string;
  playToPercentage?: number | null;
}

function LottieAnimation(
  { className, animationData, playToPercentage }: Props,
  ref: React.ForwardedRef<AnimationItem>,
): React.ReactElement {
  const elementRef = createRef<HTMLDivElement>();
  const lastPlayedTo = useRef<number>();
  const lottiePlayerRef = useForwardedRef(ref);
  const durationInFrames = useMemo(
    () => lottiePlayerRef.current?.getDuration(true) || 0,
    [lottiePlayerRef.current],
  );

  useEffect(() => {
    if (animationData && !elementRef.current?.children.length) {
      lottiePlayerRef.current = lottie.loadAnimation({
        loop: true,
        autoplay: true,
        renderer: 'svg',
        container: elementRef.current as Element,
        animationData: animationData,
      });
    }
  }, []);

  useEffect(() => {
    playSegmentsBasedOnPercentage({
      lottiePlayerRef,
      playToPercentage,
      lastPlayedToRef: lastPlayedTo,
      durationInFrames,
    });
  }, [playToPercentage]);

  return (
    <div
      ref={elementRef}
      className={classnames(styles.animationContainer, className)}
    />
  );
}

export default React.forwardRef(LottieAnimation);
