import classnames from 'classnames';
import React, { ForwardedRef } from 'react';

import styles from './styles.module.scss';

import { ShallowModify } from '../../../helpers/typescript/shallowModify';
import Button, { ButtonVariant, ButtonType } from '../Button';

export const MainButtonType = ButtonType;
export const MainButtonVariant = ButtonVariant;

type Props = ShallowModify<
  Parameters<typeof Button>[0],
  {
    type?: typeof MainButtonType[keyof typeof MainButtonType];
    variant?: typeof MainButtonVariant[keyof typeof MainButtonVariant];
  }
>;

function MainButton(
  { variant = MainButtonVariant.primary, ...otherProps }: Props,
  ref: ForwardedRef<HTMLButtonElement>,
): React.ReactElement {
  return (
    <Button
      ref={ref}
      variant={variant}
      {...otherProps}
      className={classnames(otherProps.className, styles.button)}
    />
  );
}

export default React.forwardRef(MainButton);
