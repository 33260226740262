import Spacing from '../../../../../../shared/components/core-ui/Spacing';
import ToggleButtonGroup, {
  ToggleButtonGroupSize,
} from '../../../../../../shared/components/core-ui/ToggleButtonGroup';
import { CustomFrequencyDay } from '../../../../../../shared/enums/schedulingEvents/frequency';

const DAYS_TEXT: { [key in CustomFrequencyDay]: string } = {
  [CustomFrequencyDay.Mon]: 'MON',
  [CustomFrequencyDay.Tue]: 'TUE',
  [CustomFrequencyDay.Wed]: 'WED',
  [CustomFrequencyDay.Thu]: 'THU',
  [CustomFrequencyDay.Fri]: 'FRI',
  [CustomFrequencyDay.Sat]: 'SAT',
  [CustomFrequencyDay.Sun]: 'SUN',
};

interface Props {
  onChange: (days: CustomFrequencyDay[]) => void;
  selectedDays: CustomFrequencyDay[];
}

export default function SchedulingEventWeeklyConfig({
  onChange,
  selectedDays,
}: Props): React.ReactElement {
  return (
    <>
      <Spacing vertical={2} />

      <ToggleButtonGroup
        size={ToggleButtonGroupSize.Small}
        value={selectedDays}
        onChange={(days: null | string | string[]) =>
          onChange(days as CustomFrequencyDay[])
        }
        buttons={Object.entries(DAYS_TEXT).map(([value, text]) => ({
          value,
          content: text,
        }))}
      />
    </>
  );
}
