import findLastIndex from 'lodash/findLastIndex';

export default function getNewListResponses(
  responses: { text: string }[],
  indexChanged: number,
  textChanged: string,
): { text: string }[] {
  const newResponses = [...responses];
  newResponses[indexChanged] = { text: textChanged };

  if (indexChanged === responses.length - 1 || !responses.length) {
    newResponses.push({ text: '' });
  }

  const lastIndexWithText: number = findLastIndex(
    newResponses,
    (response) => !!response?.text?.trim(),
  );

  if (lastIndexWithText < newResponses.length - 2) {
    return newResponses.slice(0, lastIndexWithText + 2);
  }

  return newResponses;
}
