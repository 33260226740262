import cronstrue from 'cronstrue';

import repeatsOnlyOnWeekdays from '../repeatsOnlyOnWeekdays';
import sortCronDaysOfWeek from '../sortCronDaysOfWeek';

interface Args {
  cron: string | null;
}

export default function getCronText({ cron }: Args): string | null {
  if (cron) {
    let str: string;

    try {
      str = cronstrue.toString(sortCronDaysOfWeek({ cron }));
    } catch {
      // cronstrue will throw if the cron is invalid.
      return null;
    }

    if (repeatsOnlyOnWeekdays({ cron })) {
      const weekendMatch = str
        .replace('on day', '')
        .match(
          /on(\s|Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday|and|,)+/,
        );

      if (weekendMatch) {
        const stringToReplace = weekendMatch?.[0].match(/\w.*\w/i)?.[0];

        if (stringToReplace) {
          return str.replace(stringToReplace, 'on every weekday');
        }
      }
    }

    return str
      .replace('Monday', 'Mon')
      .replace('Tuesday', 'Tue')
      .replace('Wednesday', 'Wed')
      .replace('Thursday', 'Thu')
      .replace('Friday', 'Fri')
      .replace('Saturday', 'Sat')
      .replace('Sunday', 'Sun');
  }

  return null;
}
