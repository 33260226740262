import moment from 'moment';

import styles from './styles.module.scss';

import Spacing from '../../../../shared/components/core-ui/Spacing';
import Label from '../../../../shared/components/core-ui/text/Label';
import P from '../../../../shared/components/core-ui/text/P';
import Title from '../../../../shared/components/core-ui/text/Title';
import TeammateAgile from '../TeammateAgile';
import TeammateStatWithTip, {
  TeammateStatWithTipVariation,
} from '../TeammateStatWithTip';
import TeammateSummary from '../TeammateSummary';
import TeammateTaskAssignment from '../TeammateTaskAssignment';

interface Props {
  day: moment.Moment | null;
  onDayChange: (newDay: moment.Moment) => void;
  profileImageUrl: string;
}

export default function TeammateProfileLayout({
  day,
  onDayChange,
  profileImageUrl,
}: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.profileData}>
          <div
            className={styles.profilePicture}
            style={{ backgroundImage: `url(${profileImageUrl})` }}
          />

          <div className={styles.profileTextContainer}>
            <Title>Ada Lovelace</Title>
            <Label>Last updated Apr 20</Label>
          </div>
        </div>
      </header>

      <div className={styles.content}>
        <Title>1. Summary</Title>
        <Spacing total={1} />

        <TeammateSummary />
        <Spacing total={5} />

        <P>Daily 2.0 responses</P>
        <Spacing total={1} />
        <TeammateAgile day={day} onDayChange={onDayChange} />

        <div className={styles.separator} />

        <Title>2. Roadmap visibility</Title>

        <Spacing total={1} />
        <TeammateStatWithTip
          variation={TeammateStatWithTipVariation.RoadmapVisibility}
        />

        <div className={styles.separator} />

        <Title>3. Time pressure</Title>

        <Spacing total={1} />
        <TeammateStatWithTip
          variation={TeammateStatWithTipVariation.TimePressure}
        />

        <div className={styles.separator} />

        <Title>4. Assign tasks based on their feedback</Title>

        <TeammateTaskAssignment />
      </div>
    </div>
  );
}
