/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-unused-vars */
import { FetchResult } from '@apollo/client';

import SlackUser from '../../types/slack/slackUsers';
import { GenericResponseType } from '../apolloClient';

type ResponseType = GenericResponseType;

const mutationName = 'UpdateTeam';
type Response = Record<typeof mutationName, ResponseType>;

export default async function updateTeam(
  teamId: string,
  name: string,
  members: SlackUser[],
  teamLeadIDs: string[],
): Promise<FetchResult<Response>> {
  return Promise.resolve({
    data: {
      UpdateTeam: {
        ok: true,
        id: 1,
      },
    },
  });

  // return apolloClient.mutate<Response>({
  //   mutation: gql`
  //     mutation ${mutationName}(
  //       $name: String!,
  //       $groupID: ID!,
  //       $members: [SlackUserInput!]!,
  //       $customerID: ID!,
  //       $teamLeadsIDs: [String!]!
  //     ) {
  //       UpdateGroup(
  //         input: {
  //           name: $name,
  //           members: $members,
  //           groupID: $groupID,
  //           customerID: $customerID,
  //           teamLeadsIDs: $teamLeadsIDs,
  //         }
  //       ) {
  //         ok
  //         error {
  //           code
  //           message
  //         }
  //       }
  //     }
  //   `,
  //   variables: {
  //     name,
  //     groupID: teamId,
  //     customerID: customerIDMock,
  //     teamLeadsIDs: teamLeadIDs,
  //     members: members.map(({ id, slackUserID, name: memberName, email }) => ({
  //       // If the user was just added from Slack, their ID will actually be the slackUserID.
  //       id: slackUserID || id,
  //       name: memberName,
  //       email,
  //     })),
  //   },
  // });
}
