import React from 'react';

import styles from './styles.module.scss';

import SurveyTemplateCard from '../../../../shared/components/SurveyTemplateCard';
import P from '../../../../shared/components/core-ui/text/P';
import Title from '../../../../shared/components/core-ui/text/Title';
import SurveyTemplate from '../../../../shared/types/surveys/template';

interface Props {
  templates: SurveyTemplate[];
  onTemplateClick: (template: SurveyTemplate) => void;
}

export default function TemplatesLayout({
  templates,
  onTemplateClick,
}: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Title>Want to know how your team is doing?</Title>
        <P>Start from Motivato's ready-made templates.</P>
      </header>

      <div className={styles.content}>
        {templates.map((template) => (
          <div key={template.id} className={styles.templateContainer}>
            <SurveyTemplateCard
              onClick={() => onTemplateClick(template)}
              verticalLayout
              surveyTemplate={template}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
