/* eslint-disable motivato/all-components-should-have-storybook-stories */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

import testAvatar from '../../../../assets/images/avatars/test-1.svg';
import arrowForwardIcon from '../../../../assets/images/icons/arrow-forward.svg';
import { ROUTES } from '../../../../router';
import P from '../../../../shared/components/core-ui/text/P';
import Title from '../../../../shared/components/core-ui/text/Title';
import User from '../../../../shared/types/auth/user';

interface Props {
  teammates: User[];
}

export default function TeammatesLayout({
  teammates,
}: Props): React.ReactElement {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.headingContainer}>
        <Title noMargin className={styles.title}>
          Teammates
        </Title>
        <P noMargin>{teammates.length} Teammates added to Motivato</P>
      </div>
      <div className={styles.teammatesContainer}>
        {teammates.map(({ id, name, profile }) => (
          <div
            key={id}
            className={styles.card}
            onClick={() => navigate(ROUTES.teammateProfile.getRoute(id))}
          >
            <img
              src={profile?.imageOriginal || testAvatar}
              className={styles.avatar}
            />
            <div className={styles.text}>
              <Title noMargin className={styles.cardTitle}>
                {name}
              </Title>
              <P noMargin>Last updated: 11/12 at 15:20 pm</P>
            </div>
            <img src={arrowForwardIcon} />
          </div>
        ))}
      </div>
    </div>
  );
}
