import styles from './styles.module.scss';

import MainButton, {
  MainButtonVariant,
} from '../../../../../../shared/components/core-ui/MainButton';
import Spacing from '../../../../../../shared/components/core-ui/Spacing';
import Title from '../../../../../../shared/components/core-ui/text/Title';
import { LiveSurveyQuestion } from '../../../../../../shared/types/surveys/liveSurvey';
import LiveSurveyResponseProps, {
  LiveSurveyResponseValue,
} from '../../../../../../shared/types/surveys/liveSurveys/liveSurveys';
import SurveyQuestion from '../../../../../../shared/types/surveys/question';
import LiveSurveyAnswer from '../answers/LiveSurveyAnswer';

interface Props {
  value: LiveSurveyResponseValue | null;
  question: LiveSurveyQuestion | SurveyQuestion;
  isEditing?: boolean;
  onContinue: () => void;
  onChange: LiveSurveyResponseProps['onChange'];
}

export default function LiveSurveyScreenMain({
  value,
  onChange,
  question,
  isEditing,
  onContinue,
}: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      <Title>{question.name}</Title>

      <Spacing flex />

      <LiveSurveyAnswer
        value={value}
        onChange={onChange}
        isEditing={isEditing}
        questionConfig={question.question}
        renderFooter={({ buttonText = 'Continue' } = {}) => (
          <>
            <Spacing flex />

            <MainButton
              variant={MainButtonVariant.primary}
              onClick={onContinue}
            >
              {buttonText}
            </MainButton>
          </>
        )}
      />
    </div>
  );
}
