import fetchAnimation from '../../../networking/surveys/fetchAnimation';
import LiveSurvey, {
  LiveSurveyQuestion,
} from '../../../types/surveys/liveSurvey';

export default function resolveAnimationsForLiveSurvey(
  survey: LiveSurvey,
  onQuestionBatchReady: (
    processedSurveyQuestions: LiveSurveyQuestion[],
  ) => void,
): void {
  const resolveAnimation = async (
    surveyQuestion: LiveSurveyQuestion,
  ): Promise<LiveSurveyQuestion> => ({
    ...surveyQuestion,
    animation: {
      ...surveyQuestion.animation,
      lottie: await fetchAnimation(surveyQuestion.animation),
    },
  });

  const [firstQuestion, secondQuestion, ...rest] = survey.questions || [];

  if (firstQuestion) {
    resolveAnimation(firstQuestion).then((surveyQuestion) =>
      onQuestionBatchReady([surveyQuestion]),
    );
  }

  if (secondQuestion) {
    resolveAnimation(secondQuestion).then((surveyQuestion) =>
      onQuestionBatchReady([surveyQuestion]),
    );
  }

  if (rest.length) {
    Promise.all(
      rest.map((surveyQuestion) => resolveAnimation(surveyQuestion)),
    ).then(onQuestionBatchReady);
  }
}
