import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import fetchUsersAndChannels from '../../../../../shared/networking/integrations/slack/usersAndChannels';
import SlackChannel from '../../../../../shared/types/slack/slackChannel';
import SlackUser from '../../../../../shared/types/slack/slackUsers';

export const getUsersAndChannels = createAsyncThunk(
  'membersAndChannelsFromSlack/GET_USERS_AND_CHANNELS',
  async () => {
    const response = await fetchUsersAndChannels();

    if (response?.data?.SlackTeam.slackTeam) {
      const { users: slackUsers, channels: slackChannels } =
        response.data.SlackTeam.slackTeam;

      return { slackUsers, slackChannels };
    }
  },
);

interface State {
  slackUsers: SlackUser[];
  slackChannels: SlackChannel[];
  slackUsersAndChannelsFetched: boolean;
}

export default createReducer<State>(
  {
    slackUsers: [],
    slackChannels: [],
    slackUsersAndChannelsFetched: false,
  },
  (builder) => {
    builder.addCase(getUsersAndChannels.fulfilled, (state, action) => {
      state.slackUsers = action.payload?.slackUsers || [];
      state.slackChannels = action.payload?.slackChannels || [];
      state.slackUsersAndChannelsFetched = true;
    });
  },
);
